import { createRoot } from 'react-dom/client';
import { Alert, Snackbar } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

let messageContainer = null;
let root = null;
let messageQueue = [];

const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '8px 16px',
          backgroundColor: '#ffffff',
        },
        icon: {
          fontSize: '20px',
        },
        message: {
          fontSize: '14px',
        },
      },
    },
  },
});

const Message = ({ message, severity, onClose }) => {
  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={true}
        autoHideDuration={4000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiAlert-root': {
            minWidth: '240px',
            maxWidth: '500px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
            backgroundColor: '#ffffff',
          }
        }}
      >
        <Alert 
          onClose={onClose} 
          severity={severity}
          sx={{
            '&.MuiAlert-standardSuccess': {
              backgroundColor: '#ffffff',
              color: '#000',
              borderColor: '#b7eb8f',
              '& .MuiAlert-icon': {
                color: '#52c41a',
              },
            },
            '&.MuiAlert-standardError': {
              backgroundColor: '#ffffff',
              color: '#000',
              borderColor: '#ffccc7',
              '& .MuiAlert-icon': {
                color: '#ff4d4f',
              },
            },
            '&.MuiAlert-standardWarning': {
              backgroundColor: '#ffffff',
              color: '#000',
              borderColor: '#ffe58f',
              '& .MuiAlert-icon': {
                color: '#faad14',
              },
            },
            '&.MuiAlert-standardInfo': {
              backgroundColor: '#ffffff',
              color: '#000',
              borderColor: '#91caff',
              '& .MuiAlert-icon': {
                color: '#1677ff',
              },
            },
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

const processMessageQueue = () => {
  if (messageQueue.length === 0) {
    return;
  }

  const { message, severity } = messageQueue[0];

  if (!messageContainer) {
    messageContainer = document.createElement('div');
    document.body.appendChild(messageContainer);
    root = createRoot(messageContainer);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    messageQueue.shift(); // Remove the message after it's closed
    root.render(null);
    setTimeout(() => {
      processMessageQueue();
    }, 100);
  };

  // Immediately remove previous message and show new one
  if (messageQueue.length > 0) {
    messageQueue.shift(); // Remove the previous message
  }

  root.render(
    <Message
      message={message}
      severity={severity}
      onClose={handleClose}
    />
  );
};

const createMessage = (message, severity) => {
  messageQueue.push({ message, severity });
  processMessageQueue();
};

export const message = {
  success: (message) => createMessage(message, 'success'),
  error: (message) => createMessage(message, 'error'),
  warning: (message) => createMessage(message, 'warning'),
  info: (message) => createMessage(message, 'info'),
};

message.destroy = () => {
  messageQueue = [];
  if (root) {
    root.render(null);
  }
};