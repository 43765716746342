import React, { useEffect, useRef, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Grid, Card, CardContent, CardMedia, Chip, Typography, Skeleton } from '@mui/material';

const icons = {
  All: <svg fill="currentColor" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3,11h7a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3v7A1,1,0,0,0,3,11ZM4,4H9V9H4ZM22,3a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1ZM20,9H15V4h5ZM2,21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1Zm2-6H9v5H4Zm18,6V14a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7A1,1,0,0,0,22,21Zm-2-1H15V15h5Z" /></svg>,
  Trending: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-flame"><path d="M8.5 14.5A2.5 2.5 0 0 0 11 12c0-1.38-.5-2-1-3-1.072-2.143-.224-4.054 2-6 .5 2.5 2 4.9 4 6.5 2 1.6 3 3.5 3 5.5a7 7 0 1 1-14 0c0-1.153.433-2.294 1-3a2.5 2.5 0 0 0 2.5 2.5z" /></svg>,
  ForCreator: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-thumbs-up"><path d="M7 10v12" /><path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2a3.13 3.13 0 0 1 3 3.88Z" /></svg>,
  ForBusiness: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-handshake"><path d="m11 17 2 2a1 1 0 1 0 3-3" /><path d="m14 14 2.5 2.5a1 1 0 1 0 3-3l-3.88-3.88a3 3 0 0 0-4.24 0l-.88.88a1 1 0 1 1-3-3l2.81-2.81a5.79 5.79 0 0 1 7.06-.87l.47.28a2 2 0 0 0 1.42.25L21 4" /><path d="m21 3 1 11h-2" /><path d="M3 3 2 14l6.5 6.5a1 1 0 1 0 3-3" /><path d="M3 4h8" /></svg>,
  CreateWithAI: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-wand-sparkles"><path d="m21.64 3.64-1.28-1.28a1.21 1.21 0 0 0-1.72 0L2.36 18.64a1.21 1.21 0 0 0 0 1.72l1.28 1.28a1.2 1.2 0 0 0 1.72 0L21.64 5.36a1.2 1.2 0 0 0 0-1.72" /><path d="m14 7 3 3" /><path d="M5 6v4" /><path d="M19 14v4" /><path d="M10 2v2" /><path d="M7 8H3" /><path d="M21 16h-4" /><path d="M11 3H9" /></svg>,
};

const categories = [
  { name: 'Trending', icon: icons.Trending },
  { name: 'For creator', icon: icons.ForCreator },
  { name: 'For business', icon: icons.ForBusiness },
];

const badWordsString = process.env.REACT_APP_BAD_WORDS || '';

const StyledTabs = styled(({ darkMode, ...props }) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ darkMode }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    width: "100%",
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    // backgroundColor: "#fb5183",
    backgroundImage: "linear-gradient(115deg, #4fcf70, #fad648, #a767e5, #12bcfe, #44ce7b)"
  },
}));

const TabLabel = ({ icon, label }) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
    {icon}
    <span>{label}</span>
  </div>
);

const StyledTab = styled(({ darkMode, ...props }) => (
  <Tab disableRipple {...props} />
))(({ darkMode }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: 16,
  fontFamily: 'inherit',
  letterSpacing: "-0.01em",
  backgroundColor: 'transparent',
  color: darkMode ? '#9ca3af' : '#15293c80', // Updated color for dark mode
  '&.Mui-selected': {
    color: darkMode ? '#ffffff' : 'black', // Brighter white for selected tab in dark mode
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: "-0.01em"
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="overflow-auto mt-2">
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const PackSkeleton = ({ darkMode }) => (
  <Card
    sx={{
      backgroundColor: darkMode ? '#1a1a1a' : 'white',
      // border: darkMode ? '1px solid #333' : 'none',
      borderRadius: 2,
      overflow: 'hidden', // Ensure shimmer stays within bounds
      position: 'relative', // For shimmer positioning
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `linear-gradient(
          90deg,
          transparent 0%,
          ${darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.5)'} 50%,
          transparent 100%
        )`,
        animation: 'shimmer 1.5s infinite',
        transform: 'translateX(-100%)',
      },
      '@keyframes shimmer': {
        '100%': {
          transform: 'translateX(100%)',
        },
      },
    }}
  >
    <Skeleton
      variant="rectangular"
      height={187}
      animation="wave"
      sx={{
        backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.06)',
        transform: 'scale(1)', // Prevents weird animation artifacts
      }}
    />
    <CardContent>
      <Skeleton
        variant="text"
        width="60%"
        height={24}
        animation="wave"
        sx={{
          backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.06)',
          transform: 'scale(1)',
          marginBottom: 1,
        }}
      />
      <Skeleton
        variant="text"
        width="90%"
        height={20}
        animation="wave"
        sx={{
          backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.06)',
          transform: 'scale(1)',
        }}
      />
    </CardContent>
  </Card>
);

const PackItem = ({ item, onClick, darkMode }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
  <Card
    onClick={onClick}
    sx={{
      borderRadius: 2,
      cursor: 'pointer',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      transition: 'transform 0.2s, box-shadow 0.2s',
      backgroundColor: darkMode ? '#1a1a1a' : 'white',
      // border: darkMode ? '1px solid #333' : 'none', // Added border for dark mode
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: darkMode ? '0 4px 20px rgba(0,0,0,0.5)' : 6, // Custom shadow for dark mode
      },
    }}
  >
    <div>
      <div className="relative aspect-w-16 aspect-h-9">
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.06)',
            transform: 'scale(1)',
            display: imageLoaded ? 'none' : 'block',
          }}
        />
        <img 
          src={item.image} 
          alt={item.title} 
          className="w-full h-full object-cover"
          onLoad={() => setImageLoaded(true)}
        />
        {item.isHot && (
          <div
            className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full"
            style={{ marginTop: '-12px' }}
          >
            Hot
          </div>
        )}
      </div>
      <CardContent>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          gutterBottom
          fontFamily="inherit"
          sx={{ color: darkMode ? '#ffffff' : '#000000' }}
        >
          {item.title}
        </Typography>
        <Typography
          fontFamily="inherit"
          variant="body1"
          sx={{
            color: darkMode ? '#9ca3af' : '#6b7280',
            fontSize: '12px',
          }}
        >
          {item.description}
        </Typography>
      </CardContent>
    </div>
  </Card>
  )
};

const ExploreStyles = ({
  onShowStyle,
  stylesList,
  darkMode,
  handleSelectCollection,
  trendingPacks,
  forCreatorPacks,
  forBusinessPacks
}) => {
  const [currentStyles, setCurrentStylesList] = useState([]);
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    let filteredStyles;

    if (newValue === 0) {
      filteredStyles = stylesList.filter(style => style.type === "new");
    } else if (newValue === 1) { // custom items
      filteredStyles = stylesList.filter(style => style.type === "custom");
    }

    setCurrentStylesList(filteredStyles);
  };

  const openPhotoPipeLine = (e, item) => {
    handleSelectCollection(item);
    onShowStyle(false);
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: darkMode ? '#373737' : '#f1f3f4' }}>
        <StyledTabs
          darkMode={darkMode}
          value={value}
          onChange={handleChangeTab}
          aria-label="styled tabs example"
          variant="scrollable"
          scrollButtons={false}
        >
          {categories.map((category, index) => (
            <StyledTab
              key={index}
              darkMode={darkMode}
              label={<TabLabel icon={category.icon} label={category.name} />}
            />
          ))}
        </StyledTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box sx={{ pt: 2 }}>
          <Grid container spacing={3}>
            {isLoading ? (
              Array.from(new Array(6)).map((_, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <PackSkeleton darkMode={darkMode} />
                </Grid>
              ))
            ) : (
              trendingPacks.map((item, key) => (
                <Grid item xs={12} md={4} key={key}>
                  <PackItem
                    item={item}
                    onClick={(e) => openPhotoPipeLine(e, item)}
                    darkMode={darkMode}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box sx={{ pt: 2 }}>
          <Grid container spacing={3}>
            {isLoading ? (
              Array.from(new Array(6)).map((_, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <PackSkeleton darkMode={darkMode} />
                </Grid>
              ))
            ) : (
              forCreatorPacks.map((item, key) => (
                <Grid item xs={12} md={4} key={key}>
                  <PackItem
                    item={item}
                    onClick={(e) => openPhotoPipeLine(e, item)}
                    darkMode={darkMode}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Box sx={{ pt: 2 }}>
          <Grid container spacing={3}>
            {isLoading ? (
              Array.from(new Array(6)).map((_, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <PackSkeleton darkMode={darkMode} />
                </Grid>
              ))
            ) : (
              forBusinessPacks.map((item, key) => (
                <Grid item xs={12} md={4} key={key}>
                  <PackItem
                    item={item}
                    onClick={(e) => openPhotoPipeLine(e, item)}
                    darkMode={darkMode}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </CustomTabPanel>
    </>
  );
};

export default ExploreStyles;
