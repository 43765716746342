import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';

const exampleImages = [
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-qxa2wahnmdrm00cjcsjaze7w74_0.jpg?generation=1728294730574490&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-w2g8jj70adrm00cjcszbnf3htw_1.jpg?generation=1728296474732532&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-pyejnyrg7hrm00cjctgam6w5pr_0.jpg?generation=1728298688259903&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-09ddcdadahrj60cjctr86dwtg8.jpg?generation=1728299705253085&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-h3j6s2wpg1rm40cjd78a2m3sp4_3.jpg?generation=1728352207836995&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-eaqg2dm3nnrm40cjct1st5jsdc_0.jpg?generation=1728296791938242&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-530c1t11zdrm60cjh9m91dz390_0.jpg?generation=1728898964111185&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-st53c3f28nrm20cjh3hvrtzw4w_2.jpg?generation=1728873517530471&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-kd70q14551rm20cjfbprptnt2w_2.jpg?generation=1728639270320099&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-64jkr1y6hnrm00cjh8yt77b8j0_1.jpg?generation=1728896187989319&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-mfg4vhqb7srm00cjfbw8rse3wg_3.jpg?generation=1728640017121092&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-jkx2yr552drm20cjfbwr4kax18_2.jpg?generation=1728640059998725&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-n5fp2cd9gxrm20cjha5v3sv4dc_1.jpg?generation=1728901289265069&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-hr2fer8pzxrm40cjh94rt2h1jw_3.jpg?generation=1728896926422920&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-9fe20d847drm40cjh99sxdkr6r_3.jpg?generation=1728897579123939&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-xbmsxy91r5rm00cjh97arc1kbc_2.jpg?generation=1728897256644317&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-e0e9jm7z0hrm40cjcsk8zztna8_3.jpg?generation=1728294909433701&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-px1ehxf315rm60cjd7yts0xa8r_1.jpg?generation=1728355131870361&alt=media',
];

const CURRENT_UPDATE_VERSION = '1.0.0';

const PopupLatestUpdate = ({ darkMode, openStudio }) => {
       
    const [isOpened, setIsOpened] = useState(false);
    const [currentView, setCurrentView] = useState('main');
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [activeTab, setActiveModelTab] = useState('1');

    const dialogContentRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === exampleImages.length - 1 ? 0 : prevIndex + 1
            );
        }, 300); // Change image every 300ms

        return () => clearInterval(interval);
        

    }, []);

    useEffect(() => {
        // Get the last seen version from localStorage
        const lastSeenVersion = localStorage.getItem('lastSeenUpdateVersion');
        // Show popup if user hasn't seen this version
        if (!lastSeenVersion || lastSeenVersion !== CURRENT_UPDATE_VERSION) {
            setTimeout(() => {
                setIsOpened(true);
            }, 2500);
        }
    }, []);

    const handleGotIt = () => {
        // Store the current version as the last seen version
        localStorage.setItem('lastSeenUpdateVersion', CURRENT_UPDATE_VERSION);
        setIsOpened(false);
    };


    const tryHeadshot = () => {
        navigate('/app/?pack=ai_headshots&view=start');
    }

    const renderContent = () => {

        const handleTabChange = key => {
            setActiveModelTab(key);
          };

        // Return main content
        return (
            <Box sx={{ position: 'relative' }}>
                <Box
                    sx={{
                        width: '100%',
                        height: 280,
                        display: 'flex',
                        position: 'relative',
                        backgroundColor: '#000',
                        overflow: 'hidden',
                        willChange: 'transform',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            animation: 'slideImages 60s linear infinite',
                            willChange: 'transform',
                            transform: 'translate3d(0, 0, 0)',
                            backfaceVisibility: 'hidden',
                            '@keyframes slideImages': {
                                '0%': {
                                    transform: `translate3d(calc(-280px * ${exampleImages.length}), 0, 0)`,
                                },
                                '100%': {
                                    transform: 'translate3d(0, 0, 0)',
                                },
                            },
                            '&:hover': {
                                animationPlayState: 'paused',
                            },
                        }}
                    >
                        {[...exampleImages, ...exampleImages, ...exampleImages].map((image, index) => (
                            <Box
                                key={index}
                                sx={{
                                    position: 'relative',
                                    width: '180px',
                                    // height: '280px',
                                    flexShrink: 0,
                                }}
                            >
                                <Box
                                    component="img"
                                    src={image}
                                    loading="lazy"
                                    decoding="async"
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        borderRadius: '10px',
                                        willChange: 'transform',
                                        transform: 'translate3d(0, 0, 0)',
                                        WebkitFontSmoothing: 'antialiased',
                                        transition: 'transform 0.3s ease',
                                        opacity: 0,
                                        animation: 'fadeIn 0.5s ease forwards',
                                        '@keyframes fadeIn': {
                                            to: { opacity: 1 }
                                        },
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                        },
                                    }}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
                <IconButton
                    onClick={() => setIsOpened(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Box
                    sx={{
                        textAlign: 'center',
                        pt: 6,
                        pb: 4,
                        px: 3,
                    }}
                >
                    <Typography
                        variant="h5"
                        component="h2"
                        sx={{
                            fontWeight: 700,
                            color: darkMode ? 'white' : 'black',
                            mb: 1,
                        }}
                    >
                        Latest Updates! 🚀
                    </Typography>
                    <Typography
                        variant="h5"
                        component="h2"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontWeight: 700,
                            color: darkMode ? 'white' : 'black',
                            mb: 1,
                        }}
                    >
                    <div style={{maxWidth: '300px'}} className={`mt-4 mb-4 w-full ${darkMode ? 'dark-border-full' : 'light-border-full'} rounded-lg`}>
                        <div className="flex">
                            <button
                            className={`w-full py-3 px-4 text-center transition-colors duration-200 ease-in-out
                                ${activeTab === '1'
                                ? darkMode
                                    ? 'dark-bg-tab text-white border-b-2 border-pink-500'
                                    : 'gray-bg-tab text-black border-b-2 border-pink-500'
                                : darkMode
                                    ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                                    : 'text-gray-600 hover:bg-gray-200'
                                }
                            `}
                            style={{ borderRadius: '4px', fontSize: '14px', margin: '4px' }}
                            onClick={() => handleTabChange('1')}
                            >
                            AI Headshot
                            </button>
                            <button
                            className={`w-full py-3 px-4 text-center transition-colors duration-200 ease-in-out
                                ${activeTab === '2'
                                ? darkMode
                                    ? 'dark-bg-tab text-white border-b-2'
                                    : 'gray-bg-tab text-black border-b-2 border-pink-500'
                                : darkMode
                                    ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                                    : 'text-gray-600 hover:bg-gray-200'
                                }
                            `}
                            style={{ borderRadius: '4px', fontSize: '14px', margin: '4px' }}
                            onClick={() => handleTabChange('2')}
                            >
                            AI Photography
                            </button>
                        </div>
                    </div>
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: darkMode ? '#fff' : '#666666',
                        }}
                    >
                        {activeTab === '1' &&
                            <>
                                <h3>
                                    Introducing the easiest way to generate Professional Headshots for BUSINESSES, JOBS, and MORE without being on-camera. You have never seen a level of realism like this before.
                                </h3>
                                <Box sx={{ position: 'relative', mt: 8, mb: 4 }}>
                                    {/* Steps Grid */}
                                    <div className="flex items-center justify-center">
                                        <div className="pipeline-wrapper w-full max-w-4xl">
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 relative">
                                                {/* Connecting lines for desktop */}
                                                <div className="hidden md:block absolute top-1/2 left-0 w-full h-1 bg-gradient-to-r from-pink-500 to-purple-500 -translate-y-1/2 z-0" />

                                                {[
                                                    { title: 'Select your appearance', image: 'images/guide-step-1.webp' },
                                                    { title: 'Upload 10+ photos of yourself', image: 'images/guide-step-2.webp' },
                                                    { title: 'Pay for your headshots package', image: 'images/guide-step-3.webp' },
                                                    { title: 'Finally, receive your Headshots', images: exampleImages },
                                                ].map((step, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            position: 'relative',
                                                            zIndex: 10,
                                                            transform: 'translateZ(0)', // Performance optimization
                                                        }}
                                                    >
                                                        {index + 1 !== 4 && (
                                                            <Box
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: -20,
                                                                    left: '50%',
                                                                    transform: 'translateX(-50%)',
                                                                    zIndex: 2,

                                                                    // Size & Shape
                                                                    width: 40,
                                                                    height: 40,
                                                                    borderRadius: '50%',

                                                                    // Visual Style
                                                                    background: `linear-gradient(135deg, #fc5185 0%, #f78ca0 100%)`,
                                                                    boxShadow: '0 4px 20px rgba(252, 81, 133, 0.3)',

                                                                    // Content Alignment
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',

                                                                    // Text Style
                                                                    color: 'white',
                                                                    fontWeight: 700,
                                                                    fontSize: '1.125rem',

                                                                    // Animation
                                                                    animation: 'pulse 2s infinite',
                                                                    '@keyframes pulse': {
                                                                        '0%': {
                                                                            boxShadow: '0 0 0 0 rgba(252, 81, 133, 0.4)',
                                                                        },
                                                                        '70%': {
                                                                            boxShadow: '0 0 0 10px rgba(252, 81, 133, 0)',
                                                                        },
                                                                        '100%': {
                                                                            boxShadow: '0 0 0 0 rgba(252, 81, 133, 0)',
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Box>
                                                        )}

                                                        {/* Step Content Container */}
                                                        <Box
                                                            sx={{
                                                                // Base Style
                                                                height: '100%',
                                                                backgroundColor: darkMode ? '#000' : '#FFFFFF',
                                                                backdropFilter: 'blur(10px)',
                                                                p: 3.5,
                                                                borderRadius: 3,

                                                                // Border & Shadow
                                                                border: `1px solid ${darkMode ? '#2A2A2A' : '#E0E0E0'}`,
                                                                boxShadow: darkMode ? '0 4px 30px rgba(0, 0, 0, 0.3)' : '0 4px 30px rgba(0, 0, 0, 0.1)',

                                                                // Hover Effects
                                                                transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                                                                '&:hover': {
                                                                    transform: 'translateY(-8px)',
                                                                    boxShadow: darkMode ? '0 8px 40px rgba(236, 72, 153, 0.2)' : '0 8px 40px rgba(236, 72, 153, 0.15)',
                                                                    borderColor: '#fc5185',
                                                                    '& img': {
                                                                        transform: 'scale(1.05)',
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <div key={index}>
                                                                <div className="flex items-center mb-4">
                                                                    <h3 className={`ml-4 text-sm font-semibold ${darkMode ? 'text-white' : 'text-gray-800'}`}>
                                                                        {step.title}
                                                                        {index === 3 && 
                                                                            <button style={{marginLeft: "6px"}} className="px-3 rounded-full bg-pink-500 text-white"
                                                                                onClick={() => tryHeadshot()}
                                                                            >
                                                                                Try it
                                                                            </button>
                                                                        }
                                                                    </h3>
                                                                </div>
                                                                <div className="relative aspect-video rounded-lg overflow-hidden shadow-lg"
                                                                    style={{ height: step.images ? '290px' : '100%' }}> {/* Conditional height */}
                                                                    {step.images ? (
                                                                        <div className="image-cycle-container h-full">
                                                                            <img
                                                                                src={step.images[currentImageIndex]}
                                                                                alt={`${step.title} result`}
                                                                                className="w-full h-full object-cover"
                                                                                style={{
                                                                                    borderRadius: '10px',
                                                                                    transition: 'opacity 0.2s ease-in-out',
                                                                                    objectPosition: 'center', // Center the image
                                                                                    objectFit: 'cover',      // Maintain aspect ratio while covering container
                                                                                    display: 'block',        // Remove any default spacing
                                                                                    margin: '0 auto'         // Center horizontally
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <img
                                                                            src={step.image}
                                                                            alt={step.title}
                                                                            className="w-full h-full object-cover"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </>
                        }
                        {activeTab === '2' &&
                            <>
                                <h3>
                                    Taking Photography to the next with full CONTROL over any STYLE, POSE, BACKGROUND, LOCATION, and more. You have never seen a level of realism like this before.
                                </h3>
                                <Box sx={{ position: 'relative', mt: 8, mb: 4 }}>
                                    {/* Steps Grid */}
                                    <div className="flex items-center justify-center">
                                        <div className="pipeline-wrapper w-full max-w-4xl">
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 relative">
                                                {/* Connecting lines for desktop */}
                                                <div className="hidden md:block absolute top-1/2 left-0 w-full h-1 bg-gradient-to-r from-pink-500 to-purple-500 -translate-y-1/2 z-0" />

                                                {[
                                                    { title: 'Train your own model', image: 'images/model-training.webp' },
                                                    { title: 'Use Advanced mode in Studio', image: 'images/use-advanced.webp' },
                                                    { title: 'Prompt your style or Transfer from an image', image: 'images/prompt.webp' },
                                                    { title: 'Finally, receive your Photography', images: exampleImages },
                                                ].map((step, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            position: 'relative',
                                                            zIndex: 10,
                                                            transform: 'translateZ(0)', // Performance optimization
                                                        }}
                                                    >
                                                        {index + 1 !== 4 && (
                                                            <Box
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: -20,
                                                                    left: '50%',
                                                                    transform: 'translateX(-50%)',
                                                                    zIndex: 2,

                                                                    // Size & Shape
                                                                    width: 40,
                                                                    height: 40,
                                                                    borderRadius: '50%',

                                                                    // Visual Style
                                                                    background: `linear-gradient(135deg, #fc5185 0%, #f78ca0 100%)`,
                                                                    boxShadow: '0 4px 20px rgba(252, 81, 133, 0.3)',

                                                                    // Content Alignment
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',

                                                                    // Text Style
                                                                    color: 'white',
                                                                    fontWeight: 700,
                                                                    fontSize: '1.125rem',

                                                                    // Animation
                                                                    animation: 'pulse 2s infinite',
                                                                    '@keyframes pulse': {
                                                                        '0%': {
                                                                            boxShadow: '0 0 0 0 rgba(252, 81, 133, 0.4)',
                                                                        },
                                                                        '70%': {
                                                                            boxShadow: '0 0 0 10px rgba(252, 81, 133, 0)',
                                                                        },
                                                                        '100%': {
                                                                            boxShadow: '0 0 0 0 rgba(252, 81, 133, 0)',
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Box>
                                                        )}

                                                        {/* Step Content Container */}
                                                        <Box
                                                            sx={{
                                                                // Base Style
                                                                height: '100%',
                                                                backgroundColor: darkMode ? '#000' : '#FFFFFF',
                                                                backdropFilter: 'blur(10px)',
                                                                p: 3.5,
                                                                borderRadius: 3,

                                                                // Border & Shadow
                                                                border: `1px solid ${darkMode ? '#2A2A2A' : '#E0E0E0'}`,
                                                                boxShadow: darkMode ? '0 4px 30px rgba(0, 0, 0, 0.3)' : '0 4px 30px rgba(0, 0, 0, 0.1)',

                                                                // Hover Effects
                                                                transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                                                                '&:hover': {
                                                                    transform: 'translateY(-8px)',
                                                                    boxShadow: darkMode ? '0 8px 40px rgba(236, 72, 153, 0.2)' : '0 8px 40px rgba(236, 72, 153, 0.15)',
                                                                    borderColor: '#fc5185',
                                                                    '& img': {
                                                                        transform: 'scale(1.05)',
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <div key={index}>
                                                                <div className="flex items-center mb-4">
                                                                    <h3 className={`ml-4 text-sm font-semibold ${darkMode ? 'text-white' : 'text-gray-800'}`}>
                                                                        {step.title}
                                                                        {index === 3 && 
                                                                            <button style={{marginLeft: "6px"}} className="px-3 rounded-full bg-pink-500 text-white"
                                                                                onClick={() => openStudio()}
                                                                            >
                                                                                Try it
                                                                            </button>
                                                                        }
                                                                    </h3>
                                                                </div>
                                                                <div className="relative aspect-video rounded-lg overflow-hidden shadow-lg"
                                                                    style={{ height: step.images ? '290px' : '100%' }}> {/* Conditional height */}
                                                                    {step.images ? (
                                                                        <div className="image-cycle-container h-full">
                                                                            <img
                                                                                src={step.images[currentImageIndex]}
                                                                                alt={`${step.title} result`}
                                                                                className="w-full h-full object-cover"
                                                                                style={{
                                                                                    borderRadius: '10px',
                                                                                    transition: 'opacity 0.2s ease-in-out',
                                                                                    objectPosition: 'center', // Center the image
                                                                                    objectFit: 'cover',      // Maintain aspect ratio while covering container
                                                                                    display: 'block',        // Remove any default spacing
                                                                                    margin: '0 auto'         // Center horizontally
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <img
                                                                            src={step.image}
                                                                            alt={step.title}
                                                                            className="w-full h-full object-cover"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </>
                        }
                    </Typography>
                    {/* <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: {
                                xs: '1fr', // 1 column for mobile
                                sm: 'repeat(2, 1fr)', // 2 columns for tablets
                                md: 'repeat(3, 1fr)', // 3 columns for desktop
                            },
                            gap: { xs: 2, sm: 2, md: 2 },
                            px: { xs: 1, sm: 2 }, // Reduce padding on mobile
                            mb: 4,
                        }}
                    >
                        {creatorOptions.map((option, index) => (
                            <StyledCard
                                key={index}
                                onClick={option.onClick}
                                sx={{
                                    bgcolor: darkMode ? '#2A2A2A' : '#F8F9FA',
                                    p: 3,
                                    cursor: 'pointer',
                                    transition: 'all 0.2s ease-in-out',
                                    border: 'none',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        transform: 'translateY(-4px)',
                                        bgcolor: darkMode ? '#333333' : '#F0F0F0',
                                    },
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '4px',
                                        background: 'linear-gradient(115deg, #4fcf70, #fad648, #a767e5, #12bcfe, #44ce7b);',
                                        opacity: 0,
                                        transition: 'opacity 0.3s ease',
                                    },
                                    '&:hover::before': {
                                        opacity: 1,
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                <Box
                                    sx={{
                                        color: darkMode ? 'white' : 'black',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mb: 1,
                                    }}
                                >
                                    {option.icon}
                                </Box>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 600,
                                        color: darkMode ? 'white' : 'black',
                                        textAlign: 'center',
                                    }}
                                >
                                    {option.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: darkMode ? '#888888' : '#666666',
                                        textAlign: 'center',
                                        fontSize: '0.875rem',
                                    }}
                                >
                                    {option.description}
                                </Typography>
                            </StyledCard>
                        ))}
                    </Box> */}
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <button 
                            onClick={() => handleGotIt()}
                            className={`px-6 py-3 rounded-full font-semibold bg-pink-500 text-white hover:bg-pink-600`}>
                            I Got It
                        </button>
                    </Box>
                </Box>
            </Box>
        );
    };

    return (
        <>
            <Dialog
                open={isOpened}
                onClose={() => setIsOpened(false)}
                maxWidth="md"
                fullWidth
                BackdropProps={{
                    sx: {
                        backdropFilter: 'blur(8px)',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                }}
                PaperProps={{
                    sx: {
                        borderRadius: '12px',
                        bgcolor: darkMode ? '#000' : '#FFFFFF',
                        backgroundImage: 'none',
                        border: darkMode ? '1px solid #2A2A2A' : '1px solid #E0E0E0 ',
                        margin: { xs: 2, sm: 3 }, // Add margin on small screens
                    },
                }}
            >
                <DialogContent ref={dialogContentRef} sx={{ p: 0 }}>{renderContent()}</DialogContent>
            </Dialog>
        </>
    );
};

export default PopupLatestUpdate;