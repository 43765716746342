import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setUser } from '../../actions';
// import { withFirebase } from '../../services/engine';

import Spinner from '../../components/Spinner';
import withBackend from '../../backend/withBackend';
import { Navigate } from 'react-router-dom';
import * as routes from '../../constants/routes';
import { message } from '../../components/Message';
import { v4 } from 'uuid';

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

const LOCATION = process.env.REACT_APP_LOCATION;

const RANDOM_CODE = Math.floor(Math.random() * 900000) + 100000;
const RANDOM_TRIAL_CODE = Math.floor(Math.random() * 900000) + 100000;

let initialUserData = {
  uid: null,
  role: "USER",
  email: "",
  joinedDate: new Date().getTime(),
  lastLoginDate: null,
  firstName: "",
  lastName: "",
  phone: null,
  phoneCode: 84,
  phoneShort: "VN",
  profilePicUrl: null,
  companyName: null,
  country: null,
  birthDate: null,
  gender: null,
  address: null,
  city: null,
  status: "incomplete",
  isActivated: true,
  hasSubscription: false,
  emailVerified: false,
  proTrial: process.env.REACT_APP_PRO_TRIAL,
  basicTrial: process.env.REACT_APP_BASIC_TRIAL,
  upscaleTrial: process.env.REACT_APP_UPSCALE_TRIAL,
  pro: false,
  basic: true,
  trial: true,
  monthlyBasicCode: `REOK ${RANDOM_CODE}`,
  yearlyBasicCode: `YB ${RANDOM_CODE}`,
  monthlyProCode: `MP ${RANDOM_CODE}`,
  yearlyProCode: `YP ${RANDOM_CODE}`,
  trialCode: `REOK ${RANDOM_TRIAL_CODE}`,
  proCredits: 0,
  basicCredits: 30,
  refCode: ''
}

// function generateVerificationCode() {
//   const length = 6; // length of verification code
//   const chars = '0123456789abcdefghijklmnopqrstuvwxyz'; // characters to use for code
//   let code = '';
//   for (let i = 0; i < length; i++) {
//     code += chars[Math.floor(Math.random() * chars.length)];
//   }
//   return code;
// }

class VerifyEmailContainer extends Component {
  state = {
    loading: false,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    error: null,
    sent: 0,
    refCode: '',
    emailLinkSignInAttempted: false,
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e, t) => {
    e.preventDefault();

    const { engine } = this.props;
    const { firstName, lastName, email, password, refCode, redirect } = this.state;

    if (redirect === "tiktok") {
      alert(t('message.error.tiktok'));
      return;
    }

    this.setState({ loading: true });

    initialUserData.firstName = firstName;
    initialUserData.lastName = lastName;
    initialUserData.email = email;
    initialUserData.refCode = refCode;

    engine
      .signUp(email, password)
      .then(response => {
        engine.sendEmailVerificationLink();
        initialUserData.uid = response.user.uid;
        initialUserData.emailVerified = response.user.emailVerified;
        engine.addUser(response.user.uid, initialUserData);
        this.setState({ loading: false, sent: 1 });

        const randomUUID = v4();
        const refLink = `https://reok.pro/vi/?ref=${randomUUID}`;
        const data = { uid: response.user.uid, email: response.user.email, code: randomUUID, refLink: refLink, isActive: true, createdAt: new Date().toISOString() }

        engine.addAffMembers(data)
          .then(querySnapshot => { })
          .catch(error => {
            if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
              error.message = t('message.error.accountExist');
            }
            this.setState({ error });
          });

        message.success(t('message.success.signIn'));

        // if ((LOCATION === "en")) {
        //   message.success("We just sent an email to activate your account. Please check it!");
        // } else {
        //   message.success("Chúng tôi đã gửi 1 email để kích hoạt tài khoản của bạn, vui lòng kiểm tra email!");
        // }
      }
      )
      .then(() => { this.setState({ loading: false }) })
      .catch(error => this.setState({ error, loading: false }));
  };

  componentDidMount() {
    this.attemptEmailLinkSignIn();
  }

  attemptEmailLinkSignIn = () => {
    const storedReferralCode = localStorage.getItem('refCode');

    if (storedReferralCode) {
      this.setState({ refCode: storedReferralCode });
    }

    const redirect = localStorage.getItem('redirect');

    if (redirect) {
      this.setState({ redirect: redirect });
    }

    const { engine, setUser, t } = this.props;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.oobCode && params.email && !this.state.emailLinkSignInAttempted) {
      const isSignInWithEmailLink = engine.isSignInWithEmailLink(window.location.href);
      if (isSignInWithEmailLink) {

        engine.signInWithEmailLink(params.email, window.location.href)
          .then((response) => {
            engine.getUser(response.user.uid)
            .then(querySnapshot => {
              if (querySnapshot.data() && querySnapshot.data().emailVerified && querySnapshot.data().email) {
                setUser(querySnapshot.data())
              } else {
                const { refCode } = this.state;
  
                initialUserData.uid = response.user.uid;
                initialUserData.email = response.user.email;
                initialUserData.firstName = response.user.displayName || response.user.email.split('@')[0];
                initialUserData.emailVerified = true;
                initialUserData.refCode = refCode;
                initialUserData.proCredits = 0;
                initialUserData.pro = false;
                initialUserData.trial = true;
  
                engine.addUser(response.user.uid, initialUserData)
                  .then(() => engine.getUser(engine.auth.currentUser.uid))
                  .then(querySnapshot => setUser(querySnapshot.data()))
                  .catch(error => {
                    if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                      error.message = t('message.error.accountExist');
                    }
                    this.setState({ error });
                  });
  
                const randomUUID = v4();
                const refLink = `https://reok.pro/vi/?ref=${randomUUID}`;
                const data = { uid: response.user.uid, email: response.user.email, code: randomUUID, refLink: refLink, isActive: true, createdAt: new Date().toISOString() }
  
                engine.addAffMembers(data)
                  .then(querySnapshot => { })
                  .catch(error => {
                    if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                      error.message = t('message.error.accountExist');
                    }
                    this.setState({ error });
                  });
              }
            })
            .catch(error => {
              console.log(error);
              this.setState({ loading: false });
            });

          })
          .catch(error => {
            console.log(error);
            this.setState({ loading: false });
          });
      }
    }

    // Load reCAPTCHA script dynamically
    // const script = document.createElement('script');
    // script.src = 'https://www.google.com/recaptcha/api.js';
    // script.async = true;
    // script.defer = true;
    // document.head.appendChild(script);
  };


  render() {
    const { firstName, lastName, email, password, error, loading, sent } = this.state;

    return (
      <>
        {sent ? <Navigate to={routes.SIGN_UP} replace={true} /> : null}
        <Spinner/>      
      </>
    );
  }
}

export default compose(
  connect(null, { setUser }),
  withBackend
)(VerifyEmailContainer);
