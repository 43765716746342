class NetworkManager {
  constructor() {
    this.listeners = new Set();
    this.isOnline = navigator.onLine;
    this.wasOffline = false;
    this.reconnectionTimeout = null;
    
    window.addEventListener('online', this.handleNetworkChange.bind(this));
    window.addEventListener('offline', this.handleNetworkChange.bind(this));
  }

  handleNetworkChange = () => {
    const isCurrentlyOnline = navigator.onLine;
    
    // Check if we're transitioning from offline to online
    if (isCurrentlyOnline && !this.isOnline) {
      this.handleReconnection();
    }

    this.isOnline = isCurrentlyOnline;
    this.notifyListeners({
      online: this.isOnline,
      timestamp: new Date()
    });
  }

  handleReconnection = () => {
    const countdown = 1;
    this.showReconnectionAlert(countdown);
    
    this.reconnectionTimeout = setTimeout(() => {
      window.location.reload();
    }, countdown * 1000);
  }

  cancelReconnection = () => {
    if (this.reconnectionTimeout) {
      clearTimeout(this.reconnectionTimeout);
      this.reconnectionTimeout = null;
    }
  }

  showReconnectionAlert = (seconds) => {
    const message = `Connection restored!`;
    if ('Notification' in window && Notification.permission === 'granted') {
      new Notification(message);
    } else {
      alert(message);
    }
  }

  subscribe = (callback) => {
    this.listeners.add(callback);
    // Immediately notify the new subscriber of current status
    callback({
      online: this.isOnline,
      timestamp: new Date()
    });
    
    return () => this.listeners.delete(callback);
  }

  notifyListeners = (status) => {
    this.listeners.forEach(listener => listener(status));
  }
}

export const networkManager = new NetworkManager();