import React, { useEffect, useRef, useState, memo } from 'react';
import { message } from '../Message';
import { FormControl, MenuItem, Select } from '@mui/material';
import Stepper from '@mui/joy/Stepper';
import Step from '@mui/joy/Step';
import StepButton from '@mui/joy/StepButton';
import StepIndicator from '@mui/joy/StepIndicator';
import Check from '@mui/icons-material/Check';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import heic2any from 'heic2any';
import { ArrowRight, ArrowLeft } from 'lucide-react';
import { CircularProgress } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import NewPricing from '../NewPricing';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveStep, setCurrentPhotoPack, updateStepData } from '../../store/slices/photoStepSlice';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const steps = ['Start', 'Upload photos', 'Pay'];

const genders = [
  { name: 'Man', icon: '👨‍💼', type: 'man' },
  { name: 'Woman', icon: '👩‍💼', type: 'woman' },
];

const eyesIcons = {
  brown: (
    <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.36.15C.197.15.106.277.074.333.067.345.064.35.064.359s.004.014.012.026C.115.441.219.57.36.57S.605.441.644.385C.652.374.656.368.656.359S.653.345.646.333C.614.277.523.15.36.15Z"
        stroke="#8B4513"
        strokeWidth=".06"
      />
      <path fill="#8B4513" d="M.48.36a.12.12 0 0 1-.12.12.12.12 0 0 1-.12-.12.12.12 0 0 1 .24 0" />
    </svg>
  ),
  darkBrown: (
    <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.36.15C.197.15.106.277.074.333.067.345.064.35.064.359s.004.014.012.026C.115.441.219.57.36.57S.605.441.644.385C.652.374.656.368.656.359S.653.345.646.333C.614.277.523.15.36.15Z"
        stroke="#5D4037"
        strokeWidth=".06"
      />
      <path fill="#5D4037" d="M.48.36a.12.12 0 0 1-.12.12.12.12 0 0 1-.12-.12.12.12 0 0 1 .24 0" />
    </svg>
  ),
  lightBrown: (
    <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.36.15C.197.15.106.277.074.333.067.345.064.35.064.359s.004.014.012.026C.115.441.219.57.36.57S.605.441.644.385C.652.374.656.368.656.359S.653.345.646.333C.614.277.523.15.36.15Z"
        stroke="#D2691E"
        strokeWidth=".06"
      />
      <path fill="#D2691E" d="M.48.36a.12.12 0 0 1-.12.12.12.12 0 0 1-.12-.12.12.12 0 0 1 .24 0" />
    </svg>
  ),
  blue: (
    <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.36.15C.197.15.106.277.074.333.067.345.064.35.064.359s.004.014.012.026C.115.441.219.57.36.57S.605.441.644.385C.652.374.656.368.656.359S.653.345.646.333C.614.277.523.15.36.15Z"
        stroke="#1E90FF"
        strokeWidth=".06"
      />
      <path fill="#1E90FF" d="M.48.36a.12.12 0 0 1-.12.12.12.12 0 0 1-.12-.12.12.12 0 0 1 .24 0" />
    </svg>
  ),
  lightBlue: (
    <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.36.15C.197.15.106.277.074.333.067.345.064.35.064.359s.004.014.012.026C.115.441.219.57.36.57S.605.441.644.385C.652.374.656.368.656.359S.653.345.646.333C.614.277.523.15.36.15Z"
        stroke="#87CEFA"
        strokeWidth=".06"
      />
      <path fill="#87CEFA" d="M.48.36a.12.12 0 0 1-.12.12.12.12 0 0 1-.12-.12.12.12 0 0 1 .24 0" />
    </svg>
  ),
  green: (
    <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.36.15C.197.15.106.277.074.333.067.345.064.35.064.359s.004.014.012.026C.115.441.219.57.36.57S.605.441.644.385C.652.374.656.368.656.359S.653.345.646.333C.614.277.523.15.36.15Z"
        stroke="#228B22"
        strokeWidth=".06"
      />
      <path fill="#228B22" d="M.48.36a.12.12 0 0 1-.12.12.12.12 0 0 1-.12-.12.12.12 0 0 1 .24 0" />
    </svg>
  ),
  lightGreen: (
    <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.36.15C.197.15.106.277.074.333.067.345.064.35.064.359s.004.014.012.026C.115.441.219.57.36.57S.605.441.644.385C.652.374.656.368.656.359S.653.345.646.333C.614.277.523.15.36.15Z"
        stroke="#90EE90"
        strokeWidth=".06"
      />
      <path fill="#90EE90" d="M.48.36a.12.12 0 0 1-.12.12.12.12 0 0 1-.12-.12.12.12 0 0 1 .24 0" />
    </svg>
  ),
  hazel: (
    <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.36.15C.197.15.106.277.074.333.067.345.064.35.064.359s.004.014.012.026C.115.441.219.57.36.57S.605.441.644.385C.652.374.656.368.656.359S.653.345.646.333C.614.277.523.15.36.15Z"
        stroke="#B8860B"
        strokeWidth=".06"
      />
      <path fill="#B8860B" d="M.48.36a.12.12 0 0 1-.12.12.12.12 0 0 1-.12-.12.12.12 0 0 1 .24 0" />
    </svg>
  ),
  amber: (
    <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.36.15C.197.15.106.277.074.333.067.345.064.35.064.359s.004.014.012.026C.115.441.219.57.36.57S.605.441.644.385C.652.374.656.368.656.359S.653.345.646.333C.614.277.523.15.36.15Z"
        stroke="#FFA500"
        strokeWidth=".06"
      />
      <path fill="#FFA500" d="M.48.36a.12.12 0 0 1-.12.12.12.12 0 0 1-.12-.12.12.12 0 0 1 .24 0" />
    </svg>
  ),
  gray: (
    <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.36.15C.197.15.106.277.074.333.067.345.064.35.064.359s.004.014.012.026C.115.441.219.57.36.57S.605.441.644.385C.652.374.656.368.656.359S.653.345.646.333C.614.277.523.15.36.15Z"
        stroke="#808080"
        strokeWidth=".06"
      />
      <path fill="#808080" d="M.48.36a.12.12 0 0 1-.12.12.12.12 0 0 1-.12-.12.12.12 0 0 1 .24 0" />
    </svg>
  ),
  black: (
    <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.36.15C.197.15.106.277.074.333.067.345.064.35.064.359s.004.014.012.026C.115.441.219.57.36.57S.605.441.644.385C.652.374.656.368.656.359S.653.345.646.333C.614.277.523.15.36.15Z"
        stroke="#000000"
        strokeWidth=".06"
      />
      <path fill="#000000" d="M.48.36a.12.12 0 0 1-.12.12.12.12 0 0 1-.12-.12.12.12 0 0 1 .24 0" />
    </svg>
  ),
  violet: (
    <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.36.15C.197.15.106.277.074.333.067.345.064.35.064.359s.004.014.012.026C.115.441.219.57.36.57S.605.441.644.385C.652.374.656.368.656.359S.653.345.646.333C.614.277.523.15.36.15Z"
        stroke="#8A2BE2"
        strokeWidth=".06"
      />
      <path fill="#8A2BE2" d="M.48.36a.12.12 0 0 1-.12.12.12.12 0 0 1-.12-.12.12.12 0 0 1 .24 0" />
    </svg>
  ),
  heterochromia: (
    <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.36.15C.197.15.106.277.074.333.067.345.064.35.064.359s.004.014.012.026C.115.441.219.57.36.57S.605.441.644.385C.652.374.656.368.656.359S.653.345.646.333C.614.277.523.15.36.15Z"
        stroke="#1E90FF"
        strokeWidth=".06"
      />
      <path fill="#1E90FF" d="M.42.36a.06.06 0 0 1-.06.06.06.06 0 0 1-.06-.06.06.06 0 0 1 .12 0" />
      <path fill="#8B4513" d="M.54.36a.06.06 0 0 1-.06.06.06.06 0 0 1-.06-.06.06.06 0 0 1 .12 0" />
    </svg>
  ),
};

const eyeColors = [
  { name: 'Brown', icon: eyesIcons.brown, type: 'brown' },
  { name: 'Dark Brown', icon: eyesIcons.darkBrown, type: 'dark_brown' },
  { name: 'Light Brown', icon: eyesIcons.lightBrown, type: 'light_brown' },
  { name: 'Blue', icon: eyesIcons.blue, type: 'blue' },
  { name: 'Light Blue', icon: eyesIcons.lightBlue, type: 'light_blue' },
  { name: 'Green', icon: eyesIcons.green, type: 'green' },
  { name: 'Light Green', icon: eyesIcons.lightGreen, type: 'light_green' },
  { name: 'Hazel', icon: eyesIcons.hazel, type: 'hazel' },
  { name: 'Amber', icon: eyesIcons.amber, type: 'amber' },
  { name: 'Gray', icon: eyesIcons.gray, type: 'gray' },
  { name: 'Black', icon: eyesIcons.black, type: 'black' },
  { name: 'Violet', icon: eyesIcons.violet, type: 'violet' },
  { name: 'Heterochromia', icon: eyesIcons.heterochromia, type: 'heterochromia' },
  { name: 'Other Color', icon: '', type: 'other' },
];

const menHairIcons = {
  black: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#000"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  darkBrown: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#3b2417"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  brown: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#8b4513"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  lightBrown: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#d2691e"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  blonde: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#ffd700"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  platinumBlonde: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#e5e4e2"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  red: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#ff0000"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  auburn: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#a52a2a"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  ginger: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#ff4500"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  gray: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#808080"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  white: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#ffffff"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  blue: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#0000ff"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  green: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#008000"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  purple: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#800080"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  pink: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="#ffc0cb"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  bald: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#F6DDCD"
        d="M0.96 0.6c0 -0.066 -0.034 -0.12 -0.075 -0.12 0 0 -0.001 0 -0.001 0C0.839 0.291 0.702 0.154 0.54 0.154s-0.299 0.137 -0.344 0.326Q0.195 0.48 0.195 0.48C0.154 0.48 0.12 0.534 0.12 0.6c0 0.063 0.031 0.114 0.069 0.119C0.226 0.919 0.369 1.05 0.54 1.05s0.314 -0.131 0.351 -0.331C0.929 0.714 0.96 0.663 0.96 0.6"
      />
      <path
        fill="#662113"
        d="M0.39 0.63a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
      <path
        fill="#C1694F"
        d="M0.54 0.93c-0.126 0 -0.165 -0.032 -0.171 -0.039a0.03 0.03 0 0 1 0.041 -0.043c0.002 0.002 0.033 0.022 0.13 0.022 0.101 0 0.13 -0.022 0.13 -0.022 0.012 -0.012 0.03 -0.011 0.042 0.001 0.012 0.012 0.011 0.031 -0.001 0.043C0.705 0.898 0.666 0.93 0.54 0.93m0.03 -0.15h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06"
      />
      <path
        fill="none"
        d="M0.99 0.38a0.075 0.075 0 0 0 -0.035 -0.063c0.003 -0.008 0.005 -0.017 0.005 -0.027a0.075 0.075 0 0 0 -0.066 -0.074c0.004 -0.01 0.006 -0.02 0.006 -0.031a0.09 0.09 0 0 0 -0.09 -0.09c-0.012 0 -0.023 0.002 -0.033 0.007A0.09 0.09 0 0 0 0.69 0.034c-0.026 0 -0.049 0.011 -0.066 0.029C0.612 0.029 0.579 0.004 0.54 0.004s-0.072 0.025 -0.084 0.059A0.089 0.089 0 0 0 0.39 0.034a0.09 0.09 0 0 0 -0.087 0.067A0.09 0.09 0 0 0 0.27 0.095a0.09 0.09 0 0 0 -0.09 0.09c0 0.011 0.002 0.021 0.006 0.031A0.075 0.075 0 0 0 0.12 0.289c0 0.009 0.002 0.018 0.005 0.027a0.075 0.075 0 0 0 -0.001 0.126A0.09 0.09 0 0 0 0.12 0.469c0 0.041 0.027 0.075 0.06 0.075 0.01 0 0.019 -0.003 0.027 -0.008C0.22 0.579 0.24 0.604 0.24 0.544c0 -0.048 0 -0.077 0.023 -0.122 0.036 0.012 0.087 0.005 0.136 -0.023a0.24 0.24 0 0 0 0.024 -0.016c0.011 0.041 0.06 0.072 0.117 0.072 0.039 0 0.073 -0.014 0.095 -0.036 0.014 0.038 0.06 0.066 0.115 0.066 0.032 0 0.061 -0.01 0.083 -0.025 0.008 0.027 0.008 0.052 0.008 0.085 0 0.07 0.02 0.041 0.034 -0.007 0.008 0.004 0.016 0.007 0.025 0.007 0.033 0 0.06 -0.034 0.06 -0.075 0 -0.01 -0.002 -0.019 -0.004 -0.027A0.075 0.075 0 0 0 0.99 0.38"
      />
    </svg>
  ),
  multicolored: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z" fill="url(#rainbow)" />
      <defs>
        <linearGradient id="rainbow" x1="0" y1="0" x2="24" y2="24" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#ff0000" />
          <stop offset="16.67%" stopColor="#ff7f00" />
          <stop offset="33.33%" stopColor="#ffff00" />
          <stop offset="50%" stopColor="#00ff00" />
          <stop offset="66.67%" stopColor="#0000ff" />
          <stop offset="83.33%" stopColor="#8b00ff" />
          <stop offset="100%" stopColor="#ff00ff" />
        </linearGradient>
      </defs>
    </svg>
  ),
};

const womenHairIcons = {
  black: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#00000"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#00000"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  darkBrown: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#3b2417"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#3b2417"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  brown: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#8b4513"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#8b4513"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  lightBrown: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#d2691e"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#d2691e"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  blonde: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#ffd700"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#ffd700"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  platinumBlonde: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#e5e4e2"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#e5e4e2"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  red: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#ff0000"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#ff0000"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  auburn: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#a52a2a"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#a52a2a"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  ginger: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#ff4500"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#ff4500"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  gray: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#808080"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#808080"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  white: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#ffffff"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#ffffff"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  blue: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#0000ff"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#0000ff"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  green: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#008000"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#008000"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  purple: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#800080"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#800080"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  pink: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      ariaHidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#ffc0cb"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="#ffc0cb"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  bald: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 1.08 1.08"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      ariaHidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="none"
        d="M0.54 0.09c0.18 0 0.48 0.09 0.48 0.48s0 0.48 -0.09 0.48 -0.21 -0.09 -0.39 -0.09 -0.297 0.09 -0.39 0.09c-0.1 0 -0.09 -0.36 -0.09 -0.48C0.06 0.18 0.36 0.09 0.54 0.09"
      />
      <path
        fill="#F6DDCD"
        d="M0.18 0.557c0 -0.256 0.161 -0.463 0.36 -0.463s0.36 0.207 0.36 0.463S0.739 1.02 0.54 1.02 0.18 0.813 0.18 0.557"
      />
      <path
        fill="#DF1F32"
        d="M0.54 0.9c-0.07 0 -0.107 -0.035 -0.111 -0.039a0.03 0.03 0 0 1 0.042 -0.043c0.002 0.001 0.024 0.022 0.069 0.022 0.046 0 0.068 -0.021 0.069 -0.022a0.03 0.03 0 0 1 0.042 0.001 0.03 0.03 0 0 1 0 0.042C0.647 0.865 0.61 0.9 0.54 0.9"
      />
      <path fill="#C1694F" d="M0.57 0.75h-0.06a0.03 0.03 0 1 1 0 -0.06h0.06a0.03 0.03 0 1 1 0 0.06" />
      <path
        fill="none"
        d="M0.092 0.72c-0.001 -0.01 -0.002 -0.019 -0.002 -0.03 0 -0.15 0.09 0.017 0.09 -0.09s0.06 -0.12 0.12 -0.18l0.09 -0.09s0.15 0.09 0.27 0.09 0.24 0.06 0.24 0.18 0.09 -0.06 0.09 0.09c0 0.011 -0.001 0.02 -0.002 0.03h0.031C1.02 0.676 1.02 0.626 1.02 0.57 1.02 0.18 0.72 0.03 0.54 0.03S0.06 0.18 0.06 0.57c0 0.035 -0.001 0.09 0 0.15z"
      />
      <path
        fill="#662113"
        d="M0.39 0.66a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 0 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03m0.3 0a0.03 0.03 0 0 1 -0.03 -0.03v-0.06a0.03 0.03 0 1 1 0.06 0v0.06a0.03 0.03 0 0 1 -0.03 0.03"
      />
    </svg>
  ),
  multicolored: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z" fill="url(#rainbow)" />
      <defs>
        <linearGradient id="rainbow" x1="0" y1="0" x2="24" y2="24" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#ff0000" />
          <stop offset="16.67%" stopColor="#ff7f00" />
          <stop offset="33.33%" stopColor="#ffff00" />
          <stop offset="50%" stopColor="#00ff00" />
          <stop offset="66.67%" stopColor="#0000ff" />
          <stop offset="83.33%" stopColor="#8b00ff" />
          <stop offset="100%" stopColor="#ff00ff" />
        </linearGradient>
      </defs>
    </svg>
  ),
};

const getHairColors = gender => {
  const icons = gender === 'man' ? menHairIcons : womenHairIcons;

  return [
    { name: 'Black', icon: icons.black, type: 'black' },
    { name: 'Dark Brown', icon: icons.darkBrown, type: 'dark_brown' },
    { name: 'Brown', icon: icons.brown, type: 'brown' },
    { name: 'Light Brown', icon: icons.lightBrown, type: 'light_brown' },
    { name: 'Blonde', icon: icons.blonde, type: 'blonde' },
    { name: 'Platinum Blonde', icon: icons.platinumBlonde, type: 'platinum_blonde' },
    { name: 'Red', icon: icons.red, type: 'red' },
    { name: 'Auburn', icon: icons.auburn, type: 'auburn' },
    { name: 'Ginger', icon: icons.ginger, type: 'ginger' },
    { name: 'Gray', icon: icons.gray, type: 'gray' },
    { name: 'White', icon: icons.white, type: 'white' },
    { name: 'Blue', icon: icons.blue, type: 'blue' },
    { name: 'Green', icon: icons.green, type: 'green' },
    { name: 'Purple', icon: icons.purple, type: 'purple' },
    { name: 'Pink', icon: icons.pink, type: 'pink' },
    { name: 'Bald', icon: icons.bald, type: 'bald' },
    { name: 'Multicolored', icon: icons.multicolored, type: 'multicolored' },
    { name: 'Other Color', icon: '', type: 'other' },
  ];
};

const ethnicities = [
  { name: 'White', icon: '', type: 'white' },
  { name: 'Black', icon: '', type: 'black' },
  { name: 'Hispanic/Latino', icon: '', type: 'hispanic_latino' },
  { name: 'East Asian', icon: '', type: 'east_asian' },
  { name: 'South Asian', icon: '', type: 'south_asian' },
  { name: 'Southeast Asian', icon: '', type: 'southeast_asian' },
  { name: 'Central Asian', icon: '', type: 'central_asian' },
  { name: 'Middle Eastern', icon: '', type: 'middle_eastern' },
  { name: 'Native American', icon: '', type: 'native_american' },
  { name: 'Pacific Islander', icon: '', type: 'pacific_islander' },
  { name: 'African', icon: '', type: 'african' },
  { name: 'Caribbean', icon: '', type: 'caribbean' },
  { name: 'Mediterranean', icon: '', type: 'mediterranean' },
  { name: 'Slavic', icon: '', type: 'slavic' },
  { name: 'Nordic', icon: '', type: 'nordic' },
  { name: 'Celtic', icon: '', type: 'celtic' },
  { name: 'Mixed', icon: '', type: 'mixed' },
  { name: 'Other', icon: '', type: 'other' },
];

const ageItems = [];
for (let age = 18; age <= 100; age++) {
  ageItems.push({
    value: age,
    label: age,
  });
}

const goodPhotos = [
  {
    src: '/images/good-ex.1.webp',
    gender: 'woman',
  },
  {
    src: '/images/good-ex.2.webp',
    gender: 'man',
  },
  {
    src: '/images/good-ex.3.webp',
    gender: 'woman',
  },
  {
    src: '/images/good-ex.6.webp',
    gender: 'man',
  },
  {
    src: '/images/good-ex.7.webp',
    gender: 'woman',
  },
  {
    src: '/images/good-ex.5.webp',
    gender: 'woman',
  },
  {
    src: '/images/good-ex.8.webp',
    gender: 'man',
  },
  {
    src: '/images/good-ex.9.webp',
    gender: 'man',
  },
  {
    src: '/images/good-ex.10.webp',
    gender: 'man',
  },
];

const badPhotos = [
  {
    src: '/images/bad-ex.5.webp',
    gender: 'woman',
  },
  {
    src: '/images/bad-ex.4.webp',
    gender: 'man',
  },
  {
    src: '/images/bad-ex.1.webp',
    gender: 'woman',
  },
  {
    src: '/images/bad-ex.2.webp',
    gender: 'man',
  },
  {
    src: '/images/bad-ex.3.webp',
    gender: 'woman',
  },
];

const MAX_IMAGES = 20;
const MIN_IMAGES = 10;
const TRAIN_MODEL_CREDITS = 200;
const maxSizeBasic = 10 * 1024 * 1024; // 10 MB
const maxSizePro = 15 * 1024 * 1024; // 10 MB

const CHECKOUT_40_PHOTOS_PACK = `${process.env.REACT_APP_PAYMENT_CHECKOUT_URL}/${process.env.REACT_APP_PAYMENT_40_PHOTOS_PACK}`;
const CHECKOUT_100_PHOTOS_PACK = `${process.env.REACT_APP_PAYMENT_CHECKOUT_URL}/${process.env.REACT_APP_PAYMENT_100_PHOTOS_PACK}`;
const CHECKOUT_200_PHOTOS_PACK = `${process.env.REACT_APP_PAYMENT_CHECKOUT_URL}/${process.env.REACT_APP_PAYMENT_200_PHOTOS_PACK}`;
const CHECKOUT_40_PHOTOS_PACK_ID = `${process.env.REACT_APP_CHECKOUT_40_PHOTOS_PACK_ID}`;
const CHECKOUT_100_PHOTOS_PACK_ID = `${process.env.REACT_APP_CHECKOUT_100_PHOTOS_PACK_ID}`;
const CHECKOUT_200_PHOTOS_PACK_ID = `${process.env.REACT_APP_CHECKOUT_200_PHOTOS_PACK_ID}`;

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};

const packagesPayments = [
  {
    id: CHECKOUT_40_PHOTOS_PACK_ID,
    name: 'Basic - 40 Headshots',
    price: '$19',
    quantity: 40,
    credits: '100 credits',
    time: '2 hours',
    feature1: 'Done in 2 hours',
    feature2: '4 Unique Styles',
    popular: false,
  },
  {
    id: CHECKOUT_100_PHOTOS_PACK_ID,
    name: 'Pro - 100 Headshots',
    price: '$29',
    quantity: 100,
    credits: '200 credits',
    time: '1 hour',
    feature1: 'Done in 1 hour',
    feature2: '10 Unique Styles',
    popular: true,
  },
  {
    id: CHECKOUT_200_PHOTOS_PACK_ID,
    name: 'Executive - 200 Headshots',
    price: '$49',
    quantity: 200,
    credits: '500 credits',
    time: '40 minutes',
    feature1: 'Done in 40 minutes',
    feature2: '20 Unique Styles',
    popular: false,
  },
];

const PhotoPipeLine = ({ userData, firebase, onClose, t, currentLanguage, photoPack, isMobile, affCode }) => {
  const dispatch = useDispatch();
  const {
    activeStep,
    stepData,
  } = useSelector((state) => state.photoStep);
  // const isLoading = useSelector((state) => state.photoStep.isLoading);
  // const error = useSelector((state) => state.photoStep.error);
  // dispatch(setActiveStep(0));

  // const [activeStep, setActiveStep] = useState(0);
  const [selectedEyes, setSelectedEyes] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedAge, setSelectedAge] = useState('');
  const [selectedHair, setSelectedHair] = useState(null);
  const [selectedEthnicity, setSelectedEthnicity] = useState(null);
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [mode, setMode] = useState(modes.pro);
  const [selectedImages, setSelectedImages] = useState([]);
  const [lastCheckout, setLastCheckout] = useState(false);
  const lastImageRef = useRef(null);
  const [hairColors, setHairColors] = useState([]);
  const [shouldScrollToLast, setShouldScrollToLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPackId, setLoadingPackId] = useState(null);
  const [isUploadingSelfies, setIsUploadingSelfies] = useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const [isShowPricing, setIsShowPricing] = useState(false);
  const [isLoadingExamples, setIsLoadingExamples] = useState(true);

  useEffect(() => {
    if (photoPack?.id) {
      dispatch(setCurrentPhotoPack(photoPack.id));
    }
  }, [photoPack, dispatch]);

  useEffect(() => {
    if (shouldScrollToLast && lastImageRef.current) {
      lastImageRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      setShouldScrollToLast(false);
    }
  }, [selectedImages, shouldScrollToLast]);

  useEffect(() => {
    if (stepData.step0.gender !== null) {
      setHairColors(getHairColors(stepData.step0.gender.type));
    } else {
      setHairColors([]);
    }
  }, [stepData.step0.gender]);

  useEffect(() => {
    setSelectedGender(stepData.step0.gender);
    setSelectedAge(stepData.step0.age);
    setSelectedEyes(stepData.step0.eyeColor);
    setSelectedHair(stepData.step0.hairColor);
    setSelectedEthnicity(stepData.step0.ethnicity);
    setSelectedImages(stepData.step1.selectedImages);
  }, [stepData]);

  const handleEyesSelect = subject => {
    setSelectedEyes(subject);
    dispatch(updateStepData({
      step: 0,
      data: { eyeColor: subject }
    }));
  };

  const handleChangeAge = event => {
    setSelectedAge(event.target.value);
    dispatch(updateStepData({
      step: 0,
      data: { age: event.target.value }
    }));
  };

  const handleHairSelect = subject => {
    setSelectedHair(subject);
    dispatch(updateStepData({
      step: 0,
      data: { hairColor: subject }
    }));
  };

  const handleEthnicitySelect = ethnicity => {
    setSelectedEthnicity(ethnicity);
    dispatch(updateStepData({
      step: 0,
      data: { ethnicity }
    }));
  };

  const handleSelectGender = (gender) => {
    setSelectedGender(gender);
    dispatch(updateStepData({
      step: 0,
      data: { gender }
    }));
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      const uploaderWrapper = document.querySelector('.uploader-wrapper');
      uploaderWrapper.style.border = '1px dashed #fc5185';
      uploaderWrapper.style.color = '#fc5185';
    } else if (e.type === 'dragleave') {
      const uploaderWrapper = document.querySelector('.uploader-wrapper');
      uploaderWrapper.style.border = !darkMode ? '1px dashed #373737' : '1px dashed #4a4a4a';
      uploaderWrapper.style.color = !darkMode ? '#373737' : '#8c8c8c';
    }
  };

  const calculateImageHash = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const buffer = e.target.result;
        let hash = 5381; // Better initial value than 0
        
        // Use a more robust hashing algorithm (djb2)
        for (let i = 0; i < buffer.byteLength; i++) {
          hash = ((hash << 5) + hash) + buffer[i];
          hash = hash >>> 0; // Convert to 32-bit unsigned
        }
        
        // Convert to hex string for more unique representation
        const hashHex = hash.toString(16).padStart(8, '0');
        
        // Include file size in hash for additional uniqueness
        const combinedHash = `${hashHex}-${file.size}`;
        
        resolve(combinedHash);
      };
      // Read more of the file for better uniqueness
      reader.readAsArrayBuffer(file.slice(0, 32768)); // Increased to 32KB
    });
  };

  const validatePhoto = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const issues = [];
        
        // Check dimensions
        if (img.width < 400 || img.height < 400) {
          issues.push('Resolution too low');
        }
        
        // Check aspect ratio
        const ratio = img.width / img.height;
        if (ratio < 0.5 || ratio > 2) {
          issues.push('Invalid aspect ratio');
        }
        
        // Check file size (max 10MB)
        if (file.size > 10 * 1024 * 1024) {
          issues.push('File too large');
        }
  
        resolve({
          isValid: issues.length === 0,
          issues: issues
        });
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const handleDrop = async event => {
    event.preventDefault();
    setIsUploading(true);
    setIsUploadingSelfies(true);
  
    try {
      let files;
      if (event.dataTransfer) {
        files = Array.from(event.dataTransfer.files);
      } else if (event.target.files) {
        files = Array.from(event.target.files);
      } else {
        setIsUploading(false);
        return;
      }
  
      const processedFiles = await Promise.all(files.map(processFile));
      const validProcessedFiles = processedFiles.filter(file => file !== null);
  
      if (validProcessedFiles.length === 0) {
        message.error('Image upload failed, only JPG/JPEG/PNG/WEBP/HEIC allowed');
        return;
      }
  
      // Get existing file hashes
      const existingHashes = selectedImages
        .filter(img => img.hash)
        .map(img => img.hash);
  
      // Process and categorize files
      const categorizedFiles = await Promise.all(
        validProcessedFiles.map(async (file) => {
          const hash = await calculateImageHash(file);
          const validation = await validatePhoto(file);
          const isDup = existingHashes.includes(hash);
  
          return {
            file,
            hash,
            validation,
            category: isDup ? 'duplicate' : 
                    (mode === modes.pro && file.size > maxSizePro) ? 'oversized' : 
                    'valid'
          };
        })
      );
  
      // Separate files by category
      const validFiles = categorizedFiles.filter(f => f.category === 'valid');
      const duplicateCount = categorizedFiles.filter(f => f.category === 'duplicate').length;
      const oversizedCount = categorizedFiles.filter(f => f.category === 'oversized').length;
  
      // Show notifications if needed
      if (duplicateCount > 0) {
        message.info(`Skipped ${duplicateCount} duplicate file(s)`);
      }
      if (oversizedCount > 0) {
        message.warning(t('message.error.fileOverPro', { count: oversizedCount }));
      }
  
      // Proceed only if there are valid files
      if (validFiles.length > 0) {
        // Create temporary previews for valid files
        const tempPreviews = validFiles.map(({ file, hash, validation }) => ({
          id: uuidv4(),
          file,
          url: URL.createObjectURL(file),
          isUploading: true,
          hash,
          validation // Include validation results in preview
        }));
  
        // Update UI with temporary previews
        setSelectedImages(prevImages => {
          const combinedImages = [...prevImages, ...tempPreviews].slice(0, MAX_IMAGES);
          return combinedImages;
        });
  
        // Upload valid files
        try {
          const uploadPromises = validFiles.map(async ({ file, hash, validation }) => {
            const originalName = file.name.replace(/\.[^/.]+$/, '');
            const fileName = `${userData.uid}/${originalName}.jpg`;
            const downloadURL = await firebase.uploadDataSetsFile(file, fileName);
            return { 
              url: downloadURL, 
              hash,
              file,
              validation
            };
          });
  
          const uploadedUrls = await Promise.all(uploadPromises);
  
          // Update final state with uploaded files
          setSelectedImages(prevImages => {
            const updatedImages = prevImages
              .filter(img => !img.isUploading)
              .concat(uploadedUrls)
              .slice(0, MAX_IMAGES);
  
            // Update Redux state
            dispatch(updateStepData({
              step: 1,
              data: { selectedImages: updatedImages }
            }));
  
            return updatedImages;
          });
  
          // Calculate valid photos count
          const validPhotoCount = uploadedUrls.filter(
            ({ validation }) => validation.isValid
          ).length;
  
          // Show success message with valid photo count
          message.success(`Successfully uploaded ${validPhotoCount} valid photo${validPhotoCount !== 1 ? 's' : ''}`);
          
        } catch (error) {
          console.error('Upload error:', error);
          // message.error('Failed to upload some images');
          setIsUploadingSelfies(false);
          
          // Remove failed uploads from preview
          setSelectedImages(prevImages => 
            prevImages.filter(img => !img.isUploading)
          );
        }
      } else {
        // message.warning('No valid files to upload');
        setIsUploadingSelfies(false);
      }
  
    } catch (error) {
      console.error('Error processing files:', error);
      message.error('An error occurred while processing the files');
      setIsUploadingSelfies(false);
    } finally {
      // Reset UI state
      const uploaderWrapper = document.querySelector('.uploader-wrapper');
      if (uploaderWrapper) {
        uploaderWrapper.style.border = !darkMode ? '1px dashed #373737' : '1px dashed #4a4a4a';
        uploaderWrapper.style.color = !darkMode ? '#373737' : '#8c8c8c';
      }
  
      setIsUploading(false);
      setShouldScrollToLast(true);
      setIsUploadingSelfies(false);
  
      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const renderImage = (image, index) => (
    <div
      ref={index === selectedImages.length - 1 ? lastImageRef : null}
      key={image.id || index}
      className="relative"
      style={{
        margin: '2px',
        border: darkMode ? '1px solid #373737' : '1px solid #4a4a4a',
        borderRadius: '10px',
      }}
    >
      <div className="relative w-24 h-24 flex-shrink-0">
        <div className="w-full h-full overflow-hidden rounded-lg mr-1">
          <img
            src={image.url}
            alt={`Selected ${index + 1}`}
            className={`w-full object-cover ${image.isUploading ? 'opacity-50' : ''}`}
            onLoad={e => {
              if (e.target.naturalHeight > e.target.naturalWidth) {
                e.target.classList.add('h-auto');
              } else {
                e.target.classList.add('h-full');
              }
            }}
          />
        </div>

        {image.isUploading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <CircularProgress size={24} sx={{ color: '#fff' }} />
          </div>
        )}

        <div className="w-full mt-1">
          <button
            disabled={isUploadingSelfies}
            className={`${darkMode ? 'text-white' : 'text-black'}`}
            style={{
              border: '1px solid #fc5185',
              background: 'none',
              cursor: 'pointer',
              borderRadius: '6px',
              padding: '2px 4px',
              width: '100%',
              fontSize: '12px',
            }}
            onClick={() => handleRemove(index)}
            aria-label="Remove image"
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );

  const renderExamplePhoto = (photo, index) => (
    <div
      key={index}
      className="relative"
      style={{ margin: '2px', border: darkMode ? '1px solid #373737' : '1px solid #4a4a4a', borderRadius: '10px' }}
    >
      <div className="relative w-24 h-24 flex-shrink-0">
        <div className="w-full h-full overflow-hidden rounded-lg mr-1">
          {isLoadingExamples[photo.src] !== false && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              animation="wave"
              sx={{
                bgcolor: darkMode ? 'grey.800' : 'grey.200',
                position: 'absolute',
                top: 0,
                left: 0
              }}
            />
          )}
          <img
            src={photo.src}
            alt={`Selected ${index + 1}`}
            className={`w-full object-cover ${isLoadingExamples[photo.src] === false ? 'opacity-100' : 'opacity-0'}`}
            onLoad={e => {
              if (e.target.naturalHeight > e.target.naturalWidth) {
                e.target.classList.add('h-auto');
              } else {
                e.target.classList.add('h-full');
              }
              setIsLoadingExamples(prev => ({
                ...prev,
                [photo.src]: false
              }));
            }}
          />
        </div>
        <div className="absolute t-0 inset-0" style={{ marginLeft: '2px' }}>
          {isLoadingExamples[photo.src] === false && (
            photo.isGood ? (
              <svg
                style={{ marginLeft: '-4px', marginTop: '-4px' }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#1bf259"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                  fill="#1bf259"
                />
              </svg>
            ) : (
              <span>❌</span>
            )
          )}
        </div>
      </div>
    </div>
  );

  const processFile = async file => {
    // Check file format
    const validFormats = [
      'image/webp',
      'image/jpeg',
      'image/jpg',
      'image/jpe',
      'image/jfif',
      'image/png'
    ];
    if (!validFormats.includes(file.type) && !file.name.toLowerCase().endsWith('.heic')) {
      return null;
    }

    let processedFile = file;
    if (!validFormats.includes(file.type)) {
      try {
        const pngBlob = await convertToPNG(file);
        processedFile = new File([pngBlob], file.name.replace(/\.[^/.]+$/, '.png'), { type: 'image/png' });
      } catch (error) {
        console.log('Error converting file:', error);
        return null;
      }
    }

    return processedFile;

    // // Check resolution
    // const dimensions = await getImageDimensions(processedFile);
    // const isLowResolution = dimensions.width < 1024 || dimensions.height < 1024;

    // return {
    //   file: processedFile,
    //   url: URL.createObjectURL(processedFile),
    //   isLowResolution: isLowResolution,
    //   dimensions: dimensions
    // };
  };

  const convertToPNG = file => {
    return new Promise((resolve, reject) => {
      if (file.type === 'image/heic' || file.name.toLowerCase().endsWith('.heic')) {
        heic2any({
          blob: file,
          toType: 'image/png',
        })
          .then(conversionResult => {
            resolve(conversionResult);
          })
          .catch(error => {
            console.error('Error converting HEIC to PNG:', error);
            reject(error);
          });
      } else {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(resolve, 'image/png');
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
      }
    });
  };

  const handleImageUpload = async (event) => {
    event.preventDefault();
    setIsUploading(true);
    setIsUploadingSelfies(true);
  
    try {
      let files;
      if (event.dataTransfer) {
        files = Array.from(event.dataTransfer.files);
      } else if (event.target.files) {
        files = Array.from(event.target.files);
      } else {
        setIsUploading(false);
        return;
      }
  
      const processedFiles = await Promise.all(files.map(processFile));
      const validProcessedFiles = processedFiles.filter(file => file !== null);
  
      if (validProcessedFiles.length === 0) {
        message.error('Image upload failed, only JPG/JPEG/PNG/WEBP/HEIC allowed');
        return;
      }
  
      // Get existing file hashes
      const existingHashes = selectedImages
        .filter(img => img.hash)
        .map(img => img.hash);
  
      // Process and categorize files
      const categorizedFiles = await Promise.all(
        validProcessedFiles.map(async (file) => {
          const hash = await calculateImageHash(file);
          const validation = await validatePhoto(file);
          const isDup = existingHashes.includes(hash);
  
          return {
            file,
            hash,
            validation,
            category: isDup ? 'duplicate' : 
                    (mode === modes.pro && file.size > maxSizePro) ? 'oversized' : 
                    'valid'
          };
        })
      );
  
      // Separate files by category
      const validFiles = categorizedFiles.filter(f => f.category === 'valid');
      const duplicateCount = categorizedFiles.filter(f => f.category === 'duplicate').length;
      const oversizedCount = categorizedFiles.filter(f => f.category === 'oversized').length;
  
      // Show notifications if needed
      if (duplicateCount > 0) {
        message.info(`Skipped ${duplicateCount} duplicate file(s)`);
      }
      if (oversizedCount > 0) {
        message.warning(t('message.error.fileOverPro', { count: oversizedCount }));
      }
  
      // Proceed only if there are valid files
      if (validFiles.length > 0) {
        // Create temporary previews for valid files
        const tempPreviews = validFiles.map(({ file, hash, validation }) => ({
          id: uuidv4(),
          file,
          url: URL.createObjectURL(file),
          isUploading: true,
          hash,
          validation // Include validation results in preview
        }));
  
        // Update UI with temporary previews
        setSelectedImages(prevImages => {
          const combinedImages = [...prevImages, ...tempPreviews].slice(0, MAX_IMAGES);
          return combinedImages;
        });
  
        // Upload valid files
        try {
          const uploadPromises = validFiles.map(async ({ file, hash, validation }) => {
            const originalName = file.name.replace(/\.[^/.]+$/, '');
            const fileName = `${userData.uid}/${originalName}.jpg`;
            const downloadURL = await firebase.uploadDataSetsFile(file, fileName);
            return { 
              url: downloadURL, 
              hash,
              file,
              validation
            };
          });
  
          const uploadedUrls = await Promise.all(uploadPromises);
  
          // Update final state with uploaded files
          setSelectedImages(prevImages => {
            const updatedImages = prevImages
              .filter(img => !img.isUploading)
              .concat(uploadedUrls)
              .slice(0, MAX_IMAGES);
  
            // Update Redux state
            dispatch(updateStepData({
              step: 1,
              data: { selectedImages: updatedImages }
            }));
  
            return updatedImages;
          });
  
          // Calculate valid photos count
          const validPhotoCount = uploadedUrls.filter(
            ({ validation }) => validation.isValid
          ).length;
  
          // Show success message with valid photo count
          message.success(`Successfully uploaded ${validPhotoCount} valid photo${validPhotoCount !== 1 ? 's' : ''}`);
          
        } catch (error) {
          console.error('Upload error:', error);
          message.error('Failed to upload some images');
          setIsUploadingSelfies(false);
          
          // Remove failed uploads from preview
          setSelectedImages(prevImages => 
            prevImages.filter(img => !img.isUploading)
          );
        }
      } else {
        message.warning('No valid files to upload');
        setIsUploadingSelfies(false);
      }
  
    } catch (error) {
      console.error('Error processing files:', error);
      message.error('An error occurred while processing the files');
      setIsUploadingSelfies(false);
    } finally {
      // Reset UI state
      const uploaderWrapper = document.querySelector('.uploader-wrapper');
      if (uploaderWrapper) {
        uploaderWrapper.style.border = !darkMode ? '1px dashed #373737' : '1px dashed #4a4a4a';
        uploaderWrapper.style.color = !darkMode ? '#373737' : '#8c8c8c';
      }
  
      setIsUploading(false);
      setShouldScrollToLast(true);
      setIsUploadingSelfies(false);
  
      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleUploadClick = () => {
    const uploaderWrapper = document.querySelector('.uploader-wrapper');
    uploaderWrapper.style.border = '1px dashed #fc5185';
    uploaderWrapper.style.color = '#fc5185';
  };

  // const handleRemove = index => {
  //   setSelectedImages(prevImages => prevImages.filter((_, i) => i !== index));
  //   setShouldScrollToLast(false);
  // };
  const handleRemove = index => {
    setSelectedImages(prevImages => {
      const updatedImages = prevImages.filter((_, i) => i !== index);

      // Update Redux state
      dispatch(updateStepData({
        step: 1,
        data: { selectedImages: updatedImages }
      }));

      return updatedImages;
    });
    setShouldScrollToLast(false);
  };

  const handleNext = async () => {
    if (activeStep === -1) {
      if (userData === null || userData === undefined) {
        alert('Login to start with REOK!');
        onClose();
        return;
      }
    }

    // if (activeStep === 1) {
    //   //Uploading images
    //   setIsUploadingSelfies(true);

    //   if (selectedImages.length < MIN_IMAGES) {
    //     message.error('Please upload at least 10 photos');
    //     setIsUploadingSelfies(false);
    //     return;
    //   }

    //   const newFileName = `${uuidv4()}`;

    //   const uploadedUrls = await Promise.all(
    //     selectedImages.map(async (image, index) => {
    //       // Check if the image has already been uploaded
    //       if (image.uploadedUrl) {
    //         return image.uploadedUrl;
    //       }

    //       // If not uploaded, proceed with upload
    //       const fileName = `${userData.uid}/${newFileName}_${index}.jpg`;
    //       const downloadURL = await firebase.uploadDataSetsFile(image.file, fileName);

    //       // Update the image object with the uploaded URL
    //       image.uploadedUrl = downloadURL;

    //       return downloadURL;
    //     })
    //   );

    //   setUploadedSelfies(uploadedUrls);
    //   setIsUploadingSelfies(false);
    // }

    // setActiveStep(prevActiveStep => Math.min(prevActiveStep + 1, steps.length - 1));
    dispatch(setActiveStep(activeStep + 1));
  };

  const handleBack = () => {
    if (activeStep === 0) {
      onClose();
    } else {
      dispatch(setActiveStep(activeStep - 1));
      // dispatch(setActiveStep(prevActiveStep => Math.max(prevActiveStep - 1, -1)));
    }
  };

  const isNextDisabled = () => {
    if (activeStep === 0) {
      return !selectedGender || !selectedAge || !selectedEyes || !selectedHair || !selectedEthnicity;
    }
    if (activeStep === 1) {
      return selectedImages.length < MIN_IMAGES;
    }
    return false;
  };

  const onBuyPack = packPayment => {
    const paymentId = packPayment.id;
    setLoadingPackId(paymentId);
    setIsLoading(true);

    if (!selectedGender || !selectedAge || !selectedEyes || !selectedHair || !selectedEthnicity) {
      message.error('Please select all fields');
      setIsLoading(false);
      setLoadingPackId(null);
      return;
    }

    const uploadedSelfies = selectedImages.map(image => image.url);
    if (uploadedSelfies.length < MIN_IMAGES) {
      message.error('Please upload at least 10 photos');
      setIsLoading(false);
      setLoadingPackId(null);
      return;
    }

    if (!photoPack) {
      message.error('Photo pack is not found');
      setIsLoading(false);
      setLoadingPackId(null);
      return;
    }

    const cartData = {
      uid: userData.uid,
      paymentId: paymentId,
      photoPackIds: [photoPack.id],
      price: packPayment.price,
      quantity: packPayment.quantity,
      gender: selectedGender.type,
      age: selectedAge,
      eyesColor: selectedEyes.type,
      hairColor: selectedHair.type,
      ethnicity: selectedEthnicity.type,
      images: uploadedSelfies,
      status: 'pending',
      createdAt: new Date().toISOString(),
    };

    firebase
      .addPhotoCart(cartData)
      .then(result => {
        const cartId = result.id;
        if (!cartId) {
          console.error('Error when buying pack, please try again');
          setIsLoading(false);
          setLoadingPackId(null);
          return;
        }

        let checkoutUrl;
        if (paymentId === CHECKOUT_40_PHOTOS_PACK_ID) {
          checkoutUrl = `${CHECKOUT_40_PHOTOS_PACK}?checkout[email]=${userData.email}&aff=${affCode}&checkout[custom][email]=${userData.email}&checkout[custom][cart_id]=${cartId}`;
        }
        if (paymentId === CHECKOUT_100_PHOTOS_PACK_ID) {
          checkoutUrl = `${CHECKOUT_100_PHOTOS_PACK}?checkout[email]=${userData.email}&aff=${affCode}&checkout[custom][email]=${userData.email}&checkout[custom][cart_id]=${cartId}`;
        }
        if (paymentId === CHECKOUT_200_PHOTOS_PACK_ID) {
          checkoutUrl = `${CHECKOUT_200_PHOTOS_PACK}?checkout[email]=${userData.email}&aff=${affCode}&checkout[custom][email]=${userData.email}&checkout[custom][cart_id]=${cartId}`;
        }

        window.open(checkoutUrl, '_self');

        setTimeout(() => {
          setIsLoading(false);
          setLoadingPackId(null);
        }, 3000);
      })
      .catch(error => {
        console.error('Error when buying pack, please try again:', error);
      });
  };

  const onShowPricing = () => {
    setIsShowPricing(false);
  };

  const startHeadshots = () => {
    setIsShowPricing(false);
    dispatch(setActiveStep(0));
  };

  return (
    <>
      <Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={true} onClose={() => onClose()}>
        <ModalDialog
          sx={{
            width: {
              xs: '100%',
              md: '60%',
            },
            height: '100%',
            background: '#09090b',
            border: '1px solid #373737',
            // overflow: 'auto'
          }}
        >
          <ModalClose
            onClick={() => onClose()}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              color: 'white',
              zIndex: 1000,
            }}
          />
          <div className="photo-pipeline-showbox">
            <div className="text-white">
              <div className="mb-4">
                <h1 className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 text-white text-lg bold">{photoPack.title} Headshots</h1>
              </div>
              <Stepper className="p-3" sx={{ width: '100%' }}>
                {steps.map((step, index) => (
                  <Step
                    key={step}
                    indicator={
                      <StepIndicator
                        variant={activeStep === index ? 'solid' : 'soft'}
                        color={activeStep === index ? 'primary' : 'neutral'}
                        sx={{ background: activeStep === index ? '#fc5185' : '' }}
                      >
                        {activeStep > index ? (
                          <Check
                            sx={{
                              // background: '#fc5185',
                              background: '#00a730',
                              color: '#fff',
                              borderRadius: '50%',
                            }}
                          />
                        ) : (
                          index + 1
                        )}
                      </StepIndicator>
                    }
                    sx={{
                      ...(activeStep > index &&
                        index !== steps.length - 1 && {
                        '&::after': {
                          height: '2px',
                          borderRadius: '24px',
                          background: 'linear-gradient(115deg, #4fcf70, #fad648, #a767e5, #12bcfe, #44ce7b)',
                        },
                      }),
                      opacity: activeStep >= index ? 1 : 0.5,
                    }}
                  >
                    <StepButton onClick={() => dispatch(setActiveStep(index))} disabled={activeStep < index}>
                      <span className={`text-white label-details ${activeStep === index ? 'coupon-card' : ''}`}>{step}</span>
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div className="flex justify-between mt-2 label-details">
                {activeStep !== -1 && (
                  <button
                    // style={{ opacity: activeStep === -1 ? 0 : 1 }}
                    onClick={handleBack}
                    // disabled={activeStep === -1}
                    className={`px-3 py-2 rounded flex items-center ${activeStep === -1 ? 'bg-gray-300 text-gray-500' : 'bg-blue-500 text-white hover:bg-blue-600'
                      }`}
                  >
                    <ArrowLeft size={isMobile ? 14 : 24} className="mr-1" />
                    <span style={{ marginLeft: '4px' }}>Back</span>
                  </button>
                )}
                {activeStep === -1 && (
                  <div className="flex items-center justify-between w-full">
                    {/* <button
                    className={`px-4 py-2 rounded-full font-semibold bg-pink-500 text-white hover:bg-pink-600`}
                    style={{ marginLeft: '1rem', display: 'none' }}
                    onClick={() => setIsShowPricing(true)}
                  >
                    Pricing
                  </button> */}
                    <button
                      onClick={handleBack}
                      className={`px-3 py-2 rounded flex items-center ${activeStep === -1 ? 'bg-gray-300 text-gray-500' : 'bg-blue-500 text-white hover:bg-blue-600'
                        }`}
                    >
                      <ArrowLeft size={isMobile ? 14 : 20} />
                      <span style={{ marginLeft: '4px' }}>Back</span>
                    </button>
                    <button onClick={() => setIsShowPricing(true)}>
                      <span className="text-center text-white coupon-card">📸 How it works</span>
                    </button>
                    {/* <button onClick={handleNext} className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600">
                    Start
                    <ArrowRight size={isMobile ? 14 : 24} />
                  </button> */}
                    <button
                      className={` px-3 py-1 rounded-full bg-pink-500 text-white hover:bg-pink-600 flex items-center`}
                      onClick={handleNext}
                    >
                      <span style={{ marginRight: '2px' }}>Start</span>
                      <ArrowRight size={isMobile ? 14 : 20} />
                    </button>
                  </div>
                )}
                {activeStep === 0 && <span className="text-center px-3 py-2 text-pink-600 bold">Set your appearance</span>}
                {activeStep === 1 && <span className="text-center px-3 py-2 text-pink-600 bold">Select 10+ photos</span>}
                {activeStep === 2 && (
                  <span className="text-center text-white px-3 py-2">You pay once, no subscriptions or hidden fees 🤘</span>
                )}
                {activeStep !== -1 && (
                  <button
                    onClick={handleNext}
                    disabled={isUploadingSelfies || isNextDisabled() || activeStep === steps.length - 1}
                    className={`px-4 py-2 rounded flex items-center ${isNextDisabled() || activeStep === steps.length - 1
                      ? 'bg-gray-300 text-gray-500'
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                      }`}
                  >
                    {activeStep === steps.length - 1 ? (
                      ''
                    ) : (
                      <>
                        {isUploadingSelfies ? (
                          <span>
                            <CircularProgress size={12} color="inherit" style={{ marginRight: '6px' }} />
                            Uploading...
                          </span>
                        ) : (
                          <>
                            <span style={{ marginRight: '4px' }}>Next</span>
                            <ArrowRight size={isMobile ? 14 : 24} />
                          </>
                        )}
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
            <div className="label-details" style={{ flex: 1, overflowY: 'auto' }}>
              {activeStep === -1 && (
                // <div className="flex items-center justify-center px-4 py-2 mt-4">
                //   <div className="pipeline-wrapper w-full max-w-4xl">
                //     <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                //       {[
                //         { title: '1. Select your appearance', image: 'images/guide-step-1.webp' },
                //         { title: '2. Upload 10+ photos of yourself', image: 'images/guide-step-2.webp' },
                //         { title: '3. Pay for your package', image: 'images/guide-step-3.webp' },
                //         { title: `Finally, receive your ${photoPack.title}`, image: photoPack.image },
                //       ].map((step, index) => (
                //         <div key={index} className={`bg-opacity-10 rounded-lg p-6 ${darkMode ? 'dark-bg dark-border' : 'bg-gray-100'}`}>
                //           <div className="flex items-center mb-4">
                //             <h3 className={`ml-4 text-sm font-semibold ${darkMode ? 'text-white' : 'text-gray-800'}`}>{step.title}</h3>
                //           </div>
                //           <div className="relative aspect-video rounded-lg overflow-hidden shadow-lg">
                //             <img src={step.image} alt={step.title} className="w-full h-full object-cover" />
                //           </div>
                //         </div>
                //       ))}
                //     </div>
                //   </div>
                // </div>
                <div className="flex items-center justify-center px-2 md:px-6 py-6">
                  <div className="pipeline-wrapper w-full max-w-4xl">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 relative">
                      {/* Connecting lines for desktop */}
                      <div className="hidden md:block absolute top-1/2 left-0 bg-gradient-to-r from-pink-500 to-purple-500 -translate-y-1/2 z-0" />
                      {[
                        { title: 'Select your appearance', image: 'images/guide-step-1.webp' },
                        { title: 'Upload 10+ photos of yourself', image: 'images/guide-step-2.webp' },
                        { title: 'Pay for your headshots package', image: 'images/guide-step-3.webp' },
                        { title: `Finally, receive your ${photoPack.title} headshots`, image: photoPack.image },].map((step, index) => (
                          <Box
                            key={index}
                            sx={{
                              position: 'relative',
                              // zIndex: 10,
                              transform: 'translateZ(0)', // Performance optimization
                            }}
                          >
                            {index + 1 !== 4 && (
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: -20,
                                  left: '50%',
                                  transform: 'translateX(-50%)',
                                  zIndex: 999,

                                  // Size & Shape
                                  width: 40,
                                  height: 40,
                                  borderRadius: '50%',

                                  // Visual Style
                                  background: `linear-gradient(135deg, #fc5185 0%, #f78ca0 100%)`,
                                  boxShadow: '0 4px 20px rgba(252, 81, 133, 0.3)',

                                  // Content Alignment
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',

                                  // Text Style
                                  color: 'white',
                                  fontWeight: 700,
                                  fontSize: '1.125rem',

                                  // Animation
                                  animation: 'pulse 2s infinite',
                                  '@keyframes pulse': {
                                    '0%': {
                                      boxShadow: '0 0 0 0 rgba(252, 81, 133, 0.4)',
                                    },
                                    '70%': {
                                      boxShadow: '0 0 0 10px rgba(252, 81, 133, 0)',
                                    },
                                    '100%': {
                                      boxShadow: '0 0 0 0 rgba(252, 81, 133, 0)',
                                    },
                                  },
                                }}
                              >
                                {index + 1}
                              </Box>
                            )}

                            {/* Step Content Container */}
                            <Box
                              sx={{
                                // Base Style
                                height: '100%',
                                backgroundColor: darkMode ? '#000' : '#FFFFFF',
                                backdropFilter: 'blur(10px)',
                                p: 3.5,
                                borderRadius: 3,

                                // Border & Shadow
                                border: `1px solid ${darkMode ? '#2A2A2A' : '#E0E0E0'}`,
                                boxShadow: darkMode ? '0 4px 30px rgba(0, 0, 0, 0.3)' : '0 4px 30px rgba(0, 0, 0, 0.1)',

                                // Hover Effects
                                transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                                '&:hover': {
                                  transform: 'translateY(-8px)',
                                  boxShadow: darkMode ? '0 8px 40px rgba(236, 72, 153, 0.2)' : '0 8px 40px rgba(236, 72, 153, 0.15)',
                                  borderColor: '#fc5185',
                                  '& img': {
                                    transform: 'scale(1.05)',
                                  },
                                },
                              }}
                            >
                              <div key={index}>
                                <div className="flex items-center mb-4">
                                  <h3 className={`ml-4 text-sm font-semibold ${darkMode ? 'text-white' : 'text-gray-800'}`}>{step.title}</h3>
                                </div>
                                <div className="relative aspect-video rounded-lg overflow-hidden shadow-lg"
                                  style={{ height: step.image ? '100%' : '100%' }}> {/* Conditional height */}
                                  {step.image && (
                                    <img
                                      src={step.image}
                                      alt={step.title}
                                      className="w-full h-full object-cover"
                                    />
                                  )}
                                </div>
                              </div>
                            </Box>
                          </Box>
                        ))}
                    </div>
                  </div>
                </div>
              )}
              {activeStep === 0 && (
                <div className="flex items-center justify-center px-4 py-2">
                  <div className="pipeline-wrapper">
                    {/* <p className="text-center mb-6 sm:mb-8 package-details text-white">
                    Step 1. Tell us about your appearance for the Best results ✨
                  </p> */}
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <div className="page-header mb-0 page-header-reset mb-4" />
                        <label className={`${darkMode ? 'text-white' : 'text-black'}`}>Your gender</label>
                        <div className="page-header mb-0 page-header-reset mb-2" />
                        <FormControl
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: '#fc5185',
                              },
                            },
                          }}
                        >
                          <Select
                            sx={{
                              border: darkMode ? '1px solid #373737' : '',
                              borderRadius: '8px',
                              background: darkMode ? '#323232' : '',
                              color: darkMode ? 'white' : 'black',
                              '& .MuiSvgIcon-root': {
                                color: darkMode ? 'white' : 'black',
                              },
                              fontSize: '14px',
                              zIndex: 9999,
                            }}
                            value={selectedGender ? selectedGender.type : ''}
                            onChange={event => handleSelectGender(genders.find(gender => gender.type === event.target.value))}
                            displayEmpty
                            renderValue={selected => {
                              if (!selected) {
                                return <em style={{ color: '#888' }}>Select gender</em>;
                              }
                              const selectedGender = genders.find(gender => gender.type === selected);
                              return (
                                <div className="flex items-center">
                                  <span className="mr-3">{selectedGender.icon}</span>
                                  <span>{selectedGender.name}</span>
                                </div>
                              );
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  border: darkMode ? '1px solid #373737' : '',
                                  borderRadius: '8px',
                                  background: darkMode ? '#323232' : '',
                                  color: 'white',
                                },
                              },
                            }}
                          >
                            {genders.map(gender => (
                              <MenuItem
                                key={gender.type}
                                value={gender.type}
                                sx={{
                                  '&:hover': {
                                    backgroundColor: '#3a3a3a',
                                  },
                                  '&.Mui-selected': {
                                    backgroundColor: '#4a4a4a',
                                  },
                                }}
                              >
                                <div className="flex items-center">
                                  <span className="mr-3">{gender.icon}</span>
                                  <span>{gender.name}</span>
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div>
                        <div className="page-header mb-0 page-header-reset mb-4" />
                        <label className={`${darkMode ? 'text-white' : 'text-black'}`}>Your age</label>
                        <div className="page-header mb-0 page-header-reset mb-2" />
                        <FormControl
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: '#fc5185',
                              },
                            },
                          }}
                        >
                          {' '}
                          <Select
                            sx={{
                              border: darkMode ? '1px solid #373737' : '',
                              borderRadius: '8px',
                              background: darkMode ? '#323232' : '',
                              color: darkMode ? 'white' : 'black',
                              '& .MuiSvgIcon-root': {
                                color: darkMode ? 'white' : 'black',
                              },
                              fontSize: '14px',
                              zIndex: 9999,
                            }}
                            value={selectedAge}
                            onChange={handleChangeAge}
                            displayEmpty
                            renderValue={selected => {
                              if (!selected) {
                                return <em style={{ color: '#888' }}>Select age</em>;
                              }
                              return `${selected}`;
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  border: darkMode ? '1px solid #373737' : '',
                                  borderRadius: '8px',
                                  background: darkMode ? '#323232' : '',
                                  color: 'white',
                                },
                              },
                            }}
                          >
                            {ageItems.map(item => (
                              <MenuItem
                                key={item.value}
                                value={item.value}
                                sx={{
                                  '&:hover': {
                                    backgroundColor: '#3a3a3a',
                                  },
                                  '&.Mui-selected': {
                                    backgroundColor: '#4a4a4a',
                                  },
                                }}
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div>
                        <div className="page-header mb-0 page-header-reset mb-2" />
                        <label className={`${darkMode ? 'text-white' : 'text-black'}`}>Your eyes color</label>
                        <div className="page-header mb-0 page-header-reset mb-2" />
                        <FormControl
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: '#fc5185',
                              },
                            },
                          }}
                        >
                          {' '}
                          <Select
                            sx={{
                              border: darkMode ? '1px solid #373737' : '',
                              borderRadius: '8px',
                              background: darkMode ? '#323232' : '',
                              color: darkMode ? 'white' : 'black',
                              '& .MuiSvgIcon-root': {
                                color: darkMode ? 'white' : 'black',
                              },
                              fontSize: '14px',
                              zIndex: 9999,
                            }}
                            value={selectedEyes ? selectedEyes.type : ''}
                            onChange={event => handleEyesSelect(eyeColors.find(color => color.type === event.target.value))}
                            displayEmpty
                            renderValue={selected => {
                              if (!selected) {
                                return <em style={{ color: '#888' }}>Select eye color</em>;
                              }
                              const selectedColor = eyeColors.find(color => color.type === selected);
                              return (
                                <div className="flex items-center">
                                  <span className="mr-3">{selectedColor.icon}</span>
                                  <span>{selectedColor.name}</span>
                                </div>
                              );
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  border: darkMode ? '1px solid #373737' : '',
                                  borderRadius: '8px',
                                  background: darkMode ? '#323232' : '',
                                  color: 'white',
                                },
                              },
                            }}
                          >
                            {eyeColors.map(subject => (
                              <MenuItem key={subject.type} value={subject.type}>
                                <div className="flex items-center">
                                  <span className="mr-3">{subject.icon}</span>
                                  <span>{subject.name}</span>
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div>
                        <div className="page-header mb-0 page-header-reset mb-2" />
                        <label className={`${darkMode ? 'text-white' : 'text-black'}`}>Your hair color</label>
                        <div className="page-header mb-0 page-header-reset mb-2" />
                        <FormControl
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: '#fc5185',
                              },
                            },
                          }}
                        >
                          {' '}
                          <Select
                            sx={{
                              border: darkMode ? '1px solid #373737' : '',
                              borderRadius: '8px',
                              background: darkMode ? '#323232' : '',
                              color: darkMode ? 'white' : 'black',
                              '& .MuiSvgIcon-root': {
                                color: darkMode ? 'white' : 'black',
                              },
                              fontSize: '14px',
                              zIndex: 9999,
                            }}
                            value={selectedHair ? selectedHair.type : ''}
                            onChange={event => handleHairSelect(hairColors.find(color => color.type === event.target.value))}
                            displayEmpty
                            renderValue={selected => {
                              if (!selected) {
                                return (
                                  <em style={{ color: '#888' }}>{hairColors.length < 1 ? 'Select gender first!' : 'Select hair color'}</em>
                                );
                              }
                              const selectedColor = hairColors.find(color => color.type === selected);
                              return (
                                <div className="flex items-center">
                                  <span className="mr-3">{selectedColor.icon}</span>
                                  <span>{selectedColor.name}</span>
                                </div>
                              );
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  border: darkMode ? '1px solid #373737' : '',
                                  borderRadius: '8px',
                                  background: darkMode ? '#323232' : '',
                                  color: 'white',
                                },
                              },
                            }}
                          >
                            {hairColors.map(subject => (
                              <MenuItem key={subject.type} value={subject.type}>
                                <div className="flex items-center">
                                  <span className="mr-3">{subject.icon}</span>
                                  <span>{subject.name}</span>
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="page-header mb-0 page-header-reset mb-5" />
                    <label className={`${darkMode ? 'text-white' : 'text-black'}`}>Your ethnicity</label>
                    <div className="page-header mb-0 page-header-reset mb-2" />
                    <FormControl
                      sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#fc5185',
                          },
                        },
                      }}
                    >
                      {' '}
                      <Select
                        sx={{
                          border: darkMode ? '1px solid #373737' : '',
                          borderRadius: '8px',
                          background: darkMode ? '#323232' : '',
                          color: darkMode ? 'white' : 'black',
                          '& .MuiSvgIcon-root': {
                            color: darkMode ? 'white' : 'black',
                          },
                          fontSize: '14px',
                          zIndex: 9999,
                        }}
                        value={selectedEthnicity ? selectedEthnicity.type : ''}
                        onChange={event => handleEthnicitySelect(ethnicities.find(ethnicity => ethnicity.type === event.target.value))}
                        displayEmpty
                        renderValue={selected => {
                          if (!selected) {
                            return <em style={{ color: '#888' }}>Select ethnicity</em>;
                          }
                          const selectedEthnicity = ethnicities.find(ethnicity => ethnicity.type === selected);
                          return (
                            <div className="flex items-center">
                              {selectedEthnicity.icon && <span className="mr-3">{selectedEthnicity.icon}</span>}
                              <span>{selectedEthnicity.name}</span>
                            </div>
                          );
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              border: darkMode ? '1px solid #373737' : '',
                              borderRadius: '8px',
                              background: darkMode ? '#323232' : '',
                              color: 'white',
                            },
                          },
                        }}
                      >
                        {ethnicities.map(ethnicity => (
                          <MenuItem
                            key={ethnicity.type}
                            value={ethnicity.type}
                            sx={{
                              '&:hover': {
                                backgroundColor: '#3a3a3a',
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#4a4a4a',
                              },
                            }}
                          >
                            <div className="flex items-center">
                              <span className="mr-3">{ethnicity.icon}</span>
                              <span>{ethnicity.name}</span>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
              {activeStep === 1 && (
                <div className="flex items-center justify-center px-4 py-2">
                  <div className="pipeline-wrapper w-full">
                    <div className="page-header mb-0 page-header-reset mb-2" />
                    <div
                      className={`${darkMode ? 'text-light-gray' : 'text-black'} photo-alert mt-1 grid grid-cols-1 md:grid-cols-2 md:gap-10`}
                    >
                      <div className="mb-4 md:mb-0">
                        <div className={`mb-1 flex items-center`} style={{ color: '#3eff76' }}>
                          <span>Photo requirements</span>
                        </div>
                        <p className="mb-4">
                          Recent photos of you, mix of close-ups and full body, different places, poses, outfits, and expressions.
                        </p>
                        <div className="pipeline-example-tab-content">
                        <div className="flex space-x-4">
                          {goodPhotos.map((photo, index) => renderExamplePhoto({...photo, isGood: true}, index))}
                        </div>
                        </div>
                      </div>

                      <div>
                        <p className={`mb-1`} style={{ color: '#ff6565' }}>
                          Photo restrictions
                        </p>
                        <p className="mb-4">Group shots, filtered photos, too close or hidden faces, old pictures, or images with animal.</p>
                        <div className="pipeline-example-tab-content">
                        <div className="flex space-x-4">
                          {badPhotos.map((photo, index) => renderExamplePhoto({...photo, isGood: false}, index))}
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 photo-alert">
                      <div className={`mb-4 ${darkMode ? 'text-white' : 'text-black'}`}>
                        <p>Select 10-20 different photos (more is better)</p>
                      </div>
                      <div>
                        <div
                          className={`uploader-wrapper ${darkMode ? 'dark-border dark-bg-input' : 'light-border'}`}
                          onClick={() => fileInputRef.current.click()}
                          onDragOver={handleDrag}
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDrop={handleDrop}
                        >
                          <div className="uploader-title">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="icon"
                              role="presentation"
                            >
                              <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
                              <polyline points="17 8 12 3 7 8" />
                              <line x1="12" y1="3" x2="12" y2="15" />
                            </svg>
                            <span style={{ marginLeft: '4px' }}>{isUploadingSelfies ? 'Uploading...' : t('home.uploadPhotosHolder')}</span>
                          </div>
                          <input
                            disabled={isUploadingSelfies}
                            type="file"
                            ref={fileInputRef}
                            className="hidden"
                            multiple
                            accept="image/*"
                            onChange={handleImageUpload}
                            onClick={handleUploadClick}
                          />
                        </div>
                        {selectedImages.length > 0 && (
                          <div className="overflow-x-auto mt-4 mb-4">
                            <div className="flex space-x-4 pb-6">
                              {selectedImages.map((image, index) => renderImage(image, index))}
                            </div>
                          </div>
                        )}
                        {selectedImages.length > 0 && (
                          <p className={selectedImages.length < MIN_IMAGES ? 'text-red' : 'text-green-success'}>
                            {selectedImages.length} image{selectedImages.length !== 1 ? 's' : ''} selected
                            {selectedImages.length < MIN_IMAGES ? ' (minimum 10 required)' : ' (ready to checkout)'}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeStep === 2 && (
                <div className="flex items-center justify-center px-4 py-2 mt-4">
                  <div className="pipeline-wrapper w-full">
                    <div className="max-w-3xl mx-auto md:px-10">
                      {!lastCheckout ? (
                        <>
                          {/* <p className="text-center mb-6 sm:mb-8 package-details text-white">
                            You pay once, no subscriptions or hidden fees 🤘
                          </p> */}
                          <div class="package-list">
                            {packagesPayments.map(pkg => (
                              <div key={pkg.id} class={`package-card ${pkg.popular ? 'package-card-popular' : ''}`}>
                                <div class="package-info">
                                  <h2 class="package-price">
                                    {pkg.price} <span class="package-name">/ {pkg.name}</span>
                                  </h2>
                                  <p class="package-details">
                                    {/* <span>Done in {pkg.time}</span> */}
                                    <div className='mb-1'>{pkg.feature1}</div>
                                    <div className='mb-1'>{pkg.feature2}</div>
                                    <span className="coupon-card" style={{ marginLeft: '-5px' }}>
                                      Bonus
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="#ffc337">
                                        <path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z"></path>
                                      </svg>
                                      {pkg.credits}
                                    </span>{' '}
                                    to use all AI tools
                                  </p>
                                </div>
                                <button
                                  className={`px-6 py-2 rounded-full font-semibold ${pkg.popular ? 'bg-pink-500 text-white' : 'bg-white text-indigo-900 border border-indigo-900'
                                    }`}
                                  onClick={() => onBuyPack(pkg)}
                                  disabled={isLoading}
                                >
                                  {isLoading && loadingPackId === pkg.id ? (
                                    <span>
                                      <CircularProgress size={12} color="inherit" style={{ marginRight: '6px' }} />
                                      Buying...
                                    </span>
                                  ) : (
                                    'Buy →'
                                  )}
                                </button>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center justify-center">
              {activeStep !== -1 && activeStep !== 2 && (
                <button
                  className={`mt-4 px-6 py-3 rounded-full font-semibold ${isNextDisabled() || activeStep === steps.length - 1
                    ? 'bg-pink-800 text-gray-500 cursor-not-allowed'
                    : 'bg-pink-500 text-white hover:bg-pink-600'
                    }`}
                  onClick={handleNext}
                  disabled={isNextDisabled() || activeStep === steps.length - 1 || isUploadingSelfies}
                >
                  {isUploadingSelfies && activeStep === 1 ? (
                    <span>
                      <CircularProgress size={12} color="inherit" style={{ marginRight: '6px' }} />
                      Uploading...
                    </span>
                  ) : (
                    'Next →'
                  )}
                </button>
              )}
              {activeStep === -1 && (
                <button
                  className={`mt-4 mb-2 px-6 py-3 rounded-full font-semibold ${activeStep === steps.length - 1
                    ? 'bg-pink-800 text-gray-500 cursor-not-allowed'
                    : 'bg-pink-500 text-white hover:bg-pink-600'
                    }`}
                  onClick={handleNext}
                >
                  Start →
                </button>
              )}
            </div>
            <div className="page-header mb-0 page-header-reset mb-2" />
          </div>
        </ModalDialog>
      </Modal>
      {isShowPricing &&
        <NewPricing tab={0} userData={userData} firebase={firebase} onShowPricing={onShowPricing} t={t} currentLanguage={currentLanguage} startHeadshots={startHeadshots} />
      }
    </>
  );
};

export default PhotoPipeLine;
