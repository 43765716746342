import React, { useEffect, useState } from 'react'
import { Avatar as MuiAvatar } from '@mui/material'

export const Avatar = ({ size = 50, url = false, initials = '', color = '#1d94ae' }) => {
  const [validUrl, setValidUrl] = useState(false)

  useEffect(() => {
    if (url) {
      fetch(url, { method: 'HEAD' })
        .then(res => {
          if (res.ok) {
            setValidUrl(true)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [url])

  return (
    <MuiAvatar
      sx={{
        width: size,
        height: size,
        bgcolor: color,
        fontSize: size / 3,
        fontWeight: 'bold',
        fontFamily: "'Campton-Medium', Verdana, sans-serif"
      }}
      src={validUrl ? url : undefined}
    >
      {!validUrl && initials}
    </MuiAvatar>
  )
}