import React, { useEffect, useState, memo } from 'react';
import LoaderLineMask from '../LoaderLineMask';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import NewSkeleton from '../NewSkeleton';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import WarningIcon from '@mui/icons-material/Warning';
import { message } from '../Message';

const StyledTabs = styled(({ darkMode, ...props }) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))(({ darkMode }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    width: '100%',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '80%',
    // backgroundColor: "#fb5183",
    backgroundImage: 'linear-gradient(115deg, #4fcf70, #fad648, #a767e5, #12bcfe, #44ce7b)',
  },
}));

const StyledTab = styled(({ darkMode, ...props }) => <Tab disableRipple {...props} />)(({ darkMode }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: 16,
  fontFamily: 'inherit',
  letterSpacing: '-0.01em',
  backgroundColor: 'transparent',
  color: darkMode ? '#5d5d5d' : '#15293c80',
  '&.Mui-selected': {
    color: darkMode ? 'white' : 'black',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: '-0.01em',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ padding: '16px' }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const AllModelShowCase = memo(({ userData, currentJob, t, firebase, handleSelectCollection, darkMode }) => {
  const [jobsList, setJobsList] = useState([]);
  const [publicModels, setPublicModels] = useState([]);
  const [value, setValue] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [deletedModel, setDeletedModel] = useState(null);

  useEffect(() => {
    if (currentJob !== undefined && currentJob !== null) {
      if (currentJob.status === 'starting' || currentJob.status === 'queued') {
        const mergedJobsList = [...[currentJob], ...jobsList];
        setJobsList(mergedJobsList);
        setValue(1);
      }
    }
  }, [currentJob]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const snapshot = await firebase.getTrainingJobs(userData.uid);
        const jobList = snapshot.docs.map(doc => ({
          id: doc.id,
          triggerWord: doc.data().triggerWord,
          modelName: doc.data().modelName,
          type: doc.data().type,
          images: {
            before: doc.data().data[0],
            after: '',
          },
          thumbnail: doc.data().thumbnail,
          status: doc.data().status,
          progress: 1,
          createdAt: doc.data().createdAt ? new Date(doc.data().createdAt).toLocaleString() : '',
          error: doc.data().error,
          isPublic: false,
        }));
        setJobsList(jobList);

        const publicModels = await firebase.getPublicModels();
        const publicModelsList = publicModels.docs.map(doc => ({
          id: doc.id,
          triggerWord: doc.data().triggerWord,
          modelName: doc.data().modelName,
          type: doc.data().type,
          images: {
            before: doc.data().thumbnail,
            after: '',
          },
          thumbnail: doc.data().thumbnail,
          status: doc.data().status,
          isPublic: true,
        }));
        setPublicModels(publicModelsList);
      } catch (error) {
        console.error('Error fetching training jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function onSelectCollection(e, collection) {
    e.preventDefault();
    handleSelectCollection(collection);
  }

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const onRemove = (e, job) => {
    e.preventDefault();
    setDeletedModel(job)
    setOpenRemove(true);
  };

  function onConfirmRemove(e) {
    e.preventDefault();
    firebase
      .deleteTrainingJob(deletedModel.id)
      .then(result => {
        message.success('Removed the model successfully');
        setJobsList(jobsList.filter(job => job.id !== deletedModel.id));
      })
      .catch(err => {
        message.error(err.message);
        console.log(err);
      });

    setOpenRemove(false);
  }

  return (
    <div className={`results-area ${darkMode ? 'dark-bg' : ''}`}>
      <Box sx={{ borderBottom: 1, borderColor: darkMode ? '#373737' : '#f1f3f4' }}>
        <StyledTabs
          darkMode={darkMode}
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
          variant="scrollable"
          scrollButtons={false}
        >
          <StyledTab darkMode={darkMode} label={'👫 ' + t('showcase.model.hotModel')} />
          <StyledTab darkMode={darkMode} label={'✨ ' + t('showcase.model.title')} />
        </StyledTabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <div className={`${darkMode ? 'text-white' : 'text-black'}`}>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 gap-4">
            {publicModels.map((job, key) => (
              <div
                key={key}
                className={`rounded-lg shadow-md overflow-hidden transition-colors duration-200 w-full ${
                  darkMode ? 'dark-bg-home' : ''
                } home-item`}
              >
                <div
                  style={{
                    width: '100%',
                    height: '300px', // Set a fixed height for consistency
                    position: 'relative',
                    overflow: 'hidden',
                    objectFit: 'cover',
                  }}
                >
                  <img src={job.images.before} alt={`${job.modelName} model`} className="public-model-image" />
                </div>
                <div className="relative">
                  {true && (
                    <button
                      onClick={e => onSelectCollection(e, job)}
                      className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full"
                      style={{ marginTop: '-12px' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-play"
                      >
                        <polygon points="6 3 20 12 6 21 6 3" />
                      </svg>
                      <span style={{ marginLeft: '2px' }}>Run</span>
                    </button>
                  )}
                </div>
                <div className="p-4">
                  <p className="text-sm font-bold mb-1">{job.modelName}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className="rnftg-h-full rnftg-w-full" id="my-result">
          <div className="rnftg-h-full dark:rnftg-bg-gray-900">
            <div className="rnftg-flex rnftg-flex-col pd-2">
              <div className="rnftg-grid rnftg-gap-6 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-2 lg:rnftg-grid-cols-3 xl:rnftg-grid-cols-3">
                {jobsList.length > 0 &&
                  jobsList.map((job, key) => {
                    job.originalUrl = job.images.before;
                    job.upscaleUrl = job.images.after;
                    if (job.status === 'starting' || job.status === 'queued') {
                      return (
                        <>
                          <article
                            key={key}
                            className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300"
                          >
                            <div className="rnftg-item__img-wrapper">
                              <div className="model-result">
                                <LoaderLineMask status={null} />
                                <span className="rnftg-no-underline rnftg-text-black dark:rnftg-text-gray-200">
                                  {!imageLoaded && <NewSkeleton />}
                                  <img
                                    className={`rnftg-w-full rnftg-h-full rnftg-object-cover rnftg-cursor-pointer rnftg-rounded-t-2xl ${
                                      imageLoaded ? 'rendering-mask' : ''
                                    }`}
                                    alt="reok"
                                    src={job.images.before}
                                    onLoad={handleImageLoad}
                                  ></img>
                                  <div className="img_src_loader"></div>
                                  <div className="prompt-label">{t('message.prompt.training')}</div>
                                </span>
                              </div>
                            </div>
                          </article>
                        </>
                      );
                    } else if (job.status === 'finished') {
                      job.modelName = job.modelName;
                      job.modelType = job.type;
                      job.createdAt = job.createdAt;

                      return (
                        <div className={`${darkMode ? 'text-white' : 'text-black'}`}>
                          <div
                            key={key}
                            className={`rounded-lg shadow-md overflow-hidden transition-colors duration-200 w-full ${
                              darkMode ? 'dark-bg-home' : ''
                            } home-item`}
                          >
                            <div
                              style={{
                                width: '100%',
                                height: '300px', // Set a fixed height for consistency
                                position: 'relative',
                                overflow: 'hidden',
                                objectFit: 'cover',
                              }}
                            >
                              <img src={job.images.before} alt={`${job.modelName} model`} className="public-model-image" />
                            </div>
                            <div className="relative">
                              {true && (
                                <button
                                  onClick={e => onSelectCollection(e, job)}
                                  className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full"
                                  style={{ marginTop: '-12px' }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-play"
                                  >
                                    <polygon points="6 3 20 12 6 21 6 3" />
                                  </svg>
                                  <span style={{ marginLeft: '2px' }}>Run</span>
                                </button>
                              )}
                            </div>
                            <div className="p-4">
                              <p className="text-sm font-bold mb-1">{job.modelName}</p>
                              <div className="flex items-center justify-between">
                                {/* <p className="text-sm mb-1"> expired at: {job.expiredAt}</p> */}
                                <button className="text-sm mb-1 text-red" onClick={(e) => onRemove(e, job)}>
                                  remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                      // return <CollectionModelCard darkMode={darkMode} key={key} t={t} info={job} onSelectCollection={onSelectCollection} />;
                    } else if (job.status === 'failed') {
                      return (
                        <>
                          <article
                            key={key}
                            className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300"
                          >
                            <div className="rnftg-item__img-wrapper">
                              <div className="model-result">
                                <div className="line-mask">
                                  <div className="circular-progress">
                                    <svg width="20px" height="20px" viewBox="0 0 0.5 0.5" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        fill="#d32f2f"
                                        d="M0.49 0.397 0.3 0.048C0.288 0.025 0.269 0.013 0.25 0.013c-0.02 0 -0.038 0.013 -0.05 0.036L0.01 0.397c-0.012 0.023 -0.014 0.046 -0.003 0.063 0.01 0.018 0.03 0.028 0.055 0.028h0.376c0.025 0 0.045 -0.01 0.056 -0.028 0.01 -0.018 0.009 -0.04 -0.004 -0.063ZM0.25 0.121c0.01 0 0.018 0.008 0.018 0.018v0.165c0 0.01 -0.008 0.018 -0.018 0.018a0.018 0.018 0 0 1 -0.018 -0.018V0.14c0 -0.01 0.008 -0.018 0.018 -0.018Zm0 0.291c-0.015 0 -0.028 -0.013 -0.028 -0.028 0 -0.016 0.013 -0.029 0.028 -0.029 0.015 0 0.028 0.013 0.028 0.028 0 0.016 -0.013 0.029 -0.028 0.029Z"
                                      />
                                    </svg>
                                  </div>
                                </div>
                                <span className="rnftg-no-underline rnftg-text-black dark:rnftg-text-gray-200">
                                  <img
                                    className="rnftg-w-full rnftg-h-full rnftg-object-cover rnftg-cursor-pointer rnftg-rounded-t-2xl rendering-mask"
                                    id="img_src_loader"
                                    loading="lazy"
                                    src={job.images.before}
                                  />
                                  <div className="img_src_loader"></div>
                                  <div className="prompt-label">{job.error}</div>
                                </span>
                              </div>
                            </div>
                          </article>
                        </>
                      );
                    } else {
                      return <></>;
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      </CustomTabPanel>
      {openRemove && (
        <div>
          <Modal 
            open={openRemove} 
            // onClick={() => setOpenRemove(false)}
          >
            <ModalDialog
              sx={{
                // width: {
                //   xs: '98%',
                //   md: '1200px'
                // },
                // height: '100%',
                background: '#09090b',
                border: '1px solid #373737',
                overflow: 'auto',
              }}
            >
              <ModalClose
                onClick={() => setOpenRemove(false)}
                sx={{
                  position: 'absolute',
                  top: '4px',
                  right: '4px',
                  color: 'white',
                  zIndex: 1000,
                }}
              />
              <h2 className="flex items-center gap-2 text-xl font-bold text-white mb-4">
                <WarningIcon sx={{ color: '#d32f2f' }}/>
                Removing model: {deletedModel.modelName}
              </h2>
              <p className="text-sm text-white ">
                This model will be permanently removed. This action cannot be undone.
                <br />
                Are you sure you want to remove this model ?
              </p>
              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}>
                <button
                  onClick={() => setOpenRemove(false)}
                  style={{
                    padding: '8px 16px',
                    background: 'transparent',
                    border: '1px solid #373737',
                    color: 'white',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  {t('button.cancel')}
                </button>
                <button
                  onClick={e => onConfirmRemove(e)}
                  style={{
                    padding: '8px 16px',
                    background: '#d32f2f',
                    border: 'none',
                    color: 'white',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  {t('button.ok')}
                </button>
              </div>
            </ModalDialog>
          </Modal>
        </div>
      )}
    </div>
  );
});

export default AllModelShowCase;
