import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { message } from '../Message';
import { useTranslation } from 'react-i18next';
import withBackend from '../../backend/withBackend';
import { setUser } from '../../actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Snackbar } from '@mui/material';

const GiveawayModal = ({ isOpen, onClose, onSubmitted, isSubmitted, currentLanguage, engine }) => {
  const popupRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [openCoupon, setOpenCoupon] = useState(false)

  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = event => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onClose]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    if (loading) return;

    // navigator.clipboard.writeText("REOK10");
    // setOpenCoupon(true);

    if (!email) {
      setEmailError(t('message.error.emptyEmail'));
      return;
    }

    if (!validateEmail(email)) {
      setEmailError(t('message.error.validEmail'));
      return;
    }

    setLoading(true);

    const apiUrl = 'https://reok.lemonsqueezy.com/email-subscribe/external';

    try {
      const response = await axios.post(apiUrl, {
        email
      });

      setLoading(false);

      if (response.status === 200) {
        handleSubmitWithEmailLink(email);
        setLoading(false);
        onSubmitted(true);
        message.success(t('message.success.subscribed'));
        // onClose();
      } else {
        message.error(t('message.error.subscribeError'));
      }
    } catch (error) {
      setLoading(false);
      message.error(t('message.error.subscribeError'));
    }
  };

  const handleSubmitWithEmailLink = (email) => {
    try {

      const baseUrl = window.location.origin;
      const redirectUrl = currentLanguage === 'en'
        ? `${baseUrl}/verify-email/?email=${email}`
        : `${baseUrl}/${currentLanguage}/verify-email/?email=${email}`;

      const actionCodeSettings = {
        url: redirectUrl,
        handleCodeInApp: true,
      };

      engine
        .sendSignInLinkToEmail(email, actionCodeSettings)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });

    } catch (error) {
      console.log(error);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-75 backdrop-blur-sm flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out"
        style={{ opacity: isOpen ? 1 : 0, pointerEvents: isOpen ? 'auto' : 'none' }}>
        <div ref={popupRef}
          className="relative bg-black rounded-lg shadow-lg p-5 max-w-[412px] mx-auto text-white transition-all duration-300 ease-in-out"
          style={{ transform: isOpen ? 'scale(1)' : 'scale(0.9)', opacity: isOpen ? 1 : 0, border: "1px solid #343434" }}>
          <button
            onClick={onClose}
            className="absolute right-2 text-gray-400 hover:text-gray-200 top-1"
          >
            ✕
          </button>
          <div className="flex justify-center mb-4">
            <svg width="50" height="50" viewBox="0 0 0.875 0.875" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" className="bounce-rotate-animation">
              <path fill="#d32f2f" d="M0.729 0.813H0.146a0.042 0.042 0 0 1 -0.042 -0.042V0.354h0.667v0.417c0 0.023 -0.019 0.042 -0.042 0.042" />
              <path fill="#f44336" d="M0.813 0.354H0.063v-0.125C0.063 0.206 0.081 0.188 0.104 0.188h0.667c0.023 0 0.042 0.019 0.042 0.042z" />
              <path fill="#ff8f00" d="M0.396 0.354h0.083V0.813H0.396zm0.125 -0.292 -0.125 0.125h0.083l0.125 -0.125z" />
              <path fill="#ffc107" d="M0.396 0.354h0.083V0.188l-0.125 -0.125H0.271l0.125 0.125z" />
            </svg>
          </div>

          <h2 className="text-center text-2xl font-bold mb-2">
            <div className="mx-auto relative z-[10] text-center mx-auto md:text-center label-giveaway">
              <span>{t('giveAway.title')}</span>
            </div>
          </h2>

          <p className="text-center text-gray-400 mb-6">
            {t('giveAway.subTitle')}
          </p>
          {isSubmitted ? (
            <div className="flex flex-col items-center justify-center mb-2">
              <hr className="w-full border-t border-gray-700 mb-6" />
              <div className="flex items-center justify-center mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                <span className="text-white font-semibold">&nbsp;{t('giveAway.emailSent')}</span>
              </div>
              <h2 className="text-center text-2xl font-bold">
                <div className="mx-auto relative z-[10] text-center mx-auto md:text-center label-giveaway">
                  <span>{t('giveAway.emailSentTitle')}</span>&nbsp;🎉
                </div>
              </h2>
              <p className="text-gray-400 text-center">
                {t('giveAway.emailSentSub')}
              </p>
            </div>
          ) : (
            <>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('giveAway.enterYourEmail')}
                className="w-full px-4 py-3 rounded-md border border-gray-700 mb-3 focus:outline-none text-white placeholder-gray-500" style={{ backgroundColor: '#151515' }}
              />
              {emailError && <p className="text-red text-sm mb-4">{emailError}</p>}

              <button
                onClick={handleSubmit}
                className="w-full bg-pink-500 text-white py-4 text-sm rounded-md hover:bg-yellow-400 hover:scale-105 transition duration-300 font-semibold"
              >
                {loading ? t('giveAway.submitting') : t('giveAway.notifyMe')}
              </button>
              {/* <div onClick={handleSubmit} className="upgrade-button"
                style={{ cursor: 'pointer' }}
              >
                {loading ? t('giveAway.submitting') : t('giveAway.notifyMe')}
              </div> */}
              <div className="text-center text-sm text-gray-500 mt-4 mb-2">
                {t('tos.agree')}
                <a
                  className="agree-service" style={{ color: "#c2c2c2" }}
                  href={'/legal/?view=terms'}
                  target="_blank"
                >
                  {t('footer.terms')}
                </a>
                {' '}&{' '}
                <a
                  className="agree-service" style={{ color: "#c2c2c2" }}
                  href={'/legal/?view=privacy-policy'}
                  target="_blank"
                >
                  {t('footer.privacy')}
                </a>
              </div>
            </>
          )}
        </div>
      </div>
      <Snackbar
        open={openCoupon}
        onClose={() => setOpenCoupon(false)}
        autoHideDuration={1500}
        message="Copied REOK10"
      />
    </>
  );
};

export default compose(
  connect(null, { setUser }),
  withBackend
)(GiveawayModal);
