import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import MoneyBackGuarantee from '../MoneyBackGuarantee';
import { useStore } from "../SmartEditor/lib/states"

const modes = {
  pro: "Pro",
  basic: "Basic"
}

const ComposeSection = ({ userData, onShowReferal, onShowPricing, defaultMode, t, currentLanguage }) => {

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(defaultMode);
  const [
    settings
  ] = useStore((state) => [
    state.settings,
  ])

  return (
    <>
      <main>
        <div className="progress-area">
          <div className="flex space-between w-100p">
            <strong>{t('app.menu.creditTitle')}</strong>
            <div className="flex items-center mb-0">
              <span style={{marginRight: "2px", marginTop: "-2px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="#ffc337"><path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z"/></svg>
              </span>
              {/* {mode === modes.pro ?
                <span className="text-white">{userData.proCredits?.toFixed(0)}</span>
                :
                <span className="text-white">{userData.basicCredits?.toFixed(0)}</span>
              } */}
              {mode === modes.pro ?
                <span className="text-white">{settings.credits?.toFixed(0)}</span>
                :
                <span className="text-white">{settings.credits?.toFixed(0)}</span>
              }
            </div>
          </div>
          {/* <div className="mt-2">
            <MoneyBackGuarantee/>
          </div> */}
          <Button 
            className="upgrade-button mt-4"
            onClick={() => onShowPricing(true)}
            type="submit"
            loading={loading}
            // variant="contained"
            sx={{
              backgroundColor: 'inherit',
              color: 'white',
              '&:hover': {
                backgroundColor: '#000',
              },
              '&:disabled': {
                backgroundColor: '#ffa5c0',
                color: 'white',
              },
              textTransform: 'none',
              // padding: '8px 16px',
              fontSize: '14px',
              width: '100%'
            }}
          >
            {t('button.upgrade')}
          </Button>
          {/* {currentLanguage=== 'vi' ? 
            (<div className="idea-alert" onClick={() => onShowReferal(true)}>
              <div className="idea-inner">
                <div className="ref-title">
                  <svg stroke="#ef4444" fill="#ef4444" strokeWidth="0" viewBox="0 0 1024 1024"  xmlns="http://www.w3.org/2000/svg">
                    <path d="M160 894c0 17.7 14.3 32 32 32h286V550H160v344zm386 32h286c17.7 0 32-14.3 32-32V550H546v376zm334-616H732.4c13.6-21.4 21.6-46.8 21.6-74 0-76.1-61.9-138-138-138-41.4 0-78.7 18.4-104 47.4-25.3-29-62.6-47.4-104-47.4-76.1 0-138 61.9-138 138 0 27.2 7.9 52.6 21.6 74H144c-17.7 0-32 14.3-32 32v140h366V310h68v172h366V342c0-17.7-14.3-32-32-32zm-402-4h-70c-38.6 0-70-31.4-70-70s31.4-70 70-70 70 31.4 70 70v70zm138 0h-70v-70c0-38.6 31.4-70 70-70s70 31.4 70 70-31.4 70-70 70z"/>
                  </svg>
                  <span>{t('app.menu.affiliateTitle')}</span>
                </div>
                <div className="ref-des">{t('app.menu.affiliateSub')}</div>
              </div>
            </div>)
            : 
            (<div className="idea-alert">
              <a href='https://reok.lemonsqueezy.com/affiliates'>
              <div className="idea-inner">
                <div className="ref-title">
                  <svg stroke="#ef4444" fill="#ef4444" strokeWidth="0" viewBox="0 0 1024 1024"  xmlns="http://www.w3.org/2000/svg">
                    <path d="M160 894c0 17.7 14.3 32 32 32h286V550H160v344zm386 32h286c17.7 0 32-14.3 32-32V550H546v376zm334-616H732.4c13.6-21.4 21.6-46.8 21.6-74 0-76.1-61.9-138-138-138-41.4 0-78.7 18.4-104 47.4-25.3-29-62.6-47.4-104-47.4-76.1 0-138 61.9-138 138 0 27.2 7.9 52.6 21.6 74H144c-17.7 0-32 14.3-32 32v140h366V310h68v172h366V342c0-17.7-14.3-32-32-32zm-402-4h-70c-38.6 0-70-31.4-70-70s31.4-70 70-70 70 31.4 70 70v70zm138 0h-70v-70c0-38.6 31.4-70 70-70s70 31.4 70 70-31.4 70-70 70z"/>
                  </svg>
                  <span>{t('app.menu.affiliateTitle')}</span>
                </div>
                <div className="ref-des">{t('app.menu.affiliateSub')}</div>
              </div>
              </a>
            </div>)
          } */}
        </div>
      </main>
    </>
  )
}

export default ComposeSection
