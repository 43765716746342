import React, { useEffect, useRef, useState } from 'react';
import { ArrowLeft as LeftOutlined, ArrowRight as RightOutlined } from '@mui/icons-material';

const BeforeAfter = ({ images }) => {

  const imageBeforeRef = useRef(null);
  const imageAfterRef = useRef(null);
  const sliderRef = useRef(null);
  const beforeRef = useRef(null);
  const afterRef = useRef(null);
  const resizerRef = useRef(null);

  useEffect(() => {
    // const slider = document.getElementById('before-after-slider');
    // const before = document.getElementById('before-image');
    // const after = document.getElementById('after-image');
    // const beforeImage = before.getElementsByTagName('img')[0];
    // const afterImage = after.getElementsByTagName('img')[0];
    // const resizer = document.getElementById('resizer');
    const slider = sliderRef.current;
    const before = beforeRef.current;
    const resizer = resizerRef.current;
    const beforeImage = beforeRef.current.querySelector('img');
    const afterImage = afterRef.current.querySelector('img');

    let active = false;

    afterImage.addEventListener('load', function () {
      const width = afterImage.width;
      beforeImage.style.width = width + 'px';
    });

    //Adjust width of image on resize 
    window.addEventListener('resize', function () {
      let width = slider.offsetWidth;
      beforeImage.style.width = width + 'px';
    })

    slider.addEventListener('mousedown', function () {
      active = true;
      resizer.classList.add('resize');
    });

    slider.addEventListener('mouseup', function () {
      active = false;
      resizer.classList.remove('resize');
    });

    slider.addEventListener('mouseleave', function () {
      active = false;
      resizer.classList.remove('resize');
    });

    slider.addEventListener('mousemove', function (e) {
      // if (!active) return;
      let x = e.pageX;
      x -= slider.getBoundingClientRect().left;
      slideIt(x);
      pauseEvent(e);
    });

    resizer.addEventListener('touchstart', function () {
      active = true;
      resizer.classList.add('resize');
    });

    slider.addEventListener('touchend', function () {
      active = false;
      resizer.classList.remove('resize');
    });

    slider.addEventListener('touchcancel', function () {
      active = false;
      resizer.classList.remove('resize');
    });

    //calculation for dragging on touch devices
    slider.addEventListener('touchmove', function (e) {
      // if (!active) return;
      let x;

      let i;
      for (i = 0; i < e.changedTouches.length; i++) {
        x = e.changedTouches[i].pageX;
      }

      x -= slider.getBoundingClientRect().left;
      slideIt(x);
      pauseEvent(e);
    });

    function slideIt(x) {
      let transform = Math.max(0, (Math.min(x, slider.offsetWidth)));
      before.style.width = transform + "px";
      resizer.style.left = transform - 0 + "px";
    }

    //stop divs being selected.
    function pauseEvent(e) {
      if (e.stopPropagation) e.stopPropagation();
      if (e.preventDefault) e.preventDefault();
      e.cancelBubble = true;
      e.returnValue = false;
      return false;
    }

    return () => {
      // afterImage.removeEventListener('load');
      // window.removeEventListener('resize');
      // resizer.removeEventListener('mousedown');
      // resizer.removeEventListener('touchstart');
      // slider.removeEventListener('mouseup');
      // slider.removeEventListener('mouseleave');
      // slider.removeEventListener('mousemove');
      // slider.removeEventListener('touchend');
      // slider.removeEventListener('touchcancel');
      // slider.removeEventListener('touchmove');
    };

  }, []);

  return (
    <div className="swiper">
      <div className="before-after-wrapper">
        <div id="before-after-slider" ref={sliderRef}>
          <div id="before-image" ref={beforeRef}>
            <img src={images.before} alt="before" className="before-image" ref={imageBeforeRef} />
          </div>
          <div id="after-image" ref={afterRef}>
            <img src={images.after} alt="after" className="after-image" ref={imageAfterRef} />
          </div>
          <div id="resizer" ref={resizerRef}>
            <div className="left-right-icon">
              <LeftOutlined /><RightOutlined />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeforeAfter;
