import React, { useEffect, useState, useRef } from 'react';
import { message } from '../Message';
import { TextField, Tooltip, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import AllModelShowCase from '../AllModelShowCase';
import heic2any from 'heic2any';
import axios from 'axios';
import { useStore } from "../SmartEditor/lib/states"
import Skeleton from '@mui/material/Skeleton';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';

const APP_URL_V1 = process.env.REACT_APP_APP_URL;

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};

const goodPhotos = [
  {
    src: "/images/good-ex.1.webp",
    gender: "woman"
  },
  {
    src: "/images/good-ex.2.webp",
    gender: "man"
  },
  {
    src: "/images/good-ex.3.webp",
    gender: "woman"
  },
  {
    src: "/images/good-ex.6.webp",
    gender: "man"
  },
  {
    src: "/images/good-ex.7.webp",
    gender: "woman"
  },
  {
    src: "/images/good-ex.5.webp",
    gender: "woman"
  },
  {
    src: "/images/good-ex.8.webp",
    gender: "man"
  },
  {
    src: "/images/good-ex.9.webp",
    gender: "man"
  },
  {
    src: "/images/good-ex.10.webp",
    gender: "man"
  },
  
];

const badPhotos = [
  {
    src: "/images/bad-ex.5.webp",
    gender: "woman"
  },
  {
    src: "/images/bad-ex.4.webp",
    gender: "man"
  },
  {
    src: "/images/bad-ex.1.webp",
    gender: "woman"
  },
  {
    src: "/images/bad-ex.2.webp",
    gender: "man"
  },
  {
    src: "/images/bad-ex.3.webp",
    gender: "woman"
  },
];

const MAX_WIDTH = 768;

const ageItems = [];
for (let age = 18; age <= 100; age++) {
  ageItems.push({
    value: age,
    label: age,
  });
}

const badWordsString = process.env.REACT_APP_BAD_WORDS || '';
const badWordsArray = badWordsString.split(',').map(word => word.trim());
const forbiddenWordsSet = new Set(badWordsArray.map(word => word.toLowerCase()));

const containsBadWord = (text) => {
  const words = text.toLowerCase().split(/\s+/);
  return words.some(word => forbiddenWordsSet.has(word));
};

const MAX_IMAGES = 20;
const MIN_IMAGES = 10;
const TRAIN_MODEL_CREDITS = 200;
const maxSizeBasic = 10 * 1024 * 1024; // 10 MB
const maxSizePro = 15 * 1024 * 1024; // 10 MB

const genders = [
  { name: 'Man', icon: '👨‍💼', type: 'man' },
  { name: 'Woman', icon: '👩‍💼', type: 'woman' },
  // { name: 'Non Binary', icon: NonBinaryIcon, type: 'non-binary' },
];

const subjects = [
  { name: 'Pet', icon: '🐶', type: 'pet' },
  { name: 'Product', icon: '🛍️', type: 'product' },
  { name: 'Clothing', icon: '👕', type: 'clothing' },
  { name: 'Food', icon: '🍔', type: 'food' },
  { name: 'Style', icon: '🎨', type: 'style' },
  { name: 'Font', icon: 'A', type: 'font' },
];

const TrainModel = ({ firebase, setUser, darkMode, userData, setUserData, handleSelectCollection, openStudio, onShowPricing, onHideMenuBar }) => {
  // const [userData, setUserData] = useState({});
  const dispatch = useDispatch();
  const [jobsList, setJobsList] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [mode, setMode] = useState(modes.pro); //Set default is PRO
  const [isShowBackground, setIsShowBackground] = useState(false);
  const fileInputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState('');
  const [file, setFile] = useState('');
  const [fileInfo, setFileInfo] = useState(null);
  const [currentJob, setCurrentJob] = useState(null);
  const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);
  const [isActiveDeskMenu, setIsActiveDeskMenu] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('');
  const [progress, setProgress] = useState(0);
  const navPopupRef = useRef(null);
  const [promptErrorMessage, setPromptErrorMessage] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const lastImageRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [shouldScrollToLast, setShouldScrollToLast] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [modelName, setModelName] = useState('');
  const [activeModeTab, setActiveModeTab] = useState('1');
  const [isLoadingExamples, setIsLoadingExamples] = useState(true);
  const [isUploadingSelfies, setIsUploadingSelfies] = useState(false);

  const [
    updateSettings
  ] = useStore((state) => [
    state.updateSettings
  ])

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    if (shouldScrollToLast && lastImageRef.current) {
      lastImageRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      setShouldScrollToLast(false);
    }
  }, [selectedImages, shouldScrollToLast]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // Access specific parameters
    const imageUrl = params.url;

    if (imageUrl !== undefined && imageUrl !== '') {
      sampleUploadFromLink(imageUrl);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (window.innerWidth <= 980) {
      setIsMobile(true);
      setIsActiveDeskMenu(false);
    }

    const handleResize = () => {
      if (window.innerWidth <= 980) {
        setIsMobile(true);
        setIsActiveDeskMenu(false);
      } else {
        setIsMobile(false);
        setIsActiveDeskMenu(true);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (navPopupRef.current && event.target.contains(navPopupRef.current)) {
        setIsActiveMobileMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [navPopupRef]);

  function checkCredits() {
    if (mode === modes.pro && ((userData.pro && userData.proCredits < 2) || !userData.pro)) {
      message.warning(t('message.warning.runOutProCredits'));
      setTimeout(() => {
        setIsCreating(false);
        onShowPricing(true);
      }, 1500);
      return true;
    }

    if (mode === modes.basic && ((userData.basic && userData.basicCredits < 2) || !userData.basic)) {
      message.warning(t('message.warning.runOutBasicCredits'));
      setTimeout(() => {
        setIsCreating(false);
        onShowPricing(true);
      }, 1500);
      return true;
    }

    return false;
  }

  function checkTrainModelCredits() {
    if (
      ((userData.activeModels < 1) ||
        (!userData.activeModels && !userData.modelsAllowed))) {
      message.warning(t('message.warning.upgradeModel'));
      setTimeout(() => {
        setIsCreating(false);
        onShowPricing(true);
      }, 1500);
      return true;
    }
    return false;
  }

  const scrollToTop = () => {
    const section = document.querySelector('#scroll-top');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const onCreateModel = async () => {
    if (checkTrainModelCredits()) {
      setIsCreating(false);
      return;
    }

    if (selectedImages.length < MIN_IMAGES) {
      message.error(t('message.error.uploadPhotoMin'));
      return;
    }

    if (modelName === '') {
      message.error(t('message.error.modelName'));
      return;
    }

    if (activeModeTab === '1' && selectedSubject === null) {
      message.error(t('message.error.selectGender'));
      return;
    }

    if (activeModeTab === '2' && selectedSubject === null) {
      message.error(t('message.error.selectSubject'));
      return;
    }

    setIsCreating(true);

    try {

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
      };

      const uploadedUrls = selectedImages.map(image => image.url);

      let requestDataEng = {
        packageName: "Pro",
        images: uploadedUrls,
        type: selectedSubject.type,
        modelName: modelName
      };

      const result = await axios.post(`${APP_URL_V1}/train-model`, requestDataEng, { headers });

      const credits = result.data.credits;
      updateSettings({ credits: credits });

      if (result.status === 200) {
        const job = {
          images: {
            before: uploadedUrls[0],
            after: '',
          },
          status: 'starting',
          progress: null,
          prompt: '',
          result: '',
        };
        setCurrentJob(job);
        message.success(t('Your model is training, will be done in 20 minutes'));
        onFetchUser();
        setIsCreating(false);
        return;
      } else {
        message.error(t('An error when uploading please try it again'));
        setIsCreating(false);
        return;
      }

    } catch (error) {
      console.error('Error uploading images:', error);
      message.error(t('An error when uploading please try it again'));
    } finally {
      setIsCreating(false);

    }
  }

  function onGenFill(newJob) {
    setCurrentJob(newJob);
  }

  function onShowBackground(value) {
    setIsShowBackground(value);
  }

  function onFetchUser() {
    firebase
      .getUser(firebase.auth.currentUser.uid)
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          setUserData(querySnapshot.data());
          setUser(querySnapshot.data());
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const checkFileSize = file => {
    const fileSize = file.size;

    if (mode === modes.pro && fileSize > maxSizePro) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return false;
    }
    if (mode === modes.basic && fileSize > maxSizeBasic) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return false;
    }
    return true;
  };

  const sampleUploadFromLink = async imageUrl => {
    const response = await fetch(imageUrl);
    const file = await response.blob();

    if (checkFileSize(file) === false) return;

    setFile(file);
    const reader = new FileReader();

    reader.onload = function () {
      const image = new Image();

      image.onload = function () {
        const canvas = document.createElement('canvas');
        const scaleSize = MAX_WIDTH / image.width;
        canvas.width = MAX_WIDTH;
        canvas.height = image.height * scaleSize;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        if (file.name === '' || file.name === undefined) {
          file.name = `reok-input-${uuidv4()}.jpg`;
        }

        canvas.toBlob(blob => {
          const canvasImage = new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          setFileInfo(canvasImage); // Set fileInfo as the canvas image with additional properties
          setFile(canvasImage);

          const reader2 = new FileReader();
          reader2.onloadend = function () {
            const srcEncoded = reader2.result;
            setImageSrc(srcEncoded);
          };

          canvas.remove(); // Remove the canvas element
          reader2.readAsDataURL(blob);
        }, 'image/jpeg');
      };

      image.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      const uploaderWrapper = document.querySelector('.uploader-wrapper');
      uploaderWrapper.style.border = '1px dashed #fc5185';
      uploaderWrapper.style.color = '#fc5185';
    } else if (e.type === 'dragleave') {
      const uploaderWrapper = document.querySelector('.uploader-wrapper');
      uploaderWrapper.style.border = !darkMode ? '1px dashed #373737' : '1px dashed rgb(0 0 0 / 20%)';
      uploaderWrapper.style.color = !darkMode ? '#373737' : '#8c8c8c';
    }
  };

  const hideMenuBar = () => {
    setIsActiveDeskMenu(!isActiveDeskMenu);
    onHideMenuBar(!isActiveDeskMenu);
  };

  const handleInputChange = event => {
    const newValue = event.target.value;
    setModelName(newValue);
  };

  const debouncedValidation = debounce((value) => {
    const badWord = containsBadWord(value);
    if (badWord) {
      setPromptErrorMessage(t('message.error.badWordsStyle', { word: badWord }));
    } else {
      setPromptErrorMessage('');
    }
  }, 100);

  const handleUploadClick = () => {
    const uploaderWrapper = document.querySelector('.uploader-wrapper');
    uploaderWrapper.style.border = '1px dashed #fc5185';
    uploaderWrapper.style.color = '#fc5185';
  };

  const handleImageUpload = async event => {
    event.preventDefault();
    setIsUploading(true);
    setIsUploadingSelfies(true);
  
    try {
      let files;
      if (event.dataTransfer) {
        files = Array.from(event.dataTransfer.files);
      } else if (event.target.files) {
        files = Array.from(event.target.files);
      } else {
        setIsUploading(false);
        return;
      }
  
      const processedFiles = await Promise.all(files.map(processFile));
      const validProcessedFiles = processedFiles.filter(file => file !== null);
  
      if (validProcessedFiles.length === 0) {
        message.error('Image upload failed, only JPG/JPEG/PNG/WEBP/HEIC allowed');
        return;
      }
  
      // Get existing file hashes
      const existingHashes = selectedImages
        .filter(img => img.hash)
        .map(img => img.hash);
  
      // Process and categorize files
      const categorizedFiles = await Promise.all(
        validProcessedFiles.map(async (file) => {
          const hash = await calculateImageHash(file);
          const validation = await validatePhoto(file);
          const isDup = existingHashes.includes(hash);
  
          return {
            file,
            hash,
            validation,
            category: isDup ? 'duplicate' : 
                    (mode === modes.pro && file.size > maxSizePro) ? 'oversized' : 
                    'valid'
          };
        })
      );
  
      // Separate files by category
      const validFiles = categorizedFiles.filter(f => f.category === 'valid');
      const duplicateCount = categorizedFiles.filter(f => f.category === 'duplicate').length;
      const oversizedCount = categorizedFiles.filter(f => f.category === 'oversized').length;
  
      // Show notifications if needed
      if (duplicateCount > 0) {
        message.info(`Skipped ${duplicateCount} duplicate file(s)`);
      }
      if (oversizedCount > 0) {
        message.warning(t('message.error.fileOverPro', { count: oversizedCount }));
      }
  
      // Proceed only if there are valid files
      if (validFiles.length > 0) {
        // Create temporary previews for valid files
        const tempPreviews = validFiles.map(({ file, hash, validation }) => ({
          id: uuidv4(),
          file,
          url: URL.createObjectURL(file),
          isUploading: true,
          hash,
          validation // Include validation results in preview
        }));
  
        // Update UI with temporary previews
        setSelectedImages(prevImages => {
          const combinedImages = [...prevImages, ...tempPreviews].slice(0, MAX_IMAGES);
          return combinedImages;
        });
  
        // Upload valid files
        try {
          const uploadPromises = validFiles.map(async ({ file, hash, validation }) => {
            const originalName = file.name.replace(/\.[^/.]+$/, '');
            const fileName = `${userData.uid}/${originalName}.jpg`;
            const downloadURL = await firebase.uploadDataSetsFile(file, fileName);
            return { 
              url: downloadURL, 
              hash,
              file,
              validation
            };
          });
  
          const uploadedUrls = await Promise.all(uploadPromises);
  
          // Update final state with uploaded files
          setSelectedImages(prevImages => {
            const updatedImages = prevImages
              .filter(img => !img.isUploading)
              .concat(uploadedUrls)
              .slice(0, MAX_IMAGES);
  
            return updatedImages;
          });
  
          // Calculate valid photos count
          const validPhotoCount = uploadedUrls.filter(
            ({ validation }) => validation.isValid
          ).length;
  
          // Show success message with valid photo count
          message.success(`Successfully uploaded ${validPhotoCount} valid photo${validPhotoCount !== 1 ? 's' : ''}`);
          
        } catch (error) {
          console.error('Upload error:', error);
          // message.error('Failed to upload some images');
          setIsUploadingSelfies(false);
          
          // Remove failed uploads from preview
          setSelectedImages(prevImages => 
            prevImages.filter(img => !img.isUploading)
          );
        }
      } else {
        // message.warning('No valid files to upload');
        setIsUploadingSelfies(false);
      }
  
    } catch (error) {
      console.error('Error processing files:', error);
      message.error('An error occurred while processing the files');
      setIsUploadingSelfies(false);
    } finally {
      // Reset UI state
      const uploaderWrapper = document.querySelector('.uploader-wrapper');
      if (uploaderWrapper) {
        uploaderWrapper.style.border = !darkMode ? '1px dashed #373737' : '1px dashed #4a4a4a';
        uploaderWrapper.style.color = !darkMode ? '#373737' : '#8c8c8c';
      }
  
      setIsUploading(false);
      setShouldScrollToLast(true);
      setIsUploadingSelfies(false);
  
      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleRemove = (index) => {
    setSelectedImages(prevImages => prevImages.filter((_, i) => i !== index));
    setShouldScrollToLast(false);
  };

  const calculateImageHash = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const buffer = e.target.result;
        let hash = 5381; // Better initial value than 0
        
        // Use a more robust hashing algorithm (djb2)
        for (let i = 0; i < buffer.byteLength; i++) {
          hash = ((hash << 5) + hash) + buffer[i];
          hash = hash >>> 0; // Convert to 32-bit unsigned
        }
        
        // Convert to hex string for more unique representation
        const hashHex = hash.toString(16).padStart(8, '0');
        
        // Include file size in hash for additional uniqueness
        const combinedHash = `${hashHex}-${file.size}`;
        
        resolve(combinedHash);
      };
      // Read more of the file for better uniqueness
      reader.readAsArrayBuffer(file.slice(0, 32768)); // Increased to 32KB
    });
  };

  const validatePhoto = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const issues = [];
        
        // Check dimensions
        if (img.width < 400 || img.height < 400) {
          issues.push('Resolution too low');
        }
        
        // Check aspect ratio
        const ratio = img.width / img.height;
        if (ratio < 0.5 || ratio > 2) {
          issues.push('Invalid aspect ratio');
        }
        
        // Check file size (max 10MB)
        if (file.size > 10 * 1024 * 1024) {
          issues.push('File too large');
        }
  
        resolve({
          isValid: issues.length === 0,
          issues: issues
        });
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const handleDrop = async event => {
    event.preventDefault();
    setIsUploading(true);
    setIsUploadingSelfies(true);
  
    try {
      let files;
      if (event.dataTransfer) {
        files = Array.from(event.dataTransfer.files);
      } else if (event.target.files) {
        files = Array.from(event.target.files);
      } else {
        setIsUploading(false);
        return;
      }
  
      const processedFiles = await Promise.all(files.map(processFile));
      const validProcessedFiles = processedFiles.filter(file => file !== null);
  
      if (validProcessedFiles.length === 0) {
        message.error('Image upload failed, only JPG/JPEG/PNG/WEBP/HEIC allowed');
        return;
      }
  
      // Get existing file hashes
      const existingHashes = selectedImages
        .filter(img => img.hash)
        .map(img => img.hash);
  
      // Process and categorize files
      const categorizedFiles = await Promise.all(
        validProcessedFiles.map(async (file) => {
          const hash = await calculateImageHash(file);
          const validation = await validatePhoto(file);
          const isDup = existingHashes.includes(hash);
  
          return {
            file,
            hash,
            validation,
            category: isDup ? 'duplicate' : 
                    (mode === modes.pro && file.size > maxSizePro) ? 'oversized' : 
                    'valid'
          };
        })
      );
  
      // Separate files by category
      const validFiles = categorizedFiles.filter(f => f.category === 'valid');
      const duplicateCount = categorizedFiles.filter(f => f.category === 'duplicate').length;
      const oversizedCount = categorizedFiles.filter(f => f.category === 'oversized').length;
  
      // Show notifications if needed
      if (duplicateCount > 0) {
        message.info(`Skipped ${duplicateCount} duplicate file(s)`);
      }
      if (oversizedCount > 0) {
        message.warning(t('message.error.fileOverPro', { count: oversizedCount }));
      }
  
      // Proceed only if there are valid files
      if (validFiles.length > 0) {
        // Create temporary previews for valid files
        const tempPreviews = validFiles.map(({ file, hash, validation }) => ({
          id: uuidv4(),
          file,
          url: URL.createObjectURL(file),
          isUploading: true,
          hash,
          validation // Include validation results in preview
        }));
  
        // Update UI with temporary previews
        setSelectedImages(prevImages => {
          const combinedImages = [...prevImages, ...tempPreviews].slice(0, MAX_IMAGES);
          return combinedImages;
        });
  
        // Upload valid files
        try {
          const uploadPromises = validFiles.map(async ({ file, hash, validation }) => {
            const originalName = file.name.replace(/\.[^/.]+$/, '');
            const fileName = `${userData.uid}/${originalName}.jpg`;
            const downloadURL = await firebase.uploadDataSetsFile(file, fileName);
            return { 
              url: downloadURL, 
              hash,
              file,
              validation
            };
          });
  
          const uploadedUrls = await Promise.all(uploadPromises);
  
          // Update final state with uploaded files
          setSelectedImages(prevImages => {
            const updatedImages = prevImages
              .filter(img => !img.isUploading)
              .concat(uploadedUrls)
              .slice(0, MAX_IMAGES);
  
            return updatedImages;
          });
  
          // Calculate valid photos count
          const validPhotoCount = uploadedUrls.filter(
            ({ validation }) => validation.isValid
          ).length;
  
          // Show success message with valid photo count
          message.success(`Successfully uploaded ${validPhotoCount} valid photo${validPhotoCount !== 1 ? 's' : ''}`);
          
        } catch (error) {
          console.error('Upload error:', error);
          // message.error('Failed to upload some images');
          setIsUploadingSelfies(false);
          
          // Remove failed uploads from preview
          setSelectedImages(prevImages => 
            prevImages.filter(img => !img.isUploading)
          );
        }
      } else {
        // message.warning('No valid files to upload');
        setIsUploadingSelfies(false);
      }
  
    } catch (error) {
      console.error('Error processing files:', error);
      message.error('An error occurred while processing the files');
      setIsUploadingSelfies(false);
    } finally {
      // Reset UI state
      const uploaderWrapper = document.querySelector('.uploader-wrapper');
      if (uploaderWrapper) {
        uploaderWrapper.style.border = !darkMode ? '1px dashed #373737' : '1px dashed #4a4a4a';
        uploaderWrapper.style.color = !darkMode ? '#373737' : '#8c8c8c';
      }
  
      setIsUploading(false);
      setShouldScrollToLast(true);
      setIsUploadingSelfies(false);
  
      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  // const handleDrop = async (event) => {
  //   event.preventDefault();
  //   setIsUploading(true);

  //   const files = Array.from(event.dataTransfer.files);
  //   const processedFiles = await Promise.all(files.map(processFile));
  //   const validProcessedFiles = processedFiles.filter(file => file !== null);

  //   if (validProcessedFiles.length === 0) {
  //     message.error("Image upload failed, only JPG/JPEG/PNG/WEBP/HEIC allowed");
  //     setIsUploading(false);
  //     return;
  //   }

  //   const [validFiles, oversizedFiles] = validProcessedFiles.reduce((acc, file) => {
  //     const fileSize = file.size;
  //     if ((mode === modes.pro && fileSize > maxSizePro)) {
  //       acc[1].push(file);
  //     } else {
  //       acc[0].push(file);
  //     }
  //     return acc;
  //   }, [[], []]);

  //   if (oversizedFiles.length > 0) {
  //     message.warning(t('message.error.fileOverPro', { count: oversizedFiles.length }));
  //   }

  //   setSelectedImages(prevImages => {
  //     if (prevImages.length >= MAX_IMAGES) {
  //       return prevImages;
  //     }

  //     const newImages = validFiles
  //       .filter(file => !prevImages.some(existingImage => existingImage.file.name === file.name))
  //       .map(file => ({
  //         file: file,
  //         url: URL.createObjectURL(file)
  //       }));

  //     // Only add new images up to a total of MAX_IMAGES
  //     const combinedImages = [...prevImages, ...newImages];
  //     return combinedImages.slice(0, MAX_IMAGES);
  //   });

  //   const uploaderWrapper = document.querySelector('.uploader-wrapper');
  //   uploaderWrapper.style.border = !darkMode ? '1px dashed #373737' : '1px dashed rgb(0 0 0 / 20%)';
  //   uploaderWrapper.style.color = !darkMode ? '#373737' : '#8c8c8c';

  //   setIsUploading(false);
  //   setShouldScrollToLast(true);
  // };

  const processFile = async (file) => {
    // Check file format
    const validFormats = ['image/webp', 'image/jpeg', 'image/png'];
    if (!validFormats.includes(file.type) && !file.name.toLowerCase().endsWith('.heic')) {
      return null;
    }

    let processedFile = file;
    if (!validFormats.includes(file.type)) {
      try {
        const pngBlob = await convertToPNG(file);
        processedFile = new File([pngBlob], file.name.replace(/\.[^/.]+$/, ".png"), { type: 'image/png' });
      } catch (error) {
        console.log('Error converting file:', error);
        return null;
      }
    }

    return processedFile;

    // // Check resolution
    // const dimensions = await getImageDimensions(processedFile);
    // const isLowResolution = dimensions.width < 1024 || dimensions.height < 1024;

    // return {
    //   file: processedFile,
    //   url: URL.createObjectURL(processedFile),
    //   isLowResolution: isLowResolution,
    //   dimensions: dimensions
    // };
  };

  const convertToPNG = (file) => {
    return new Promise((resolve, reject) => {
      if (file.type === 'image/heic' || file.name.toLowerCase().endsWith('.heic')) {
        heic2any({
          blob: file,
          toType: 'image/png',
        }).then((conversionResult) => {
          resolve(conversionResult);
        }).catch((error) => {
          console.error('Error converting HEIC to PNG:', error);
          reject(error);
        });
      } else {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(resolve, 'image/png');
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
      }
    });
  };

  const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });
  };

  const handleSubjectSelect = (subject) => {
    setSelectedSubject(subject);
  };

  const handleModeTabChange = (key) => {
    setActiveModeTab(key);
  };

  const renderImage = (image, index) => (
    <div
      ref={index === selectedImages.length - 1 ? lastImageRef : null}
      key={image.id || index}
      className="relative"
      style={{
        margin: '2px',
        border: darkMode ? '1px solid #373737' : '1px solid #4a4a4a',
        borderRadius: '10px',
      }}
    >
      <div className="relative w-24 h-24 flex-shrink-0">
        <div className="w-full h-full overflow-hidden rounded-lg mr-1">
          <img
            src={image.url}
            alt={`Selected ${index + 1}`}
            className={`w-full object-cover ${image.isUploading ? 'opacity-50' : ''}`}
            onLoad={e => {
              if (e.target.naturalHeight > e.target.naturalWidth) {
                e.target.classList.add('h-auto');
              } else {
                e.target.classList.add('h-full');
              }
            }}
          />
        </div>

        {image.isUploading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <CircularProgress size={24} sx={{ color: '#fff' }} />
          </div>
        )}

        <div className="w-full mt-1">
          <button
            disabled={isUploadingSelfies}
            className={`${darkMode ? 'text-white' : 'text-black'}`}
            style={{
              border: '1px solid #fc5185',
              background: 'none',
              cursor: 'pointer',
              borderRadius: '6px',
              padding: '2px 4px',
              width: '100%',
              fontSize: '12px',
            }}
            onClick={() => handleRemove(index)}
            aria-label="Remove image"
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );

  const renderExamplePhoto = (photo, index) => (
    <div
      key={index}
      className="relative"
      style={{ margin: '2px', border: darkMode ? '1px solid #373737' : '1px solid #4a4a4a', borderRadius: '10px' }}
    >
      <div className="relative w-24 h-24 flex-shrink-0">
        <div className="w-full h-full overflow-hidden rounded-lg mr-1">
          {isLoadingExamples[photo.src] !== false && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              animation="wave"
              sx={{
                bgcolor: darkMode ? 'grey.800' : 'grey.200',
                position: 'absolute',
                top: 0,
                left: 0
              }}
            />
          )}
          <img
            src={photo.src}
            alt={`Selected ${index + 1}`}
            className={`w-full object-cover ${isLoadingExamples[photo.src] === false ? 'opacity-100' : 'opacity-0'}`}
            onLoad={e => {
              if (e.target.naturalHeight > e.target.naturalWidth) {
                e.target.classList.add('h-auto');
              } else {
                e.target.classList.add('h-full');
              }
              setIsLoadingExamples(prev => ({
                ...prev,
                [photo.src]: false
              }));
            }}
          />
        </div>
        <div className="absolute t-0 inset-0" style={{ marginLeft: '2px' }}>
          {isLoadingExamples[photo.src] === false && (
            photo.isGood ? (
              <svg
                style={{ marginLeft: '-4px', marginTop: '-4px' }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#1bf259"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                  fill="#1bf259"
                />
              </svg>
            ) : (
              <span>❌</span>
            )
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`right-area editor-area ${darkMode ? 'dark-bg' : 'light-bg'}`}
      style={{
        transition: 'all 0.35s ease',
        left: !isActiveDeskMenu ? '0' : '0',
        width: !isActiveDeskMenu ? '100%' : 'calc(100% - 250px)',
      }}
    >
      <div className={`form-area ${darkMode ? 'dark-bg dark-border-2' : 'light-bg light-border-2'}`}>
        <div>
          <div className="generate-form" style={{ paddingBottom: isMobile ? '16px' : '10px' }}>
            {!isMobile ? (
              <>
                <div className="flex align-items-center">
                  <span style={{ color: '#888888' }} className={`${darkMode ? 'cursor-pointer-dark' : 'cursor-pointer-light'}`} onClick={hideMenuBar}>
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="bi bi-layout-sidebar-inset"
                    >
                      <path d="M21 3a1.5 1.5 0 0 1 1.5 1.5v15a1.5 1.5 0 0 1 -1.5 1.5H3a1.5 1.5 0 0 1 -1.5 -1.5V4.5a1.5 1.5 0 0 1 1.5 -1.5zM3 1.5a3 3 0 0 0 -3 3v15a3 3 0 0 0 3 3h18a3 3 0 0 0 3 -3V4.5a3 3 0 0 0 -3 -3z" />
                      <path d="M4.5 6a1.5 1.5 0 0 1 1.5 -1.5h3a1.5 1.5 0 0 1 1.5 1.5v12a1.5 1.5 0 0 1 -1.5 1.5H6a1.5 1.5 0 0 1 -1.5 -1.5z" />
                    </svg>
                  </span>
                  <h3 className={`mb-0 pl-4 ${darkMode ? 'text-white' : 'text-black'}`}>{t('app.menu.trainModel')}</h3>
                </div>
                <div className={`mb-0 ${darkMode ? 'text-white' : 'text-black'} model-alert mt-4 py-2 px-4`}>{t('app.trainModel.guide')} <span className='coupon-card bold' style={{ color: darkMode ? '#fff' : '#fc5183', cursor: 'pointer' }} onClick={openStudio}>Studio</span></div>
              </>
            ) : (
              <>
                <h3 className={`mb-0 ${darkMode ? 'text-white' : 'text-black'}`}>{t('app.menu.trainModel')}</h3>
                <div className={`mb-0 ${darkMode ? 'text-white' : 'text-black'} model-alert mt-4 p-4`}>{t('app.trainModel.guide')} <span className='coupon-card bold' style={{ color: darkMode ? '#fff' : '#fc5183', cursor: 'pointer' }} onClick={openStudio}>Studio</span></div>
              </>
            )}
            <>
              <div className="page-header mb-0 page-header-reset mb-4" />
              {/* <hr className="section-line" /> */}
              {/* <div className="page-header mb-0 page-header-reset mb-2" /> */}
              {/* <span style={{ color: '#8c8c8c', fontSize: '14px', marginTop: '0' }}>{t('app.trainModel.title')}</span> */}
              <div
                className={`w-full ${darkMode ? 'dark-border-full' : 'light-border-full'} rounded-lg`}
              >
                <div className={`flex`}>
                  <button
                    className={`w-full py-3 px-4 text-center transition-colors duration-200 ease-in-out
                      ${activeModeTab === '1'
                        ? darkMode
                          ? 'dark-bg-tab text-white border-b-2 border-pink-500'
                          : 'gray-bg-tab text-black border-b-2 border-pink-500'
                        : darkMode
                          ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                          : 'text-gray-600 hover:bg-gray-200'
                      }
                    `}
                    style={{ borderRadius: '4px', fontSize: '14px', margin: '4px' }}
                    onClick={() => handleModeTabChange('1')}
                  >
                    Person
                  </button>
                  <button
                    className={`w-full py-2 px-4 text-center transition-colors duration-200 ease-in-out
                      ${activeModeTab === '2'
                        ? darkMode
                          ? 'dark-bg-tab text-white border-b-2'
                          : 'gray-bg-tab text-black border-b-2 border-pink-500'
                        : darkMode
                          ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                          : 'text-gray-600 hover:bg-gray-200'
                      }
                    `}
                    style={{ borderRadius: '4px', fontSize: '14px', margin: '4px' }}
                    onClick={() => handleModeTabChange('2')}
                  >
                    Object
                  </button>
                </div>
              </div>
              <div className="page-header mb-0 page-header-reset mb-2" />
              <div
                className={`w-full ${darkMode ? 'dark-border-full' : 'light-border-full'} rounded-lg`}
              >
                <div className="p-4">
                  {activeModeTab === '1' && (
                    <div className="h-full">
                      <label className={`${darkMode ? 'text-white' : 'text-black'}`}>
                        Name
                        {' '}
                        <Tooltip title="Name the model so you can easily identify it later.">
                          <span className="ml-1 text-gray-500 cursor-help">?</span>
                        </Tooltip>
                      </label>
                      <div className="page-header mb-0 page-header-reset mb-2" />
                      <TextField
                        value={modelName}
                        sx={{
                          width: '100%',
                          border: darkMode ? '1px solid #373737' : '',
                          borderRadius: '6px',
                          background: darkMode ? '#323232' : '',
                          color: darkMode ? 'white' : 'black',
                          fontSize: isMobile ? '16px' : '14px',
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: '#fc5185',
                            },
                          },
                        }}
                        maxRows={1}
                        minRows={1}
                        onChange={handleInputChange}
                        placeholder="Your model name"
                        inputProps={{
                          style: {
                            fontSize: isMobile ? '16px' : '14px',
                            color: darkMode ? 'white' : 'black',
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: isMobile ? '16px' : '14px',
                            color: darkMode ? 'white' : 'black',
                          },
                        }}
                        error={!!promptErrorMessage}
                        helperText={promptErrorMessage}
                        autoFocus={false}
                      />
                      <div className="page-header mb-0 page-header-reset mb-4" />
                      <label className={`${darkMode ? 'text-white' : 'text-black'}`}>
                        Select Gender
                        {/* {' '}
                        <Tooltip title="Select the type of model you want to create.">
                          <span className="ml-1 text-gray-500 cursor-help">?</span>
                        </Tooltip> */}
                      </label>
                      <div className="page-header mb-0 page-header-reset mb-2" />
                      <div className="grid grid-cols-3 sm:grid-cols-4 gap-4">
                        {genders.map((subject, key) => (
                          <button
                            key={key}
                            style={{ border: darkMode ? '1px solid #373737' : '1px solid #ececec', borderRadius: '10px' }}
                            className={`flex flex-col items-center justify-center p-4 rounded-lg transition-colors
                            ${selectedSubject === subject
                                ? darkMode
                                  ? 'bg-pink-500 text-white'
                                  : 'bg-pink-500 text-white'
                                : darkMode
                                  ? 'dark-bg-item text-white'
                                  : 'light-bg-item text-black'
                              }`}
                            onClick={() => handleSubjectSelect(subject)}
                          >
                            <span className="mb-2">{subject.icon}</span>
                            <span className="text-sm">{subject.name}</span>
                          </button>
                        ))}
                      </div>
                      <div className="page-header mb-0 page-header-reset mb-4" />
                      <label className={`${darkMode ? 'text-white' : 'text-black'}`}>
                        Select 10+ photos
                        {' '}
                        <Tooltip title="See guide below for selecting good photos.">
                          <span className="ml-1 text-gray-500 cursor-help">?</span>
                        </Tooltip>
                      </label>
                      <div className={`${darkMode ? 'text-light-gray' : 'text-black'} photo-alert mt-1`}>
                        <div className="mb-4">
                          <div className={`mb-1 flex items-center`} style={{ color: darkMode ? "#3eff76" : "#00bb36" }}>
                            {/* <span>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="#00bb36" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z" fill="#00bb36"></path></svg>
                            </span> */}
                            <span>
                              Photo requirements
                            </span>
                          </div>
                          <p className="mb-4">
                            Recent photos of you, mix of close-ups and full body, different places, poses, outfits, and expressions.
                          </p>
                          <div className="example-tab-content">
                          <div className="flex space-x-4">
                            {goodPhotos.map((photo, index) => renderExamplePhoto({...photo, isGood: true}, index))}
                          </div>
                          </div>
                        </div>

                        <div>
                          <p className={`mb-1`} style={{ color: darkMode ? "#ff6565" : "#ff2f2e" }}>Photo restrictions</p>
                          <p className="mb-4">
                            Group shots, filtered photos, too close or hidden faces, old pictures, or images with animal.
                          </p>
                          <div className="example-tab-content">
                          <div className="flex space-x-4">
                            {badPhotos.map((photo, index) => renderExamplePhoto({...photo, isGood: false}, index))}
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeModeTab === '2' && (
                    <div className="h-full">
                      <label className={`${darkMode ? 'text-white' : 'text-black'}`}>
                        Name
                        {' '}
                        <Tooltip title="Name the model so you can easily identify it later.">
                          <span className="ml-1 text-gray-500 cursor-help">?</span>
                        </Tooltip>
                      </label>
                      <div className="page-header mb-0 page-header-reset mb-2" />
                      <TextField
                        value={modelName}
                        sx={{
                          width: '100%',
                          border: darkMode ? '1px solid #373737' : '',
                          borderRadius: '6px',
                          background: darkMode ? '#323232' : '',
                          color: darkMode ? 'white' : 'black',
                          fontSize: isMobile ? '16px' : '14px',
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: '#fc5185',
                            },
                          },
                        }}
                        maxRows={1}
                        minRows={1}
                        onChange={handleInputChange}
                        placeholder="Your model name"
                        inputProps={{
                          style: {
                            fontSize: isMobile ? '16px' : '14px',
                            color: darkMode ? 'white' : 'black',
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: isMobile ? '16px' : '14px',
                            color: darkMode ? 'white' : 'black',
                          },
                        }}
                        error={!!promptErrorMessage}
                        helperText={promptErrorMessage}
                        autoFocus={false}
                      />
                      <div className="page-header mb-0 page-header-reset mb-4" />
                      <label className={`${darkMode ? 'text-white' : 'text-black'}`}>
                        Select Type
                        {' '}
                        <Tooltip title="Select the type of model you want to create.">
                          <span className="ml-1 text-gray-500 cursor-help">?</span>
                        </Tooltip>
                      </label>
                      <div className="page-header mb-0 page-header-reset mb-2" />
                      <div className="grid grid-cols-3 sm:grid-cols-4 gap-4">
                        {subjects.map((subject, key) => (
                          <button
                            key={key}
                            style={{ border: darkMode ? '1px solid #373737' : '1px solid #ececec', borderRadius: '10px' }}
                            className={`flex flex-col items-center justify-center p-4 rounded-lg transition-colors
                          ${selectedSubject === subject
                                ? darkMode
                                  ? 'bg-pink-500 text-white'
                                  : 'bg-pink-500 text-white'
                                : darkMode
                                  ? 'dark-bg-item text-white'
                                  : 'light-bg-item text-black'
                              }`}
                            onClick={() => handleSubjectSelect(subject)}
                          >
                            <span className="mb-2">{subject.icon}</span>
                            <span className="text-sm">{subject.name}</span>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="photographer-guide mt-4 photo-alert p-4" style={{ border: darkMode ? '1px solid #373737' : '1px solid #e9e9e9' }}>
                <div className={`mb-4 ${darkMode ? 'text-white' : 'text-black'}`}>
                  <p>
                    Select 10-20 different photos (more is better)
                  </p>
                </div>
                <div>
                  <div
                    className={`uploader-wrapper ${darkMode ? 'dark-border dark-bg-input' : 'light-border'}`}
                    onClick={() => fileInputRef.current.click()}
                    onDragOver={handleDrag}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDrop={handleDrop}
                  >
                    <div className="uploader-title">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon"
                        role="presentation"
                      >
                        <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
                        <polyline points="17 8 12 3 7 8" />
                        <line x1="12" y1="3" x2="12" y2="15" />
                      </svg>
                      <span style={{ marginLeft: '4px' }}>
                        {isUploading ? 'Uploading...' : t('home.uploadPhotosHolder')}
                      </span>
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      className="hidden"
                      multiple
                      accept="image/*"
                      onChange={handleImageUpload}
                      onClick={handleUploadClick}
                    />
                  </div>
                  {selectedImages.length > 0 && (
                    <div className="overflow-x-auto mt-4 mb-4">
                      <div className="flex space-x-4 pb-6">
                        {selectedImages.map((image, index) => renderImage(image, index))}
                      </div>
                    </div>
                  )}
                  {!isUploading && selectedImages.length > 0 && (
                    <p className={selectedImages.length < MIN_IMAGES ? 'text-red' : 'text-green-success'}>
                      {selectedImages.length} image{selectedImages.length !== 1 ? 's' : ''} selected
                      {selectedImages.length < MIN_IMAGES ? ' (minimum 10 required)' : ' (ready to create)'}
                    </p>
                  )}
                </div>
              </div>
              <div className="page-header mb-0 page-header-reset mb-4" />
              {/* <button
                onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
                className={`flex ${darkMode ? 'text-white' : 'text-black'}`}
              >
                <label className={`${darkMode ? 'text-white' : 'text-black'} cursor-pointer`}>
                  Advanced
                </label>
                {showAdvancedOptions ? (
                  <ChevronUp className="w-5 h-5" />
                ) : (
                  <ChevronDown className="w-5 h-5" />
                )}
              </button>
              {showAdvancedOptions && (
                <>
                  <div className={`mb-0 ${darkMode ? 'text-white' : 'text-black'} model-alert mt-2 p-4`}> With advanced options, you create a model with any specific object, style, animal or clothing (Skip if you do for your self)</div>
                  <div className="page-header mb-0 page-header-reset mb-2" />
                  <label className={`${darkMode ? 'text-white' : 'text-black'}`}>
                    Name
                    {' '}
                    <Tooltip title="Name the model so you can easily identify it later.">
                      <span className="ml-1 text-gray-500 cursor-help">?</span>
                    </Tooltip>
                  </label>
                  <div className="page-header mb-0 page-header-reset mb-2" />
                  <TextField
                    value={triggerWord}
                    sx={{
                      width: '100%',
                      border: darkMode ? '1px solid #373737' : '',
                      borderRadius: '6px',
                      background: darkMode ? '#323232' : '',
                      color: darkMode ? 'white' : 'black',
                      fontSize: isMobile ? '16px' : '14px',
                    }}
                    maxRows={1}
                    minRows={1}
                    onChange={handleInputChange}
                    placeholder="e.g: Your name model"
                    inputProps={{
                      style: {
                        fontSize: isMobile ? '16px' : '14px',
                        color: darkMode ? 'white' : 'black',
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: isMobile ? '16px' : '14px',
                        color: darkMode ? 'white' : 'black',
                      },
                    }}
                    error={!!promptErrorMessage}
                    helperText={promptErrorMessage}
                    autoFocus={false}
                  />
                  <div className="page-header mb-0 page-header-reset mb-4" />
                  <div>
                    <label className={`${darkMode ? 'text-white' : 'text-black'}`}>
                      Select Type
                      {' '}
                      <Tooltip title="Select the type of model you want to create.">
                        <span className="ml-1 text-gray-500 cursor-help">?</span>
                      </Tooltip>
                    </label>
                    <div className="page-header mb-0 page-header-reset mb-2" />
                    <div className="grid grid-cols-3 sm:grid-cols-4 gap-4">
                      {subjects.map((subject) => (
                        <button
                          key={subject.name}
                          style={{ border: darkMode ? '1px solid #373737' : '1px solid rgb(0 0 0 / 20%)', borderRadius: '10px' }}
                          className={`flex flex-col items-center justify-center p-4 rounded-lg transition-colors
                            ${selectedSubject === subject
                              ? darkMode
                                ? 'bg-pink-500 text-white'
                                : 'bg-pink-500 text-white'
                              : darkMode
                                ? 'dark-bg-item text-white'
                                : 'light-bg-item text-black'
                            }`}
                          onClick={() => handleSubjectSelect(subject)}
                        >
                          <span className="mb-2">{subject.icon}</span>
                          <span className="text-sm">{subject.name}</span>
                        </button>
                      ))}
                    </div>
                    <div className="page-header mb-0 page-header-reset mb-4" />
                  </div>
                </>
              )} */}
              <div className="page-header mb-0 page-header-reset mb-2" />
              <div style={{ color: '#8c8c8c', fontSize: '14px', marginTop: '0' }}>
                {/* By creating a model, you agree to our <a href="/legal" target="_blank" rel="noopener noreferrer" className={darkMode ? 'text-white' : 'text-black'}>Terms of Service</a> and confirm that you are creating a model of yourself or have obtained explicit consent from any individuals whose likeness you are using. */}
                By proceeding to create a model, you agree to our <a href="/legal" target="_blank" rel="noopener noreferrer" className={darkMode ? 'text-white' : 'text-black'}>Terms of Service</a> and confirm that you are creating a model of yourself or have secured explicit consent from any individuals whose likeness is being used.
              </div>
            </>
          </div>
          <div className={`down-action-area ${darkMode ? 'dark-bg' : 'light-bg'}`}>
          <div className="create-imagine-button generate-btn">
            <Button 
              disabled={isUploading || isCreating} 
              onClick={onCreateModel} 
              variant="contained"
              sx={{
                backgroundColor: '#fc5185',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#000',
                },
                '&:disabled': {
                  backgroundColor: '#000',
                  color: 'white',
                },
                // textTransform: 'none',
                padding: '8px 16px',
                fontSize: '14px',
                width: '100%'
              }}
            >
              {isCreating ? (
                <Stack direction="row" spacing={1} alignItems="center">
                  <CircularProgress size={16} sx={{ color: 'white' }} />
                  <span>Creating...</span>
                </Stack>
              ) : (
                <span>
                  Create
                  {userData.modelsAllowed && userData.activeModels ? (
                    <span
                      className="ml-2 px-1 py-1"
                      style={{
                        backgroundColor: '#44d48326',
                        color: '#44d483',
                        borderRadius: '4px',
                        marginLeft: '4px',
                        fontWeight: '600'
                      }}>
                      {userData.modelsAllowed && userData.activeModels ? 
                        `${userData.activeModels}/${userData.modelsAllowed}` : 
                        '0/0'}
                    </span>
                  ) : (
                    <span
                      className="ml-2 px-1 py-1"
                      style={{
                        backgroundColor: '#98305626',
                        color: '#c77d94',
                        borderRadius: '4px',
                        marginLeft: '4px',
                        fontWeight: '600'
                      }}>
                      {userData.modelsAllowed && userData.activeModels ? 
                        `${userData.activeModels}/${userData.modelsAllowed}` : 
                        '0/0'}
                    </span>
                  )}
                </span>
              )}
            </Button>
          </div>
          </div>
        </div>
      </div>
      <AllModelShowCase
        userData={userData}
        checkCredits={checkCredits}
        firebase={firebase}
        currentJob={currentJob}
        t={t}
        jobsList={jobsList}
        isCreating={isCreating}
        progress={progress}
        onGenFill={onGenFill}
        onFetchUser={onFetchUser}
        handleSelectCollection={handleSelectCollection}
        darkMode={darkMode}
      />
    </div>
  );
};

export default TrainModel;
