import React, { useRef, useEffect, useState } from 'react';
import { ArrowDownToLine, LoadingOutlined } from 'lucide-react';
import CircularProgress from '@mui/material/CircularProgress';
import BeforeAfter from '../BeforeAfter';
import ViewPhoto from '../CompareImage/ViewPhoto';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  ViberIcon,
  WhatsappIcon,
  XIcon,
  FacebookMessengerShareButton,
} from "react-share";

import { FirebaseContext } from '../../services/firebase';
import { useContext } from 'react';

const { PHOTO_GEN_MODEL, UPSCALE_MODEL, MAGIC_FILL_MODEL, REMIX_MODEL } = require('../../constants/model');

const ExportComponent = ({ images, onClose, isMobile }) => {
  const firebase = useContext(FirebaseContext);
  const downloadRef = useRef(null);
  const textareaRef = useRef(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    name: '',
    jobTitle: '',
    rating: 0,
    message: ''
  });

  useEffect(() => {
    const handleClickOutside = event => {
      if (downloadRef.current && event.target.contains(downloadRef.current)) {
        onClose();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [downloadRef]);

  function generateRandomFileName(extension) {
    const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
    return `reok-${randomNumber}.${extension}`;
  }

  const handleClickDownload = (image) => {
    setIsDownloading(true);

    try {
      setTimeout(async () => {
        const response = await fetch(image);
        const blob = await response.blob();

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        const fileName = generateRandomFileName('jpeg');
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(url);
        setIsDownloading(false);

      }, 2000);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  }

  function convertModelToText(model) {
    if (model === PHOTO_GEN_MODEL) {
      return 'Studio';
    } else if (model === UPSCALE_MODEL) {
      return 'Magic upscaler';
    } else if (model === MAGIC_FILL_MODEL) {
      return 'Magic fixer';
    } else if (model === REMIX_MODEL) {
      return 'Transfer style + pose';
    }
    return '';
  }

  const handleCopy = async () => {
    if (textareaRef.current) {
      try {
        await navigator.clipboard.writeText(textareaRef.current.value);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } catch (err) {
        console.error('Failed to copy text:', err);
      }
    }
  };

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
  
    // Check if rating is provided
    if (feedbackData.rating === 0) {
      alert('Please provide a rating');
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      // Handle the feedback submission here
      const currentUser = firebase.currentUser();

      if (currentUser) {
        const feedbackToSubmit = {
          ...feedbackData,
          status: "pending",
          imageUrl: images.after,
          uid: currentUser.uid,
          email: currentUser.email,
          createdAt: new Date(),
        };
    
        await firebase.addFeedbacks(feedbackToSubmit);
    
        setOpenFeedback(false);
        // Reset form
        setFeedbackData({
          name: '',
          jobTitle: '',
          rating: 0,
          message: ''
        });
        
        // Show success message
        alert('Thank you for your feedback! We will review it soon.');
      } else {
        throw("Can't get your info, please refresh and try again")
      }
    } catch (error) {
      alert('Something went wrong. Please try again.');
      console.error('Feedback submission error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={true}
        onClose={() => onClose()}
      >      
        <ModalDialog
          sx={{
            width: {
              xs: '98%',
              md: '1300px'
            },
            // height: '100%',
            background: '#09090b',
            border: '1px solid #373737',
            overflow: 'auto'
          }}
        >        
          <ModalClose
            onClick={() => onClose()}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              color: 'white',
              zIndex: 1000,
            }}
          />
          <div className="export-container">
            {!(images.mode === PHOTO_GEN_MODEL || images.before === "") ? (
              <div className='flex align-items-center justify-center'>
                <BeforeAfter
                  images={images}
                />
              </div>
            ) : (
              <ViewPhoto
                images={images}
              />
            )}

            <div className="export-content">
            <div className="flex justify-between">
              <h3 className="font-semibold mb-2 text-white mt-2">{convertModelToText(images.mode)}</h3>
              <span 
                className='coupon-card text-white text-sm' style={{ color: '#fff', cursor: 'pointer' }}
                onClick={() => setOpenFeedback(true)}
              >
                <ThumbUpAltIcon sx={{fontSize: "20px", marginRight: "2px"}}/>
                Get free 50 credits
              </span>
            </div>
            <div className="relative">
              <textarea
                ref={textareaRef}
                readOnly
                className="text-white mb-4 p-4 bg-gray rounded-md w-full"
                value={images.prompt}
                style={{ 
                  resize: 'none', 
                  overflow: 'auto',
                  border: '1px solid #373737',
                  padding: '40px'
                }}
                rows={isMobile? 5:6}
              />
              <Tooltip title={copied ? "Copied!" : "Copy to clipboard"}>
                <IconButton
                  onClick={handleCopy}
                  sx={{
                    position: 'absolute',
                    right: '0px',
                    top: '0px', // Changed from top to bottom
                    color: copied ? 'success.main' : 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.08)'
                    }
                  }}
                >
                  {copied ? <CheckIcon /> : <ContentCopyIcon />}
                </IconButton>
              </Tooltip>
            </div>
              <h3 className="font-semibold mb-2 text-white">Share on social</h3>
              <div className="grid rnftg-grid-cols-5 gap-2 mb-5 mt-4">
                <div className="flex flex-col items-center">
                  <FacebookShareButton url={images.after} quote={images.prompt}>
                    <FacebookIcon size={45} round={true}/>
                  </FacebookShareButton>
                  <span className="text-xs text-center text-white mt-1">Facebook</span>
                </div>
                <div className="flex flex-col items-center">
                  <FacebookMessengerShareButton url={images.after} quote={images.prompt}>
                    <FacebookMessengerIcon size={45} round={true}/>
                  </FacebookMessengerShareButton>
                  <span className="text-xs text-center text-white mt-1">Messenger</span>
                </div>
                <div className="flex flex-col items-center">
                  <LinkedinShareButton url={images.after} quote={images.prompt}>
                    <LinkedinIcon size={45} round={true}/>
                  </LinkedinShareButton>
                  <span className="text-xs text-center text-white mt-1">Linkedin</span>
                </div>
                <div className="flex flex-col items-center">
                  <RedditShareButton url={images.after} quote={images.prompt}>
                    <RedditIcon size={45} round={true}/>
                  </RedditShareButton>
                  <span className="text-xs text-center text-white mt-1">Reddit</span>
                </div>
                <div className="flex flex-col items-center">
                  <PinterestShareButton url={images.after} quote={images.prompt}>
                    <PinterestIcon size={45} round={true}/>
                  </PinterestShareButton>
                  <span className="text-xs text-center text-white mt-1">Pinterest</span>
                </div>
                <div className="flex flex-col items-center">
                  <TwitterShareButton url={images.after} quote={images.prompt}>
                    <XIcon size={45} round={true}/>
                  </TwitterShareButton>
                  <span className="text-xs text-center text-white mt-1">X</span>
                </div>
                <div className="flex flex-col items-center">
                  <TelegramShareButton url={images.after} quote={images.prompt}>
                    <TelegramIcon size={45} round={true}/>
                  </TelegramShareButton>
                  <span className="text-xs text-center text-white mt-1">Telegram</span>
                </div>
                <div className="flex flex-col items-center">
                  <WhatsappShareButton url={images.after} quote={images.prompt}>
                    <WhatsappIcon size={45} round={true}/>
                  </WhatsappShareButton>
                  <span className="text-xs text-center text-white mt-1">Whatsapp</span>
                </div>
                <div className="flex flex-col items-center">
                  <ViberShareButton url={images.after} quote={images.prompt}>
                    <ViberIcon size={45} round={true}/>
                  </ViberShareButton>
                  <span className="text-xs text-center text-white mt-1">Viber</span>
                </div>
                <div className="flex flex-col items-center">
                  <EmailShareButton url={images.after} quote={images.prompt}>
                    <EmailIcon size={45} round={true}/>
                  </EmailShareButton>
                  <span className="text-xs text-center text-white mt-1">Email</span>
                </div>
                {/* <button className="flex flex-col items-center" onClick={() => shareOnSocialMedia('youtube')}>
                  <img src="images/youtube-icon.svg" alt="Youtube" className="w-10 h-10 mb-1" />
                  <span className="text-xs text-center text-white">Youtube</span>
                </button>
                <button className="flex flex-col items-center" onClick={() => shareOnSocialMedia('facebook')}>
                  <img src="images/facebook-icon.svg" alt="Facebook" className="w-10 h-10 mb-1" />
                  <span className="text-xs text-center text-white">Facebook</span>
                </button>
                <button className="flex flex-col items-center" onClick={() => shareOnSocialMedia('instagram')}>
                  <img src="images/instagram-icon.svg" alt="Instagram" className="w-10 h-10 mb-1" />
                  <span className="text-xs text-center text-white">Instagram</span>
                </button>
                <button className="flex flex-col items-center" onClick={() => shareOnSocialMedia('linkedin')}>
                  <img src="images/linkedin-icon.svg" alt="Linkedin" className="w-12 h-12 mb-1" style={{ maxWidth: '100%', height: 'auto' }} />
                  <span className="text-xs text-center text-white">Linkedin</span>
                </button>
                <button className="flex flex-col items-center" 
                  onClick={() => shareOnSocialMedia('twitter')}
                >
                  <img src="images/x.webp" alt="X" className="w-8 h-8 mb-3 mt-2" style={{ filter: 'invert(1)' }} />
                  <span className="text-xs text-center text-white">X</span>
                </button> */}
              </div>
              <button className=" bg-pink-500 p-3 rounded-lg flex justify-between items-center">
                <div className="flex items-center text-sm bold" onClick={() => handleClickDownload(images.after)}>
                  {isDownloading ?
                    <CircularProgress size={24} sx={{ color: '#fff' }} />
                    : (
                      <ArrowDownToLine className="h-6 w-6 mr-2" />
                    )}
                  <span style={{ marginLeft: '6px' }}>Download</span>
                </div>
              </button>
            </div>
          </div>
        </ModalDialog>
      </Modal>
      <Modal
        open={openFeedback}
        onClose={() => setOpenFeedback(false)}
      >
        <ModalDialog
          sx={{
            width: {
              xs: '90%',
              sm: '400px'
            },
            maxHeight: '90vh', // Maximum height of 90% of viewport height
            background: '#09090b',
            border: '1px solid #373737',
            p: 3,
            overflowY: 'auto',
          }}
        >
          <ModalClose
            onClick={() => setOpenFeedback(false)}
            sx={{
              color: 'white',
            }}
          />
          
          {/* Image Section */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            mb: 3 
          }}>
            <img 
              src={images.after} 
              alt="Feedback Gift"
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'contain',
                borderRadius: '4px'
              }}
            />
          </Box>

          <h2 className="text-white text-xl mb-4 text-center">
            Share Your Feedback & Get 50 Credits
          </h2>
          <p className="text-gray-400 text-sm mb-4 text-center">
            If your feedback meets our guidelines, you will receive 50 free credits as a thank you for your contribution. Your insights help us improve and serve you better!
          </p>

          <Box component="form" onSubmit={handleFeedbackSubmit}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              margin="normal"
              value={feedbackData.name}
              onChange={(e) => setFeedbackData({...feedbackData, name: e.target.value})}
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: '#373737',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#fc5185',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'gray',
                },
              }}
            />
            <TextField
              fullWidth
              label="Job Title"
              variant="outlined"
              margin="normal"
              value={feedbackData.jobTitle}
              onChange={(e) => setFeedbackData({...feedbackData, jobTitle: e.target.value})}
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: '#373737',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#fc5185',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'gray',
                },
              }}
            />
            <Box sx={{ mt: 2, mb: 2 }}>
              <p className="text-white mb-2">Rate this photo</p>
              <Rating
                value={feedbackData.rating}
                onChange={(event, newValue) => {
                  setFeedbackData({...feedbackData, rating: newValue});
                }}
                size="large"
                sx={{ 
                  color: '#ffc337',
                  '& .MuiRating-iconEmpty': {
                    color: 'rgba(255, 255, 255, 0.3)'
                  }
                }}              
              />
            </Box>
            <TextField
              fullWidth
              label="Message"
              variant="outlined"
              multiline
              rows={4}
              margin="normal"
              value={feedbackData.message}
              onChange={(e) => setFeedbackData({...feedbackData, message: e.target.value})}
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: '#373737',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#fc5185',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'gray',
                },
              }}
            />
          <p className="text-gray-400 text-sm mb-2 mt-4 text-center">
          By clicking "Send," you agree that we may use your feedback as a testimonial on our website.
          </p>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={isSubmitting}
            sx={{
              mt: 2,
              bgcolor: '#fc5185',
              '&:hover': {
                bgcolor: '#be185d',
              },
            }}
          >
            {isSubmitting ? (
              <CircularProgress 
                size={24} 
                sx={{ 
                  color: 'white',
                  mr: 1
                }} 
              />
            ) : 'Send'}
          </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default ExportComponent;