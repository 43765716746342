const en = {
    Basic: "Basic",
    Pro: "Pro",
    month: "month",
    year: "year",
    button: {
        getStarted: 'Get Started',
        try: 'Start your photos with AI',
        viewall: 'View All',
        basic: 'Basic',
        pro: 'Pro',
        generate: 'Generate Design',
        backHome: 'Back to Home',
        googleSignin: 'Start with Google',
        googleSignup: 'Start with Google',
        emailSignin: 'Sign In with Email',
        emailSignup: 'Start with Email',
        submit: 'Submit',
        choose: 'Choose',
        download: 'Download This Template',
        upgrade: 'Select your plan',
        subscribe: 'Buy it',
        upgradePro: 'Upgrade',
        confirmPayment: 'Confirm Payment',
        tryThisFeature: 'Try This Now',
        removeBG: 'Start',
        professional: 'Start',
        upscale: 'Start',
        ok: 'Ok',
        cancel: 'Cancel',
        select: 'Select',
        selectToStart: 'Select to start',
        uploadPose: 'Upload pose',
        uploadingPose: 'Uploading your pose',
        upgradeToUpload: 'Upgrade to upload',
        unlock: "Upgrade",
        englishOnly: "English version",
        contactUs: "Contact Us",
        forEnterprise: "For Corporate Headshots",
        tryThis: "Try",
        useThis: "Run",
        upscale: "Upscale",
        remove: "Remove",
        render: "Render",
        rendering: "Rendering",
    },
    header: {
        nav: {
            signIn: 'Sign In',
            signUp: 'Sign Up',
            signOut: 'Sign Out',
            billing: 'Billing',
            supportTitle: 'Support',
            supportContact: 'Contact Us',
            myFlowers: 'My assets'
        }
    },
    landing: {
        // title: 'Your friendly AI photo studio',
        title: 'Photo Shoots Using AI Studio',
        joinNow: 'Ready to create stunning photos?',
        subTitle: "Instantly generate realistic photos with our AI-photographer in minutes not hours! Generate photos for your social media, product and more.",
        subTitle1: "Save time and money effortlessly. Trusted by Creators, Photographers, Business owners, Companies, Studio and Professionals all over the world.",
        input: 'Tell me the type of flower you want to create?',
        inside: 'Indoor',
        outside: 'Outdoor',
        loveBy: '221,880+ AI photos already created',
        respectTitle: 'We respect your privacy',
        respectSub1: 'No FaceID or multiple photos needed. Simply use a single selfie, just like your usual Facebook or Instagram posts.',
        respectSub2: 'Your data is completely safe and secure on Google platform very high security.',
        howItWorks: 'How AI studio works',
        howItWorksSub: 'Snap or upload -> Pick style and background -> Pick or upload your pose -> Receive your headshots!',
        unBlurryTitle: 'Magic upscaler',
        unBlurrySub: 'Remove blurs, enhance, and improve photo colors before posting on social media platforms like Facebook, Instagram, Tiktok.',
        useThisFeature: 'Create yours now',
        anotherFeatureTitle: 'Outstanding Features',
        anotherFeatureSub: 'Free, fast, and accurate. Try these features below.',
        feature1: 'Remove backgrounds',
        feature1Sub: 'Accurately remove any background.',
        feature2: 'Interior Design',
        feature2Sub: 'Interior design, exterior design, and sketching with genhomeai.com',
        feature3: 'Change Backgrounds (Coming soon)',
        feature3Sub: 'Change backgrounds for any object with REOK’s AI',
        hotFeatureTitle: 'Our customers',
        hotFeatureSub: 'These are AI-generated photos examples, shared by their permission.',
        pricingTitle: 'Pick your plan',
        pricingLabel: 'Pricing',
        pricingSub: '1 professional photo = 10 credits or less',
        step1: 'Upload a few selfies',
        step1Sub: 'You will upload a few selfies and appearance. Our AI will present it as a model in 20 minutes.',
        step2: 'Our AI gets to work',
        step2Sub: 'Instantly creates stunning professional headshots and business photos in just seconds.',
        step3: 'Receive your photos',
        step3Sub: 'Download your favorite photos and edit them easily with our AI tools.',
        trialTitle: "FREE Trial",
        trialSubTitle: "#1 AI Headshot Generator 📸",
        headshotsMenu: "Create",
        feedbackLabel: 'Feedback',
        faqLabel: 'FAQ',
        sketch: 'Sketch',
        creativity: 'Creativity',
        upscale4k: 'Magic Upscaler',
        remove: 'Cleanroom',
        fillup: 'Furniture',
        exterior: 'Exterior',
        interior: 'Interior',
        steps: 'Steps',
        upscaleShowCase: {
            label: "MAGIC UPSCALER",
            title: "Upscale to 4K",
            subTitle: "Remove blurs, enhance, and improve photo colors before posting on social media platforms like Facebook, Instagram, Tiktok."
        },
        exteriorShowCase: {
            label: "BACKGROUND REMOVER",
            title: "Remove any background",
            subTitle: "Accurately remove any background, ensuring a clean and professional look for your photos. Perfect for social media, marketing materials, and more."
        },
        furnitureShowCase: {
            label: "FILL FURNITURE",
            title: "Fill an empty room with furnitures",
            subTitle: "You can try out different setups until you find the perfect one. Decorate empty room with furniture with Reok. Works for under construction or empty spaces."
        },
        magicEraserShowCase: {
            label: "MAGIC ERASER",
            title: "Remove anything",
            subTitle: "Effortlessly remove any unwanted object from your photos, creating the perfect image tailored to your vision."
        },
        creativityShowCase: {
            label: "AI creativity",
            title: "Custom AI creativity",
            subTitle: "Take charge of your design journey with AI Intervention. You decide how much the AI should help. Whether you want just a few suggestions or a complete makeover, it's all up to you."
        },
        sketchShowCase: {
            label: "SKETCH",
            title: "Sketch to realistic designs",
            subTitle: "Transform any sketch into lifelike, realistic designs, bringing your concepts to vivid fruition with precision and authenticity."
        },
    },
    businessHeadshots: {
        title: 'Businesses Headshots',
        subTitle: "Unlock the Ultimate Team Branding Experience with Businesses Headshot. Instantly generate realistic photos with our AI-photographer in minutes not hours!",
        menuTitle: "Businesses",
    },
    datingPhotos: {
        title: 'Dating Photos',
        subTitle: "Skyrocket Your Dating Success: Unleash the Power of AI to Transform Your Profile!",
        menuTitle: "Dating"
    },
    halloweenPhotos: {
        title: 'Halloween Photos',
        subTitle: "Skyrocket Your Dating Success: Unleash the Power of AI to Transform Your Profile!",
        menuTitle: "Halloween"
    },
    travelPhotos: {
        title: 'Travel Photos',
        subTitle: "Skyrocket Your Dating Success: Unleash the Power of AI to Transform Your Profile!",
        menuTitle: "Travel"
    },
    techConference: {
        title: 'Tech Conference',
        subTitle: "Skyrocket Your Dating Success: Unleash the Power of AI to Transform Your Profile!",
        menuTitle: "Tech Conference"
    },
    makeUp: {
        title: 'Make Up Try on',
        subTitle: "Skyrocket Your Dating Success: Unleash the Power of AI to Transform Your Profile!",
        menuTitle: "Make Up Try on"
    },
    youtubeThumbnail: {
        title: 'Youtube Thumbnail',
        subTitle: "Skyrocket Your Dating Success: Unleash the Power of AI to Transform Your Profile!",
        menuTitle: "Youtube Thumbnail"
    },
    studentHeadshots: {
        title: 'Student Headshots',
        subTitle: "Get more jobs with our professional AI headshot in seconds",
        menuTitle: "Student Headshots"
    },
    teacherHeadshots: {
        title: 'Teacher Headshots',
        subTitle: "Unlock the Ultimate Professional Edge: Teachers, Transform Your Image in Minutes with the Revolutionary AI Headshot Generator!",
        menuTitle: "Teacher Headshots"
    },
    docterHeadshots: {
        title: 'Doctor Headshots',
        subTitle: "Unlock the Future of Personal Branding with AI-enhanced Headshots for Healthcare Professionals!",
        menuTitle: "Doctor Headshots"
    },
    lawyerHeadshots: {
        title: 'Lawyer Headshots',
        subTitle: "Unlock the Power of First Impressions: Revolutionary AI Headshot Tailored for Lawyers!",
        menuTitle: "Lawyer Headshots"
    },
    realeastateHeadshots: {
        title: 'Real Estate Headshots',
        subTitle: "Boost Your Real Estate Success Elevate Your Marketing with Striking AI-Generated Headshots!",
        menuTitle: "Real Estate Headshots"
    },
    salespersonHeadshots: {
        title: 'Salesperson Headshots',
        subTitle: "Unlock the Secret to Skyrocketing Your Sales Transform Your First Impression with Our Revolutionary AI Headshot!",
        menuTitle: "Salesperson Headshots"
    },
    yearbook: {
        title: 'AI Yearbook photoshoot 📸',
        subTitle: "Not using many Selfie images. Get your 90s Yearbook photos with a single Selfie with our AI-photographer in seconds. Save 10x your time.",
        input: 'Tell me the type of flower you want to create?',
        inside: 'Indoor',
        outside: 'Outdoor',
        loveBy: '221,880+ AI photos already created',
        respectTitle: 'We respect your privacy',
        respectSub1: 'We do not use FaceID or require a lot of photos to perform. Just use a Selfie, similar to what you often post on Facebook and Instagram.',
        respectSub2: 'Your data is completely safe and secure on Google platform very high security.',
        howItWorks: 'How AI Yearbook Works',
        howItWorksSub: 'You take a selfie or use a clear ID photo, select your style & pose do headshots in seconds not hours!',
        unBlurryTitle: 'Magic upscaler',
        unBlurrySub: 'Remove blurs, enhance, and improve photo colors before posting on social media platforms like Facebook, Instagram, Tiktok.',
        useThisFeature: 'Create yours now',
        anotherFeatureTitle: 'Outstanding Features',
        anotherFeatureSub: 'Free, fast, and accurate. Try these features below.',
        feature1: 'Remove backgrounds',
        feature1Sub: 'Accurately remove any background.',
        feature2: 'Interior Design',
        feature2Sub: 'Interior design, exterior design, and sketching with genhomeai.com',
        feature3: 'Change Backgrounds (Coming soon)',
        feature3Sub: 'Change backgrounds for any object with REOK’s AI',
        hotFeatureTitle: 'Our AI Yearbook Photos',
        hotFeatureSub: 'These are AI-generated headshots.',
        pricingTitle: 'Pick your plan',
        pricingLabel: 'Pricing',
        pricingSub: '1 professional photo = 10 credits or less',
    },
    showcase: {
        title: 'Sample Flowers',
        status: {
            pending: 'processing',
            upscaling: 'upscaling',
            choosing: 'choosing',
            finished: 'finished'
        },
        mode: {
            pro: 'Pro',
            basic: 'Basic'
        },
        model: {
            title: 'Your models',
            hotModel: "Our models",
        },
        generator: {
            hotPack: 'Hot styles',
            title: 'Current results',
        }
    },
    home: {
        title: 'Current Results',
        hotColletion: "New Styles",
        credits: 'Designs left',
        uploadTitle: 'Upload your space',
        uploadPlaceHolder: 'Click here or drag an image file',
        uploadPhotosHolder: 'Click here or drag image files',
        stylePlaceHolder: 'Select',
        uploadPosePlaceHolder: 'Select',
        uploadBGPlaceHolder: 'Select',
        roomTitle: 'Choose room type (29)',
        themeTitle: 'Choose room style (34)',
        pro: {
            title: 'Creativity for All Types of Flowers',
            input: 'Describe your flower...',
        },
        basic: {
            title: 'Unlimited Creativity with Pro',
            chooseFlowerTitle: 'Flower',
            flowers: 'Living Room',
            styles: 'Style',
            decoration: 'Decoration',
            events: 'Events',
            backgrounds: 'Backgrounds'
        },
    },
    profile: {
        title: 'My assets',
        before: 'Before -',
        after: 'After -'
    },
    terms: {
        title: 'TERMS',
        content: ''
    },
    //Part 2
    privacy: {
        title: 'Privacy Policy',
        one: 'Your privacy is very important to us. The privacy policy of reok.pro respects your privacy regarding any information we may collect from you on our website, reok.pro, and other websites we own and operate.',
        two: 'We only request personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.',
        three: 'We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.',
        four: 'We do not share any personally identifying information publicly or with third-parties, except when required to by law.',
        five: 'Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot accept responsibility or liability for their respective privacy policies.',
        six: 'You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.',
        seven: 'Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.',
        eight: 'This policy is effective as of September 15, 2023.'
    },
    refund: {
        moneyBack: 'Money-back guarantee',
        title: 'Refund Policy',
        one: 'Our goal is to provide high-quality service and outcomes to all our clients. We understand that there can be instances where the output does not meet your expectations',
        two: 'We guarantee your satisfaction. If you are unhappy with the photos generated using Reok.pro, we are more than happy to provide you with a full refund. Alternatively, in some cases, we may offer to redo your photos to better meet your expectations.',
        three: 'We will review and respond to refund requests, based on this policy, within 1-3 business days. If your refund is approved, the funds should be back on your card within 5-10 business days. Refunds will be processed back to the original payment method used during purchase; we cannot refund to a different account.',
        four: 'We can only process refund requests submitted within 30 days of purchase and use UNDER 20 credits.',
        five: 'To apply for a refund, kindly email us at hello@reok.pro or use our chat feature available on our website. When contacting us about a refund, please include the email address you used when placing your order.',
        six: 'Thank you for choosing Reok.pro for your headshots.',
    },
    footer: {
        title: 'REOK is an AI Photo Generator Studio for Social Media, Resume, Acting, Blog and more. Instant results in seconds.',
        terms: 'Terms of Service',
        privacy: 'Privacy Policy',
        cookie: 'Cookie Policy',
        app: 'App',
        signin: 'Sign in & Sign up',
        signup: 'Sign Up',
        email: 'Email',
        chatNow: 'Chat Now',
        partner: 'Partner',
        reportBug: 'Report a Bug',
        require: 'Submit a Request',
        page: 'Page',
        tos: 'Legal',
        contact: 'Contact',
        other: 'Other',
        affiliate: 'Affiliates',
        yearbook: 'AI Yearbook',
        blog: 'Blog',
        pricing: 'Pricing',
        howItWorks: 'How it works',
        sendFeedback: 'Contact Us',
        create: 'Create',
        features: {
            title: 'AI Tools',
            photoShoot: 'AI Headshot',
            modelTraining: 'AI Influencer',
            magicUpscaler: 'Magic Upscaler',
            magicEraser: 'Magic Eraser',
            magicBackground: 'Magic Background',
        },
        explore: {
            title: 'Explore',
            page: 'Page',
            blog: 'Blog',
            app: 'App',
            signin: 'Sign in & Sign up',
            signup: 'Sign Up',
            email: 'Email',
            chatNow: 'Chat Now',
            partner: 'Partner',
            affiliate: 'Affiliate',
            pricing: 'Pricing',
            faq: 'FAQ',
        },
    },
    tos: {
        agree: 'New accounts are subject to our ',
        one: '1. Introduction',
        oneDetail: 'By using reok.pro, you agree to and are bound by these terms and conditions.',
        two: '2. Agreement to Terms',
        twoDetail: 'This Agreement is effective from the date you first use the reok.pro app.',
        three: '3. Features',
        threeDetail: 'You will be able to use all features after subscribing to a specific paid plan. Details of available features can be found on the pricing page.',
        four: '4. Refund Policy',
        fourDetail: 'We allow refunds for failed transactions within 14 days of purchase. Contact support to request a refund.',
        five: '5. Use of Product',
        fiveDetail: 'By using reok.pro, you agree to receive important product updates from reok.pro via the email associated with your Google account or the email you used to register your account. You can opt out of receiving these product updates at any time by clicking the "Unsubscribe" link at the bottom of each email. We only send important product updates.',
        six: '6. Disclaimer',
        sixDetail: 'We do not guarantee that reok.pro will meet your requirements or that its operation will be uninterrupted or error-free. All warranties or conditions not expressly set out in this Agreement (including, without limitation, loss of profit, loss or corruption of data, business interruption, or any other pecuniary loss, damage, cost, or expense of any nature whatsoever) are, to the maximum extent permitted by law, excluded and disclaimed. This Agreement does not affect any statutory rights you may have as a consumer.',
        seven: '7. Warranty and Legal Limitations',
        sevenDetail: 'reok.pro does not make any warranties, guarantees, or other representations about the quality, fitness for a particular purpose, or other character or performance of the software. reok.pro will not be liable to you for any lost profits or any consequential, special, incidental, or indirect damages arising out of or relating to this Agreement or your use of reok.pro (however arising, including negligence) except where liability cannot be excluded under law.',
        eight: '8. General Terms and Law',
        eightDetail: 'You acknowledge that no joint venture, partnership, employment, or agency relationship exists between you and reok.pro as a result of using these services. You agree not to hold yourself out as a representative, agent, or employee of reok.pro. You agree that reok.pro will not be liable to you for any action that we take to prevent or address misuse of our platform. You acknowledge that, in providing you with the use of reok.pro, we have relied upon your agreement to be bound by the terms of this Agreement.',
    },
    faq: {
        title: 'Commonly asked questions',
        q1: 'Why should I use REOK for taking professional photos?',
        answer1: 'To save your time and money. We have created an online studio using our powerful AI technology. No need to wait, do makeup, or pose for photos. It helps you quickly create beautiful, professional, and stylish photos for work or to post on social media platforms like Facebook, Instagram, Tiktok.',
        q2: 'How does REOK work?',
        answer2: 'Just take a few selfies from your gallery with clearly your face, then choose or customize your style you would create, our AI photographer will process your request quickly and accurately.',
        q3: 'Does it work on any device? (Laptop, MacOS, PC, iOS, Android)',
        answer3: 'Absolutely! You don`t need to install any mobile app or desktop program, you just run it from the most common used browsers(Chrome, Firefox, Safari).',
        q4: 'How easy I can use it?',
        answer4: 'Just upload a few selfies and start to generate your photos.',
        q5: 'Is my personal information secure?',
        answer5: 'Yes absolutely! Protecting your privacy is extremely important to us. REOK commits to taking necessary measures to secure your personal information and design data. We have strong security protocols in place to ensure that your data is kept secure and used only for the purpose of providing our services. For detailed information, please refer to our privacy policy on our website.',
        q6: 'How long will My assets be stored?',
        answer6: 'Your designs will be stored permanently on the cloud as long as our service is still operational or you can request to delete them at any time.',
        q7: 'How much does it cost to take a photo for social media or business?',
        answer7: 'Very cheap! It costs only 5 credits for a photo. This is 20 times cheaper than going to a studio for a photo shoot.',
        q8: 'Can I get a refund if I am not satisfied?',
        answer8: `We're sorry, we can't offer refunds for our AI photo services. The costs for creating AI models and generating AI photos are very high, and we pay our technology providers upfront for the computer power needed. This means we can't get that money back, even if you're not satisfied with the results. When you sign up, you agree to these terms. However, you can cancel your subscription at any time, and it will end at the end of your current billing period.`,
        q9: 'Do you have tools for editing photos?',
        answer9: 'Yes, we have an all-in-one photo editor that allows you to edit your photos. You can remote the background, upscale to 4k, and more.',
        q10: 'Can I use REOK for commercial purposes?',
        answer10: 'Absolutely! You can use REOK for commercial purposes. You can use the photos for your social media, business, website, or other commercial purposes.',
    },
    signin: {
        title: 'Sign In',
        another: 'or',
        emailPlaceHolder: 'Email Address',
        passwordPlaceHolder: 'Password',
        rememberTitle: 'Remember for Next Time',
        forgetPassword: 'Forgot Password?',
        noAccount: 'Don’t have an account?'
    },
    signup: {
        title: 'Create an account or log in with an existing one.',
        another: 'or',
        firstNamePlaceHolder: 'First Name',
        lastNamePlaceHolder: 'Last Name',
        emailPlaceHolder: 'Email Address',
        passwordPlaceHolder: 'Password',
        rememberTitle: 'Remember for Next Time',
        forgetPassword: 'Forgot Password?',
        gotAccount: 'Already have an account?'
    },
    // Part 3
    forgetPassword: {
        title: 'Retrieve Password',
        content: 'We will send a password retrieval email to you',
        emailPlaceHolder: 'Please enter your Email',
        sendBtn: 'Send',
        backSignin: 'Back to Sign in'
    },
    pricing: {
        yearlySubscription: '1000 designs',
        monthlySubscription: '100 designs',
        saveDesk: 'Save 10%',
        saveMobile: 'Save 10% when purchasing 1000 designs',
        popular: "76% pick this plan",
        free: {
            title: 'Free Credits',
            price: '0$',
            trialTitle: 'Registration Code: Please Sign In',
            joinTitle: 'Paste your code here',
            one: 'Get 20 Free Credits',
            two: 'Included All 100 Credits Features',
            affiliateTitle: 'Affiliate program',
            shareTitle: 'Share with friends👇',
            loginToShare: 'Please sign in to share'
        },
        basic: {
            title: '100 Credits',
            price: '7$',
            duration: '/lifetime',
            recommend: 'For Individuals',
            one: '20 Professional Photos',
            two: 'Remove Background',
            three: 'Upscale to 4K',
            four: 'Download All Photos',
            five: 'Unlimited Cloud Storage',
            six: 'Commercial Usage Of Photos',
            seven: 'Access to All New Features',
            subscribed: 'Purchased'
        },
        pro: {
            title: '1000 Credits',
            subTitle: '(Save 30%)',
            price: '$49',
            duration: '/lifetime',
            recommend: 'Coffee Shop Owners, Businesses, Photo Studios',
            one: '200 Professional Photos',
            two: 'Included All 100 Credits Features',
            three: 'Save Your Money',
            subscribed: 'Purchased'
        },
        credit: {
            title: 'Choose a plan that fits your needs!'
        },
        first: {
            title: 'STARTER',
            price: '$19',
            priceDetail: '100 photos - no subscription',
            duration: '/lifetime',
            recommend: '1 Headshot = 5 Credits',
            five: 'Magic Upscaler',
            credit: '500 credits',
            two: '100 photos/purchase',
            three: 'All modes unlocked',
            one: 'Magic Eraser',
            six: 'Commercial license',
            four: 'What else?',
            seven: 'What else?',
            eight: 'What else?',
            nine: 'What else?',
            ten: 'Download high quality',
            eleven: 'Unlimited render storage',
            twelve: 'Early access to new features',
            thirteen: 'Cancel any time',
            fourteen: 'Magic Fixer',
        },
        second: {
            title: 'PRO',
            subTitle: '(Save 85%)',
            price: '$29',
            priceDetail: '1,000 photos per month',
            duration: '/lifetime',
            recommend: 'Coffee Shop Owners, Businesses, Photo Studios',
            five: 'Magic Upscaler',
            credit: '5,000 credits',
            two: '1,000 photos/mo',
            three: 'All modes unlocked',
            one: 'Magic Eraser',
            six: 'Commercial license',
            four: 'What else?',
            seven: 'What else?',
            eight: 'What else?',
            nine: 'What else?',
            ten: 'Download high quality',
            eleven: 'Unlimited render storage',
            twelve: 'Early access to new features',
            thirteen: 'Cancel any time',
            fourteen: 'Magic Fixer',
            subscribed: 'Subscribe'
        },
        third: {
            title: 'PRO YEARLY',
            subTitle: '(Save 93%)',
            price: '$199',
            priceDetail: '12,000 photos per year',
            duration: '/lifetime',
            recommend: 'Coffee Shop Owners, Businesses, Photo Studios',
            five: 'Magic Upscaler',
            credit: '60,000 credits',
            two: '12,000 photos/yr',
            three: 'All modes unlocked',
            one: 'Magic Eraser',
            six: 'Commercial license',
            four: 'What else?',
            seven: 'What else?',
            eight: 'What else?',
            nine: 'What else?',
            ten: 'Download high quality',
            eleven: 'Unlimited render storage',
            twelve: 'Early access to new features',
            thirteen: 'Cancel any time',
            fourteen: 'Magic Fixer',
            subscribed: 'Subscribe'
        },
        four: {
            title: 'Enterprise Solutions',
            subTitle: '(Contact Us)',
            price: '$0',
            priceDetail: '$ 0 / headshot',
            duration: '/lifetime',
            recommend: 'Coffee Shop Owners, Businesses, Photo Studios',
            one: 'High-quality headshots',
            two: 'Companies or remote teams',
            three: 'Save time and money',
            four: 'Take headshots in seconds',
            five: 'Without traditional photo shoots',
            subscribed: 'Purchased',
        },
        currency: 'đ',
        perMonth: '/month',
        safePayment: 'Secure Payment',
        qr: 'Or Use QR Code'
    },
    newPricing: {
        billWeekly: 'Billed weekly',
        billMonthly: 'Billed monthly',
        billYearly: 'Billed yearly',
        noBill: 'One time payment',
        weekly: 'Weekly',
        monthly: 'Monthly',
        yearly: 'Yearly',
        headshots: 'One time',
        week: 'week',
        month: 'month',
        year: 'year',
        monthlyPlan: {
            starter: {
                title: 'Starter',
                price: '$2',
                description: 'For newcomers exploring, with limited features and photo generation.',
                feature1: 'Fast Processing',
                feature2: 'Basic Styles & Models',
                feature3: 'Magic Eraser',
                feature4: 'Magic Upscaler',
                feature5: 'Commercial License',
                notAllowedFeature1: 'Create 100 AI Photos',
                notAllowedFeature2: 'Create AI Models',
                included: `What's included`,
            },
            premium: {
                title: 'Premium',
                price: '$3',
                description: 'For Photographer, Model, Influencer, Blogger. Any Style, Pose, and Place.',
                feature1: 'Create 500 Photos',
                feature2: 'Create 2 AI Clones (2 Persons)',
                feature3: 'Fully customized with AI Studio',
                feature4: 'All Styles & Models',
                feature5: 'Magic Eraser',
                feature6: 'Magic Upscaler',
                feature7: 'Commercial License',
                included: `What's included per month`,
            },
            business: {
                title: 'Business',
                price: '$4',
                description: 'For Team or Startup, Small Business, Studio. Any Style, Pose, and Place.',
                feature1: 'Create 3000 Photos',
                feature2: 'Create 10 AI Clones (10 Persons)',
                feature3: 'Fully customized with AI Studio',
                feature4: 'All Styles & Models',
                feature5: 'Magic Eraser',
                feature6: 'Magic Upscaler',
                feature7: 'Commercial License',
                included: `What's included per month`,
            },
        },
        yearlyPlan: {
            starter: {
                title: 'Starter',
                price: '$2',
                description: 'For newcomers exploring, with limited features and photo generation.',
                feature1: 'AI Studio',
                feature2: 'Basic Styles & Models',
                feature3: 'Magic Eraser',
                feature4: 'Magic Upscaler',
                feature5: 'Commercial License',
                notAllowedFeature1: 'Create 100 AI Photos',
                notAllowedFeature2: 'Create AI Models',
                included: `What's included`,
            },
            premium: {
                title: 'Premium',
                price: '$3',
                description: 'For Photographer, Model, Influencer, Blogger. Any Style, Pose, and Place.',
                feature1: 'Create 6,000 Photos',
                feature2: 'Create 24 AI Clones (24 Persons)',
                feature3: 'Fully customized with AI Studio',
                feature4: 'All Styles & Models',
                feature5: 'Magic Eraser',
                feature6: 'Magic Upscaler',
                feature7: 'Commercial License',
                included: `What's included per year`,
            },
            business: {
                title: 'Business',
                price: '$4',
                description: 'For Team or Startup, Small Business, Studio. Any Style, Pose, and Place.',
                feature1: 'Create 36,000 Photos',
                feature2: 'Create 120 AI Clones (120 Persons)',
                feature3: 'Fully customized with AI Studio',
                feature4: 'All Styles & Models',
                feature5: 'Magic Eraser',
                feature6: 'Magic Upscaler',
                feature7: 'Commercial License',
                included: `What's included per year`,
            },
        },
        headshotsPlan: {
            basic: {
                title: 'Starter',
                price: '$2',
                description: 'Get instantly 40 Headshots with 4 unique backgrounds and outfits.',
                feature1: 'Done in 2 hours',
                feature2: '40 Headshots',
                feature3: '4 Unique Styles',
                bonus: 'Bonus 100 credits to use',
                bonusFeature1: 'Your AI Clone in 1 month',
                bonusFeature2: 'Magic Studio',
                bonusFeature3: 'Magic Eraser',
                bonusFeature4: 'Magic Upscaler',
                notAllowedFeature1: 'Create AI Photos',
                notAllowedFeature2: 'Create AI Models',
                included: `What's included`,
            },
            pro: {
                title: 'Creator',
                price: '$3',
                description: 'Get instantly 100 Headshots with 10 unique backgrounds and outfits.',
                feature1: 'Done in 1 hour',
                feature2: '100 Headshots',
                feature3: '10 Unique Styles',
                bonus: 'Bonus 200 credits to use',
                bonusFeature1: 'Your AI Clone in 1 month',
                bonusFeature2: 'Magic Studio',
                bonusFeature3: 'Magic Eraser',
                bonusFeature4: 'Magic Upscaler',
                included: `What's included`,
            },
            executive: {
                title: 'Business',
                price: '$4',
                description: 'Get instantly 200 Headshots with 20 unique backgrounds and outfits.',
                feature1: 'Done in 40 minutes',
                feature2: '200 Headshots',
                feature3: '20 Unique Styles',
                bonus: 'Bonus 500 credits to use',
                bonusFeature1: 'Your AI Clone in 1 month',
                bonusFeature2: 'Magic Studio',
                bonusFeature3: 'Magic Eraser',
                bonusFeature4: 'Magic Upscaler',
                included: `What's included`,
            },
        },
    },
    milestoneMessage: {
        first: 'Happier customers with',
        second: 'designs created'
    },
    message: {
        success: {
            redering: 'Your designs are rendering, please wait a moment',
            rederingDone: 'AI has finished creating the design',
            activated: 'Activation successful! Enjoy creating beautiful and satisfying designs!',
            removingBG: 'Removing your background',
            upscale: 'Upscaling resolution',
            resetPassword: 'We just sent an email to reset your password. Please check it!',
            signIn: 'We just sent an email to activate your account. Please check it!',
            reviewing: 'We have received your request. It will be reviewed in a day',
            remindReviewing: 'This photo has been reviewing to clam new credits',
            removed: 'Removed the photo successfully',
            useTheTemplate: "Using the template",
            subscribed: "Email sent successfully!"
        },
        error: {
            modelName: 'Please enter your model name',
            selectSubject: 'Please select your subject to train',
            selectGender: 'Please select your gender',
            selectPhotographer: 'Please Select model to start to start',
            uploadPhoto: 'Please upload your space',
            uploadPhotoMin: 'Please upload at least 10 photos',
            chooseRoom: 'Please select the room type',
            chooseTheme: 'Please select the room style',
            fileOverBasic: 'Please upload a file smaller than 5MB, or upgrade to Pro to upload larger files',
            fileOverPro: 'Please upload a file smaller than 10MB',
            wrongFile: 'Invalid file, please try another file',
            wrongFace: 'Some things went wrong, please try again',
            busy: 'Connection interrupted, please try again',
            gender: 'Please select your gender',
            age: 'Please select your age',
            roomType: 'Please select type',
            style: 'Please select one style or custom yours',
            numberOfResult: 'Please select number of headshots',
            outOfCredits: 'You are out of Credits, please buy more credits!',
            userNotFound: 'This account is not found. Please input correctly your account!',
            wrongPassword: 'Wrong password! If you don`t remember your password please use Forget Password.',
            accountExist: 'Account already exists. Try to login from this account instead',
            tiktok: 'For Google User Security reasons, please use the Chrome or Safari browser. Visit reok.pro to access.',
            reviewing: 'You can not remove this photo because it has been reviewing',
            background: 'Please select your background',
            pose: 'Please select your pose',
            signUpReCaptcha: 'Please complete the reCAPTCHA before sign up.',
            emptyBG: 'Your background is empty, please input it!',
            tooShortBG: 'Your background should be longer than 2 words.',
            tooLongBG: 'Your background too long.',
            badWordsBG: 'Your background contains inappropriate words. Please try another',
            emptyStyle: 'Your style is empty, please input it!',
            tooShortStyle: 'Your style should be longer than 2 words.',
            tooLongStyle: 'Your style too long.',
            badWordsStyle: 'Your style contains inappropriate words. Please try another',
            emptyEmail: 'Please enter your email',
            validEmail: 'Please enter a valid email address',
            subscribeError: 'Sorry, we couldn\'t subscribe you.',
            selectModel: 'Please select a model to start',
            promptStyle: 'Prompt what you want to see',
            aspectRatio: 'Please select aspect ratio',
            stylePackage: 'Please select your Style',

        },
        warning: {
            runOutBasicCredits: 'You have used all the credits in the Basic plan, please purchase more credits',
            runOutProCredits: 'Looks like you need more credits? ^^',
            selectPose: 'Please select your Gender before Pose',
            aiMagicFill: 'Switch to Magic Fixer',
            aiFurnitureRemoval: 'Upload your image to Remove unwanted objects, as removal history isn\'t saved please download it.',
            upscale: 'Upload your image to Upscale with the magic',
            upgradeModel: 'Please upgrade to create your AI Models'
        },
        prompt: {
            professional: 'Professional photo',
            removeBG: 'Remove background',
            upscale: 'Upscaled resolution',
            rendering: 'AI rendering',
            training: 'AI training ~ 30 minutes',
            faceNotFound: 'Your face was too close, please try another selfie!',
            inReview: 'In review',
            styles: {
                business: 'Professional business photo',
                realEstate: 'Professional realtor photo',
                carShowroom: 'Professional car salesperson photo',
                doctor: 'Professional doctor photo',
                yearbook: 'Professional 90s Yearbook photo',
                wedding: 'Professional wedding photo',
                halloween: 'Halloween photo'
            },
        },
        maintain: {
            backsoon: 'We are maintaining this feature, and it will be back soon. Please use other features in the meantime. Thank you! 💗💗💗'
        }
    },   
    // Part 4 
    app : {
        menu: {
            creditTitle: 'Your credits',
            affiliateTitle: 'Affiliate program',
            affiliateSub: 'Receive a 30% commission on payments for all customers you refer to us!',
            trainModel: 'Model',
            studioMode: 'Studio',
            removeBGMode: 'Remove background',
            removeObjectsMode: 'Magic eraser',
            unBluryMode: 'Magic upscaler',
            myPhotos: 'My assets',
            aiMagicFill: "Magic fixer",
            editorTools: "Magic tools",
            home: "Home"
        },
        tryThese: 'No image? Try these:',
        goodSelfie: 'Good selfie',
        badSelfie: 'Bad selfie',
        trick: 'Use Upscale to get better quality',
        removeBGSub: 'Removing backgrounds becomes quick, simple, and accurate with AI.',
        studioMode: {
            title: 'Create Perfect Photography Using AI',
            refundTitle: 'Our AI has been learning for great results 💓',
            creditTitle: '1 photo = 10 credits',
            genderTitle: 'Select type',
            ageTitle: 'Select your age',
            styleTitle: 'Headshot style',
            quantityTitle: 'Number of photos',
            poseTitle: 'Select/upload pose',
            backgroundTitle: 'Select/custom background',
            skipPose: 'Skip to keep your original pose',
            defaultPose: 'Default',
            uploadedPose: 'Uploaded',
            defaultBackground: 'Backgrounds',
            defaultStyle: 'Style',
            headshotsTab: 'Headshots',
            selectPhotographer: 'Select model',
            selectPhotographerSub: 'Please choose a model who fits your needs',
            customizeSub: 'Customize your photo with 1 click on Basic or custom your style and pose by Advanced',
            requirements: 'Customize',
            guideSub: 'Follow the designer requirements 👇',
            creativity: "AI creativity",
            customize: "Prompt your style",
            enOnlyCustomize: "customize (english version only)",
            interiorsTitle: "Interiors",
            exteriorsTitle: "Exteriors",
            gardensTitle: "Gardens",
            aspectRatio: "Aspect ratio",
        },
        upscaleMode: {
            title: 'Quickly and accurately remove blurriness and enhance your photos with AI.',
        },
        trainModel: {
            title: 'Create your AI model 👇',
            guide: 'It takes about 20 minutes to train a model on your photos. We will notify you when it is done and you can start photography with your model through 👉',
            refundTitle: 'Our AI has been learning for great results 💓',
            creditTitle: '1 photo = 10 credits',
            genderTitle: 'Select type',
            ageTitle: 'Select your age',
            styleTitle: 'Styles',
            quantityTitle: 'Number of photos',
            poseTitle: 'Select/upload pose',
            backgroundTitle: 'Select/custom background',
            skipPose: 'Skip to keep your original pose',
            defaultPose: 'Default',
            uploadedPose: 'Uploaded',
            defaultBackground: 'Backgrounds',
            defaultStyle: 'Styles',
            headshotsTab: 'Headshots',
            selectPhotographer: 'Select amodel',
            selectPhotographerSub: 'Please choose a model who fits your needs',
            requirements: 'Customize',
            guideSub: 'Follow the designer requirements 👇',
            creativity: "AI creativity",
            customize: "✍️ customize (beta)",
            enOnlyCustomize: "✍️ customize (english version only)",
            interiorsTitle: "Interiors",
            exteriorsTitle: "Exteriors",
            gardensTitle: "Gardens",
        },
    },
    myDesign: {
        title: 'My assets',
        scrollTitle: 'The content is generated by AI. Please use this feature legally and in a friendly manner',
    },
    imageComponent: {
        compare: 'View',
        download: 'Download',
        removeObjects: 'Eraser',
        upscale: 'Upscaler',
        virtualHome: 'Transfer',
        errorPhoto: 'Renew Bad Photo'
    },
    results: {
        one: '1 photo',
        four: '4 photos',
    },
    genders: {
        man: 'Man',
        woman: 'Woman'
    },
    styles: {
        "business_headshots": "Professional headshots",
        "travel_photos": "Travel photos",
        "dating": "Dating",
        "other": "Other",
        custom: {
            title: 'New Styles',
            btn: 'Start custom',
            dialogTitle: 'Your style and clothes',
            dialogSubTitle: 'Tell us your style, clothes, age, our AI photographer will try to help you appear with it!',
            upgrade: 'Upgrade this feature',
            example: 'a classic suit without tie, 26 years old depends on what you want...',
        },
        "cyberpunk_style_bathroom": "Cyberpunk-style",
        "eclectic_style_coworking_space": "Eclectic-style",
        "maximalist_style_bedroom": "Maximalist-style",
        "contemporary_style_hotel_bedroom": "Contemporary-style",
        "zen_style_bedroom": "Zen-style",
        "cyberpunk_style_outdoor_patio": "Cyberpunk-style",
        "midcentury_modern_style_pool": "Midcentury modern-style",
        "easter_style_drop_zone": "Easter-style",
        "coastal_style_kitchen": "Coastal-style",
    },
    backgrounds: {
        coffeeShop: "Coffee Shop",
        office: "Office",
        cityStreet: "City Street",
        factory: "Factories",
        garden: "Garden",
        carShowroom: "Car Showroom",
        bookShelf: "Bookshelf",
        hotel: "Hotel",
        goldenGate: "Golden Gate",
        snow: "Snow",
        realtor: "Realtor",
        brick: "Brick",
        custom: {
            title: 'Backgrounds',
            btn: 'Start custom',
            dialogTitle: 'Your backgrounds',
            dialogSubTitle: 'Tell us your background, our AI photographer will try to help you appear with it!',
            upgrade: 'Upgrade this feature',
            example: 'a coffee shop, depends on where you want...',
        },
    },
    removeTitle: 'Removing this photo',
    removeSub: 'Are you sure you want to remove this photo? This photo will be permanently removed. This action cannot be undone.',
    renewTitle: 'Renew this photo',
    renewSub: 'We will review your request. If this photo has problem, it will be permanently removed. You will get 10 new credits.',
    reasonTitle: 'Tell us why would you renew it?',
    reasonEx: 'It was unformal...',
    photographers: {
        sam: {
            name: "Sam, 👋 Hi customer",
            title: "I'm good at Styles",
            intro: "What I can do?",
            intro1: "Any Style and Mood in seconds",
            intro2: "Real estate, Art and Blog photos",
            intro3: "Interior & Exterior designs",
            intro4: "Fill an empty room with Furniture",
            intro5: "Photorealistic renders"
        },
        david: {
            name: "David, 👋 Hi customer",
            title: "I'm good at Sketch",
            intro: "What I can do?",
            intro1: "Any Sketch to realistic photos in seconds",
            intro2: "Sketch to any design",
            intro3: "House design",
            intro4: "Architecture design",
            intro5: "Interior design"
        },
        annie: {
            name: "Annie, 👋 Hi customer",
            title: "I'm good at Remodeling",
            intro: "What I can do?",
            intro1: "Keep windows, ceilings, and doors.",
            intro2: "Fill the room with Furniture",
            intro3: "House design",
            intro4: "Architecture design",
            intro5: "Interior design"
        }
    },
    roomsList: {
        "living_room": "Living room",
        "bedroom": "Bedroom",
        "dining_room": "Dining room",
        "bath_room": "Bath room",
        "study_room": "Study room",
        "gaming_room": "Gaming room",
        "meeting_room": "Meeting room",
        "kitchen": "Kitchen",
        "toilet": "Toilet",
        "coworking_space": "Coworking space",
        "fitness_gym": "Fitness gym",
        "mudroom": "Mudroom",
        "walk_in_closet": "Walk-in closet",
        "office": "Office",
        "hotel_room": "Hotel room",
        "hotel_lobby": "Hotel lobby",
        "hotel_bathroom": "Hotel bathroom",
        "exhibition_space": "Exhibition space",
        "house_exterior": "House exterior",
        "attic": "Attic",
        "onsen": "Onsen",
        "drop_zone": "Drop zone",
        "workshop": "Workshop",
        "nail": "Nail",
        "restaurant": "Restaurant",
        "coffee_shop": "Coffee shop",
        "clothing_store": "Clothing store",
        "family_room": "Family Room",
        "kids_room": "Kids Room",
        "balcony": "Balcony",
        "wine_cellar": "Wine Cellar",
        "rooftop_terrace": "Rooftop Terrace",
        "sunroom": "Sunroom",
        "home_spa": "Home Spa",
        "craft_room": "Craft Room",
        "dressing_room": "Dressing Room",
        "guest_bedroom": "Guest Bedroom",
        "home_bar": "Home Bar",
        "library": "Library",
        "art_studio": "Art Studio",
        "yoga_studio": "Yoga Studio",
        "photo_studio": "Photo Studio",
        "multimedia_room": "Multimedia Room",
        "auditorium": "Auditorium",
        "medical_exam_room": "Medical Exam Room",
        "reception_area": "Reception Area",
        "music_room": "Music Room",
        "science_laboratory": "Science Laboratory",
        "home_theater": "Home Theater",
        "wedding_room": "Wedding Room",
        "laundry_room": "Laundry Room",
        "outdoor_kitchen": "Outdoor Kitchen",
        "utility_room": "Utility Room",
        "pet_room": "Pet Room",
        "home_gym": "Home Gym",
        "lounge": "Lounge",
        "play_room": "Play Room",
        "reading_nook": "Reading Nook",
        "sauna": "Sauna",
        "man_cave": "Man Cave",
        "foyer": "Foyer",
        "green_house": "Green House",
        "she_shed": "She Shed",
        "conservatory": "Conservatory",
        "nursery": "Nursery",
        "prayer_room": "Prayer Room",
        "side_of_house": "Side Of House",
        "front_of_house": "Front Of House",
        "back_of_house": "Back Of House",
        "backyard": "Backyard",
        "patio": "Patio",
        "terrace": "Terrace",
        "front_yard": "Front Yard",
        "garden": "Garden",
        "court_yard": "Court Yard",
        "pool_area": "Pool Area",
        "porch": "Porch",
        "playground": "Playground"
    },
    giveAway: {
        title: 'Get 50% off today',
        subTitle: 'Get notified about new features and future giveaways, not spam you 👇',
        enterYourEmail: 'Enter your email...',
        notifyMe: 'Get it now →',
        note: 'We only send important app updates. Not spam you! Unsubscribe anytime.',
        submitting: 'Submitting...',
        emailSent: 'Email sent.',
        emailSentSub: 'Please check your email and start to use Reok!',
        emailSentTitle: 'Woohoo! You\'re in!',
    },
    feedback: {
        title: 'We appreciate your feedback!',
        subTitle: 'Please give us feedback on how we can improve. You will have a chance to win 100 credits!',
        enterYourFeedback: 'Tell us about it...',
        notifyMe: 'Send it now →',
        note: 'We only send important app updates. Not spam you! Unsubscribe anytime.',
        submitting: 'Submitting...',
        sent: 'We received your feedback ✅',
        sentSub: 'We will review your feedback and get back soon!',
        type: {
            bug: 'Bug report',
            feature: 'Feature request',
            feedback: 'Other feedback',
            refund: 'Refund request',
        },
        emptyFeedback: 'Please enter your thoughts!',
        emptyFeedbackType: 'Please select a feedback type!',
    }
}

export default en;
