import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Logout as LogoutOutlined } from '@mui/icons-material';
import i18n from '../../i18n';

const SignOut = ({ handleSignOut, t }) => {

  return (
    <Link
      to={i18n.language === "en" ? "/signin" : `/${i18n.language}/signin`}
      onClick={handleSignOut}
      style={{color: 'inherit'}}
    >
      <LogoutOutlined style={{ display: "inline-grid", paddingRight: "2px" }} /><span>{t('header.nav.signOut')}</span>
    </Link>
  )
};

SignOut.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
};

export default SignOut;
